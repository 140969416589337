import React from "react";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import { isLoggedIn } from "axios-jwt";

import {
  Dashboard,
  // Location
  ListArea,
  EditArea,
  AddArea,
  ListCountry,
  AddCountry,
  EditCountry,
  ListCity,
  AddCity,
  EditCity,
  ListDistrict,
  AddDistrict,
  EditDistrict,
  // Shop
  ShopListCategory,
  ShopAddCategory,
  ShopEditCategory,
  ShopListProduct,
  ShopActionProduct,
  ShopListOrder,
  StatisticOrderShop,
  // Service
  ServiceListCategory,
  ServiceAddCategory,
  ServiceEditCategory,
  ServiceList,
  ServiceAction,
  ServiceBooking,
  DetailServiceBooking,
  StatisticOrderService,
  // Hospital
  HospitalListType,
  HospitalListSpecialty,
  HospitalList,
  HospitalAction,
  //Doctor
  DoctorList,
  ActionDoctor,
  ListOrderServiceDoctor,
  StatisticOrderDoctor,
  DetailDoctorBooking,
  DoctorCategory,
  DoctorCategoryAdd,
  DoctorCategoryEdit,
  // Hotel
  ListHotel,
  ActionHotel,
  ListRoom,
  AddRoom,
  EditRoom,
  ListBooking,
  DetailBooking,
  //profile
  Infomation,
  Agency,
  User,
  ChangePass,
  // User
  ListUser,
  AddUser,
  EditUser,
  // Handbook
  HandbookCategory,
  HandbookCategoryAdd,
  HandbookCategoryEdit,
  HandbookList,
  HandbookAction,
  // Page content
  ListPage,
  AddPage,
  EditPage,
  // QA
  ListQa,
  AddQa,
  EditQa,
} from "../modules";

// import layout
import Login from "../layouts/login";
import MainLayout from "../layouts/main";

import PageNotFound from "../modules/404";

const PrivateRoute = ({
  component: Component,
  layout: Layout = MainLayout,
  authed,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/"
          exact
          component={Dashboard}
        />
        {/* Location */}
        <PrivateRoute
          authed={isLoggedIn()}
          path="/location/area"
          exact
          component={ListArea}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/location/area/add"
          component={AddArea}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/location/area/edit/:id"
          component={EditArea}
        />

        <PrivateRoute
          authed={isLoggedIn()}
          path="/location/country"
          exact
          component={ListCountry}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/location/country/add"
          component={AddCountry}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/location/country/edit/:id"
          component={EditCountry}
        />

        <PrivateRoute
          authed={isLoggedIn()}
          path="/location/city"
          exact
          component={ListCity}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/location/city/add"
          component={AddCity}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/location/city/edit/:id"
          component={EditCity}
        />

        <PrivateRoute
          authed={isLoggedIn()}
          path="/location/district"
          exact
          component={ListDistrict}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/location/district/add"
          component={AddDistrict}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/location/district/edit/:id"
          component={EditDistrict}
        />

        {/* shop */}
        <PrivateRoute
          authed={isLoggedIn()}
          path="/shop/category"
          exact
          component={ShopListCategory}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/shop/category/add"
          component={ShopAddCategory}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/shop/category/edit/:id"
          component={ShopEditCategory}
        />

        <PrivateRoute
          authed={isLoggedIn()}
          path="/shop/product"
          exact
          component={ShopListProduct}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/shop/product/action"
          exact
          component={ShopActionProduct}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/shop/product/action/:id"
          component={ShopActionProduct}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/shop/order"
          component={ShopListOrder}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/shop/statistic"
          component={StatisticOrderShop}
        />
        {/* Service */}
        <PrivateRoute
          authed={isLoggedIn()}
          path="/service"
          exact
          component={ServiceList}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/service/action"
          exact
          component={ServiceAction}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/service/action/:id"
          component={ServiceAction}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/service/booking"
          exact
          component={ServiceBooking}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/service/statistic"
          exact
          component={StatisticOrderService}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/service/booking/:id"
          component={DetailServiceBooking}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/service/category"
          exact
          component={ServiceListCategory}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/service/category/add"
          component={ServiceAddCategory}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/service/category/edit/:id"
          component={ServiceEditCategory}
        />

        {/* Hospital */}
        <PrivateRoute
          authed={isLoggedIn()}
          path="/hospital"
          exact
          component={HospitalList}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/hospital/type"
          component={HospitalListType}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/hospital/specialty"
          component={HospitalListSpecialty}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/hospital/action/:id"
          component={HospitalAction}
        />

        {/* Doctor */}
        <PrivateRoute
          authed={isLoggedIn()}
          path="/doctors"
          exact
          component={DoctorList}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/doctor/action/:id"
          component={ActionDoctor}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/doctor/bookings"
          exact
          component={ListOrderServiceDoctor}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/doctor/statistic"
          exact
          component={StatisticOrderDoctor}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/doctor/bookings/:id"
          component={DetailDoctorBooking}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/doctor/category"
          exact
          component={DoctorCategory}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/doctor/category/add"
          component={DoctorCategoryAdd}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/doctor/category/edit/:id"
          component={DoctorCategoryEdit}
        />

        {/* User */}
        <PrivateRoute
          authed={isLoggedIn()}
          path="/user/:role"
          exact
          component={ListUser}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/user/:role/add"
          component={AddUser}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/user/:role/edit/:id"
          component={EditUser}
        />

        {/* Hotel */}
        <PrivateRoute
          authed={isLoggedIn()}
          path="/hotel"
          exact
          component={ListHotel}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/hotel/action"
          exact
          component={ActionHotel}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/hotel/action/:id"
          component={ActionHotel}
        />

        <PrivateRoute
          authed={isLoggedIn()}
          path="/hotel/:idHotel/room"
          exact
          component={ListRoom}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/hotel/:idHotel/room/add"
          component={AddRoom}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/hotel/:idHotel/room/edit/:idRoom"
          component={EditRoom}
        />

        <PrivateRoute
          authed={isLoggedIn()}
          path="/hotel/bookings"
          component={ListBooking}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/hotel/booking/:id"
          component={DetailBooking}
        />

        {/* Info user */}
        <PrivateRoute
          authed={isLoggedIn()}
          path="/profile"
          exact
          component={Infomation}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/profile/change-pass"
          exact
          component={ChangePass}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/profile/user"
          exact
          component={User}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/profile/agency"
          exact
          component={Agency}
        />

        {/* Handbook */}
        <PrivateRoute
          authed={isLoggedIn()}
          path="/handbook"
          exact
          component={HandbookList}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/handbook/action"
          exact
          component={HandbookAction}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/handbook/action/:id"
          component={HandbookAction}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/handbook/category"
          exact
          component={HandbookCategory}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/handbook/category/add"
          component={HandbookCategoryAdd}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/handbook/category/edit/:id"
          component={HandbookCategoryEdit}
        />

        {/* Page content */}
        <PrivateRoute
          authed={isLoggedIn()}
          path="/page"
          exact
          component={ListPage}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/page/add"
          exact
          component={AddPage}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/page/edit/:id"
          exact
          component={EditPage}
        />

        {/* QA */}
        <PrivateRoute
          authed={isLoggedIn()}
          path="/qa"
          exact
          component={ListQa}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/qa/add"
          exact
          component={AddQa}
        />
        <PrivateRoute
          authed={isLoggedIn()}
          path="/qa/edit/:id"
          exact
          component={EditQa}
        />

        <PrivateRoute authed={isLoggedIn()} path="*" component={PageNotFound} />
      </Switch>
    </Router>
  );
}
