import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Form, Input, Select, message, Button, Spin, Modal
} from 'antd';
import { HomeOutlined, SaveOutlined } from '@ant-design/icons';

import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';
import ModalChangePass from './components/modal-change-pass';


const status = [
    { value: 'published', label: 'Kích hoạt' },
    { value: 'draft', label: 'Không kích hoạt' },
];

const EditUser = (props) => {

    const [form] = Form.useForm();
    const { role, id } = useParams();

    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [email,setEmail] = useState('');

    // load age
    const loadPage = async () => {
        if (typeof id !== 'undefined') {
            return await axiosInstance.get(`${CONSTANT.API.USER.USER}/${id}`)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        return res.data.data;
                    }
                })
                .catch(error => {
                    message.warning('Lỗi lấy thông tin tài khoản!');
                });
        }
    }

    useEffect(() => {
        let mounted = true;
        setLoading(true);
        loadPage().then(data => {
            if (mounted) {
                if (data) {
                    form.setFieldsValue({
                        firstname: data.firstname,
                        lastname: data.lastname,
                        email: data.email,
                        password: data.password,
                        re_password: data.re_password,
                        status: data.is_active === true ? 'published' : 'draft'
                    });
                    setEmail(data.email);
                }
                setLoading(false);
            }
        })

        return () => {
            mounted = false;
        }

    }, []);


    // submit
    const onFinish = async (values) => {
        if (typeof role !== 'undefined') {
            values.role = role;
            values.is_active = values.status === 'published' ? true : false;
            // Create
            setSaving(true);
            await axiosInstance.patch(`${CONSTANT.API.USER.USER}/${id}`, values)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Lưu thông tin thành công!').then(() => props.history.push(`/user/${role}`))
                    }
                    else {
                        message.error('Lưu thông tin thất bại! Vui lòng thử lại sau.');
                        setSaving(false);
                    }
                }).catch(err => {
                    message.error('Lưu thông tin thất bại! Vui lòng thử lại sau.')
                    setSaving(false);
                });
        }
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={`/user/${role}`}>Quản lý tài khoản</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Sửa
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {loading && <div style={{ textAlign: 'center' }}><Spin /></div>}
                {
                    !loading &&
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Row gutter={16}>
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                    label="Họ" name="firstname"
                                    required tooltip="Trường bắt buộc nhập"
                                    rules={[
                                        { required: true, message: 'Vui lòng nhập Họ!' },
                                    ]}
                                >
                                    <Input placeholder="Họ" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12}>
                                <Form.Item
                                    label="Tên" name="lastname"
                                    required tooltip="Trường bắt buộc nhập"
                                    rules={[
                                        { required: true, message: 'Vui lòng nhập tên!' },
                                    ]}
                                >
                                    <Input placeholder="Tên" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24}>
                                <Form.Item
                                    label="Email" name="email"
                                    required tooltip="Trường bắt buộc nhập"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'E-mail chưa đúng định dạng !',
                                        },
                                        {
                                            required: true,
                                            message: 'Vui lòng nhập email E-mail!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Email" disabled={true} />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12}>

                                <Form.Item
                                    label="Trạng thái"
                                    name="status"
                                >
                                    <Select
                                        placeholder="Chọn trạng thái"
                                        style={{ width: '100%' }}
                                    >
                                        {
                                            status.map(item => <Select.Option key={item.value}>{item.label}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24}>
                                <Button type="primary" onClick={showModal}>Đổi mật khẩu</Button>
                                <ModalChangePass 
                                    isModalVisible={isModalVisible} handleCancel={handleCancel} 
                                    role={role} email={email}
                                />
                            </Col>
                        </Row>
                        <div className="footer-toolbar">
                            <div className="right">
                                <Button
                                    type="primary" icon={<SaveOutlined />} htmlType="submit"
                                    loading={saving}
                                >
                                    {saving ? 'Đang lưu' : 'Lưu thông tin'}
                                </Button>
                            </div>

                        </div>
                    </Form>
                }
            </Layout.Content>

        </div>
    )

}

export default EditUser;
