import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Table,
  Button,
  Divider,
  Form,
  Input,
  Row,
  Col,
  Avatar,
  Space,
  Popconfirm,
  Select,
  message,
  Tag,
} from "antd";
import {
  HomeOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import moment from "moment";

import { axiosInstance } from "../../config/api";
import CONSTANT from "../../config/constant";

const getRandomuserParams = (params) => ({
  perPage: params.pagination.pageSize,
  page: params.pagination.current,
  sort: params.sort ? params.sort : `{"created_at": -1}`,
  ...params.options,
});

export default class ListPage extends React.PureComponent {
  constructor(props) {
    super(props);

    // define column table
    this.columns = [
      { title: "UID", dataIndex: "uid", width: "50px" },
      { title: "Tên trang", dataIndex: "name" },
      {
        title: "Trạng thái",
        dataIndex: "is_active",
        width: "120px",
        render: (is_active) =>
          is_active ? (
            <Tag color="green">Công khai</Tag>
          ) : (
            <Tag color="red">Bản nháp</Tag>
          ),
      },
      {
        title: "Ngày tạo",
        dataIndex: "created_at",
        sorter: true,
        width: "170px",
        render: (created_at) =>
          `${moment(new Date(created_at)).format(CONSTANT.DATE_FORMAT)}`,
      },
      {
        title: "Hành động",
        width: "180px",
        render: (text, record) => (
          <Space size="middle">
            <Link to={`/page/edit/${record._id}`}>
              <EditOutlined /> Sửa
            </Link>
            <Divider type="vertical" />
            <Popconfirm
              title="Bạn có chắc xóa trang này?"
              onConfirm={() => this.handleDelete(record._id)}
            >
              <a>
                <DeleteOutlined /> Xóa
              </a>
            </Popconfirm>
          </Space>
        ),
      },
    ];
    this.state = {
      selectedRowKeys: [],
      loading: false,
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
      },
    };
  }
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  handleDelete = async (key) => {
    if (key) {
      await axiosInstance
        .delete(`${CONSTANT.API.EXTRA.PAGE}/${key}`)
        .then((res) => {
          if (res && res.data && res.data.code === 200) {
            message.success("Xóa thành công!");
            const dataSource = [...this.state.data];
            this.setState({
              data: dataSource.filter((item) => item._id !== key),
            });
          } else {
            message.error("Xóa không thành công");
          }
        })
        .catch((err) => message.error("Xóa không thành công"));
    }
  };

  async componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sort: `{"${sorter.field}": ${sorter.order === "ascend" ? 1 : -1}}`,
      pagination,
    });
  };

  fetch = async (params = {}) => {
    this.setState({ loading: true });
    let query = new URLSearchParams(getRandomuserParams(params)).toString();
    await axiosInstance
      .get(`${CONSTANT.API.EXTRA.PAGE}?${query}`)
      .then((res) => {
        if (res && res.data && res.data.code === 200) {
          const { docs, total } = res.data.data;
          this.setState({
            loading: false,
            data: docs,
            pagination: {
              ...params.pagination,
              total,
            },
          });
        }
      });
  };
  // on search
  onSearch = async (values) => {
    const { pagination } = this.state;
    let options = {};
    if (values.field === "name") options.name = values.q;
    if (values.field === "uid") options.uid = values.q;

    this.fetch({
      pagination,
      options,
    });
  };

  reload = () => {
    const { pagination } = this.state;
    this.fetch({ pagination });
    this.formRef.resetFields();
  };

  render() {
    const { loading, selectedRowKeys, data, pagination } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>
            <Link to="/">
              <HomeOutlined />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Trang nội dung</Breadcrumb.Item>
        </Breadcrumb>
        <Layout.Content className="layout-content">
          {/* Begin form search */}
          <Form
            layout="inline"
            onFinish={this.onSearch}
            style={{ marginBottom: 20 }}
            initialValues={{
              field: "name",
            }}
            ref={(ref) => (this.formRef = ref)}
          >
            <Form.Item
              name="q"
              required
              rules={[{ required: true, message: "Vui lòng nhập tên hoặc id" }]}
            >
              <Input
                type="text"
                placeholder="Nhập tên trang hoặc Id"
                style={{ width: 500 }}
              />
            </Form.Item>
            <Form.Item name="field">
              <Select style={{ width: 150 }}>
                <Select.Option value="name">Tên trang</Select.Option>
                <Select.Option value="uid">Id</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                htmlType="submit"
              >
                Tìm
              </Button>
            </Form.Item>
            <Form.Item>
              <Button icon={<RedoOutlined />} onClick={this.reload}>
                Đặt lại
              </Button>
            </Form.Item>
          </Form>
          {/* End form search */}
          <Divider />
          <Row justify="end" style={{ marginBottom: 20 }}>
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => this.props.history.push("/page/add")}
              >
                Thêm mới
              </Button>
            </Col>
          </Row>

          <Table
            //rowSelection={rowSelection}
            columns={this.columns}
            dataSource={data}
            loading={loading}
            pagination={pagination}
            rowKey={(record) => record._id}
            onChange={this.handleTableChange}
          />
        </Layout.Content>
      </div>
    );
  }
}
