import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Form, Input, Select, Upload, message, Button, InputNumber, Modal, Spin, Radio
} from 'antd';
import { HomeOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import Autocomplete from "react-google-autocomplete";
import JoditEditor from "jodit-react";

import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';
import * as COMMON from '../../config/common';
import { lang } from 'moment';

const status = [
    { value: 'true', label: 'Công khai' },
    { value: 'false', label: 'Bản nháp' },
];
const currencys = [
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' },
    { value: 'CHF', label: 'CHF' },
    { value: 'CAD', label: 'CAD' },
    { value: 'KRW', label: 'KRW' },
    { value: 'JPY', label: 'JPY' },
    { value: 'VND', label: 'VND' },
];
const ActionHotel = (props) => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    /*================= Editor ============== */

    //define variable
    const [typeData, setTypeData] = useState([]);
    const [facilitiesData, setFacilitiesData] = useState([]);
    const [areaData, setAreaData] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [loc, setLoc] = useState(null);
    /*================= Images ============== */
    const [imageList, setImageList] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewTitle, setPreviewTitle] = useState('');
    const [previewImage, setPreviewImage] = useState('');
    const onChangeImages = ({ fileList }) => {
        setImageList(fileList);
    }
    const onPreviewImages = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }

        setPreviewImage(src);
        setPreviewVisible(true);
        let _previewTitle = file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
        setPreviewTitle(_previewTitle);

    };
    const onRemove = async file => {
        
        // remove 
    }
    // save image
    const saveImage = async () => {
        let arrImage = [];
        if (imageList.length > 0) {
            for (const item of imageList) {
                if(item.status === 'done'){
                    if (item.response.code === 200 && item.response.data && item.response.data.filename) {
                        let idImage = await axiosInstance.post(`${CONSTANT.API.EXTRA.SAVE_FILE}`, {
                            filename: item.response.data.filename,
                            type: "hotel"
                        })
                            .then(async res => {
                                if (res && res.data && res.data.code === 200) {
                                    let filename = res.data.data.url;
                                    if (typeof filename !== 'undefined') {
                                        return await axiosInstance.post(`${CONSTANT.API.HOTEL.IMAGE}`, {
                                            filename
                                        })
                                            .then(mRes => {
                                                if (mRes && mRes.data && mRes.data.code === 200) {
                                                    const { data } = mRes.data;
                                                    if (data) {
                                                        return data._id;
                                                    }
                                                }
                                            }
                                            )
                                    }
                                }
                            });    
                        arrImage.push(idImage);
                    }
                }
                else{
                    arrImage.push(item.uid);
                }
            }
        }
        return arrImage;
    };

    /*================= Select ============== */
    const onChangeSelect = (type) => async (value) => {
        switch (type) {
            case 'type':
                break;
            case 'area':
                setCountryData([]);
                setCityData([]);
                form.setFieldsValue({
                    country: null,
                    city: null
                })
                // load country
                await axiosInstance.get(`${CONSTANT.API.DESTINATION.COUNTRY}?perPage=-1&area=${value}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            setCountryData(res.data.data.docs);
                        }
                    });
                break;
            case 'country':
                setCityData([]);
                form.setFieldsValue({
                    city: null
                })
                // load city
                await axiosInstance.get(`${CONSTANT.API.DESTINATION.CITY}?perPage=-1&country=${value}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            setCityData(res.data.data.docs);
                        }
                    });
                break;
            default:
                break;
        }
    }

    // submit
    const onFinish = async (values) => {
        values.images = await saveImage();    
        values.loc = loc;
        values.is_active = values.is_active==='true'?true:false;        
        //Action hotel
        if (!id) {
            await axiosInstance.post(`${CONSTANT.API.HOTEL.HOTEL}`, values)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    message.success('Thêm mới thành công!')
                        .then(() => props.history.push('/hotel'))
                }
                else {
                    message.error('Thêm mới thất bại! Vui lòng thử lại sau.');
                }
            });
        }                
        else{
            await axiosInstance.patch(`${CONSTANT.API.HOTEL.HOTEL}/${id}`, values)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    message.success('Sửa thông tin thành công!')
                        .then(() => props.history.push('/hotel'))
                }
                else {
                    message.error('Sửa thông tin thất bại! Vui lòng thử lại sau.');
                }
            });
        }
    };

    // effect
    useEffect(() => {
        // load area
        loadArea();
        // load type
        loadTypeHotel();
        // load hotel Facilitie
        loadFacility();
        //Load hotel
        if (id && id !== '') {
            loadHotel();
        }
    }, []);

    // load category hotel
    const loadTypeHotel = async () => {
        await axiosInstance.get(`${CONSTANT.API.HOTEL.TYPE}?perPage=-1`)
            .then(async res => {
                if (res && res.data && res.data.code === 200) {
                    setTypeData(res.data.data.docs);
                }
            });
    }
    // load Area
    const loadArea = async () => {
        await axiosInstance.get(`${CONSTANT.API.DESTINATION.AREA}?perPage=-1&is_parent=true`)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    setAreaData(res.data.data.docs);
                }
            });
    }

    // load FACILITY
    const loadFacility = async () => {
        await axiosInstance.get(`${CONSTANT.API.HOTEL.FACILITY}?perPage=-1&type=hotel`)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    setFacilitiesData(res.data.data.docs);
                }
            });
    }
    const loadHotel = async () => {
        setLoading(true);        
        await axiosInstance.get(`${CONSTANT.API.HOTEL.HOTEL}/${id}`)
            .then(async res => {
                if (res.data.data && res.data.data.area && res.data.data.area._id) {
                    await axiosInstance.get(`${CONSTANT.API.DESTINATION.COUNTRY}?perPage=-1&area=${res.data.data.area._id}`)
                        .then(async res1 => {
                            if (res1 && res1.data && res1.data.code === 200) {
                                await setCountryData(res1.data.data.docs);
                            }

                        });
                }
                // load city by country if exist country
                if (res.data.data && res.data.data.area && res.data.data.country._id) {
                    // load city
                    await axiosInstance.get(`${CONSTANT.API.DESTINATION.CITY}?perPage=-1&country=${res.data.data.country._id}`)
                        .then(async res2 => {
                            if (res2 && res2.data && res2.data.code === 200) {
                                await setCityData(res2.data.data.docs);
                            }
                        });
                }
                if (res && res.data && res.data.code === 200) {
                    const { data } = res.data;
                    setLoc(data.loc);
                    form.setFieldsValue({
                        _id:data._id,
                        name: data.name,
                        description: data.description,
                        content: data.content,
                        address: data.address ? data.address : "",
                        area: data.area ? data.area._id:'',
                        country: data.country._id,
                        city: data.city ? data.city._id:'',
                        total_vote: data.total_vote,
                        currency: data.currency,
                        price: data.price,
                        facilities: data.facilities && data.facilities.length > 0 ? data.facilities.map(i => i._id) : [],
                        type: data.type ? data.type._id:'',
                        price_extrabed: data.price_extrabed,
                        vat_fee: data.vat_fee,
                        service_fee: data.service_fee,
                        is_active: data.is_active ? 'true' : 'false',
                        isolation: data.isolation
                    });
                    if (res.data.data.images && res.data.data.images.length > 0) {
                        let images = [];
                        for (let i = 0; i < res.data.data.images.length; i++) {
                            let image = res.data.data.images[i];
                            images = images.concat({
                                uid: image._id,
                                name: CONSTANT.STATIC_URL + image.filename,
                                status: 'done-add',
                                url: CONSTANT.STATIC_URL + image.filename,
                                thumbUrl: CONSTANT.STATIC_URL + image.filename
                            });
                        }
                        setImageList(images);
                    }
                }
            })
            .catch(error => message.warning('Hotel không tồn tại!' + error.message, 2.5).then(() => props.history.push('/hotel')))
        setLoading(false);
    }
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/hotel">Khách sạn</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {id && id !== '' ? 'Sửa thông tin' : 'Thêm mới'}
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {
                    loading &&
                    <div style={{ textAlign: 'center' }}>
                        <Spin />
                    </div>
                }

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={{
                        name: "",
                        description: "",
                        address: "",
                        area: "",
                        country: "",
                        city: "",
                        total_vote: "",
                        currency: null,
                        price: null,
                        loc: null,
                        related: [],
                        rooms: [],
                        facilities: [],
                        images: null,
                        type: "",
                        price_extrabed: null,
                        vat_fee: null,
                        service_fee: null,
                        is_active: "false",
                        isolation: false
                    }}>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={18}>
                            <Form.Item
                                label="Tên"
                                name="name"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập tên Hotel!' },
                                ]}
                            >
                                <Input placeholder="Tên hotel" />
                            </Form.Item>
                            <Form.Item
                                label="Mô tả" name="description"
                                tooltip="Mô tả ngắn gọn"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập mô tả ngắn gọn' },
                                ]}>
                                <Input.TextArea placeholder="Nhập mô tả ngắn gọn" rows={4} />
                            </Form.Item>
                            <Form.Item
                                label="Tiện nghi" name="facilities"
                                tooltip="Tiện nghi của khách sạn"
                            >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    placeholder="Vui lòng chọn tiện nghi"
                                    style={{ width: '100%' }}
                                >
                                    {
                                        facilitiesData.length > 0 &&
                                        facilitiesData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Địa chỉ" name="address">
                                <Autocomplete
                                    apiKey={'AIzaSyBVKrdPwfYZpv9qzUSSTwAxNt2u-ozJMww'}
                                    options={{
                                        types: ["address"]
                                    }}
                                    language="vi"
                                    className='ant-input'
                                    onPlaceSelected={async (place) => {
                                        await setLoc({
                                            "coordinates": [
                                                place.geometry.location.lat(),
                                                place.geometry.location.lng()
                                            ],
                                            "type": "Point"
                                        });
                                        form.setFieldsValue({ address: place.formatted_address });
                                    }}
                                />
                            </Form.Item>
                            {/* Begin Images */}
                            <Form.Item label="Hình ảnh">
                                <ImgCrop
                                    rotate
                                    modalTitle="Sửa ảnh"
                                    modalOk="Đồng ý"
                                    aspect={1200 / 628}
                                    quality={0.8}
                                    modalWidth={800}
                                >
                                    <Upload
                                        listType="picture-card"
                                        action={`${CONSTANT.API.EXTRA.UPLOAD_IMAGE}`}
                                        fileList={imageList}
                                        onPreview={onPreviewImages}
                                        onChange={onChangeImages}
                                        headers={{
                                            'X-TK': CONSTANT.API.KEY
                                        }}
                                        onRemove={onRemove}
                                    >
                                        {
                                            imageList.length < 15 &&
                                            <div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>Chọn hình</div>
                                            </div>
                                        }
                                    </Upload>
                                </ImgCrop>
                                <Modal
                                    visible={previewVisible}
                                    title={previewTitle}
                                    footer={null}
                                    onCancel={() => setPreviewVisible(false)}>
                                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </Form.Item>
                            {/* End Images */}
                            <Form.Item
                                label="Tổng quát"
                                name="content"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập mô tả' },
                                ]}>
                                <JoditEditor
                                    config={{ height: 485 }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                            <Form.Item label="Khu vực"
                                name="area"
                                required tooltip="Khu vực: Châu Á, Châu Âu, Châu Mỹ"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn khu vực!' }
                                ]}>
                                <Select
                                    placeholder="Chọn khu vực"
                                    onChange={onChangeSelect('area')}
                                    style={{ width: '100%' }}>
                                    {
                                        areaData.length > 0 &&
                                        areaData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Quốc gia"
                                name="country"
                                required tooltip="Trường này bắt buộc chọn"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn quốc gia!' }
                                ]}>
                                <Select
                                    showSearch
                                    placeholder="Chọn quốc gia"
                                    optionFilterProp="children"
                                    onChange={onChangeSelect('country')}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: '100%' }}
                                    onFocus={COMMON.offAutoCompleteSelect()}>
                                    {
                                        countryData.length > 0 &&
                                        countryData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Thành phố"
                                name="city"
                                required tooltip="Trường này bắt buộc chọn"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn thành phố!' }
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Chọn thành phố"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: '100%' }}
                                    onFocus={COMMON.offAutoCompleteSelect()}
                                >
                                    {
                                        cityData.length > 0 &&
                                        cityData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Loại hotel"
                                name="type"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn loại khách sạn!' }
                                ]}
                            >
                                <Select
                                    placeholder="Chọn loại khách sạn"
                                    optionFilterProp="children"
                                    onChange={onChangeSelect('type')}
                                    style={{ width: '100%' }}>
                                    {
                                        typeData.length > 0 &&
                                        typeData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Loại tiền tệ"
                                name="currency"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn loại tiền tệ!' }
                                ]}
                            >
                                <Select
                                    placeholder="Loại tiền tệ"
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}>
                                    {
                                        currencys.length > 0 &&
                                        currencys.map(item => <Select.Option key={item.value}>{item.label}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Giá"
                                name="price"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập giá' },
                                ]}
                                
                            >
                                <InputNumber placeholder="Nhập giá" style={{ width: '100%' }} min={0} />
                            </Form.Item>
                            <Form.Item
                                label="Giá giường phụ" name="price_extrabed">
                                <InputNumber placeholder="Nhập giá giường phụ" style={{ width: '100%' }} min={0} />
                            </Form.Item>
                            <Form.Item label="Phí phục vụ (tính theo % thì nhập có ký tự %)" name="service_fee">
                                <InputNumber placeholder="5%" style={{ width: '100%' }} min={0} />
                            </Form.Item>
                            <Form.Item label="Thuế VAT (tính theo % thì nhập có ký tự %)" name="vat_fee">
                                <InputNumber placeholder="10%" style={{ width: '100%' }} min={0} />
                            </Form.Item>
                            <Form.Item label="Số sao" name="total_vote">
                                <InputNumber placeholder="Nhập số sao" style={{ width: '100%' }} min={0} />
                            </Form.Item>
                            <Form.Item label="Phục vụ cách ly Covid-19" name="isolation">
                                <Radio.Group>
                                    <Radio value={true}>Có</Radio>
                                    <Radio value={false}>Không</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label="Trạng thái" name="is_active">
                                <Select
                                    placeholder="Chọn trạng thái"
                                    style={{ width: '100%' }}>
                                    {
                                        status.map(item => <Select.Option key={item.value}>{item.label}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className="footer-toolbar">
                        <div className="right">
                            <Button type="primary" icon={<SaveOutlined />}
                                htmlType="submit"
                            >
                                Lưu thông tin
                            </Button>
                        </div>

                    </div>
                </Form>

            </Layout.Content>

        </div>
    )

}

export default ActionHotel;
