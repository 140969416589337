import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Form, Input, Select, Upload, message, Button, InputNumber, Modal, Spin, Collapse
} from 'antd';
import { HomeOutlined, UploadOutlined, SaveOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import JoditEditor from "jodit-react";

import CONSTANT from '../../../config/constant';
import { axiosInstance } from '../../../config/api';
import * as COMMON from '../../../config/common';
const { Panel } = Collapse;

const status = [
    { value: 'publish', label: 'Công khai' },
    { value: 'approve', label: 'Chờ duyệt' },
    { value: 'draft', label: 'Bản nháp' },
];

const currencys = [
    { value: 'VND', label: 'VND' },
];

const ServiceAction = (props) => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    /*================= Editor ============== */

    //define variable
    const [categoriesData, setcategoriesData] = useState([]);
    const [areaData, setAreaData] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [cityData, setCityData] = useState([]);
    /*================= Images ============== */
    const [imageList, setImageList] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewTitle, setPreviewTitle] = useState('');
    const [previewImage, setPreviewImage] = useState('');
    const onChangeImages = ({ fileList }) => {
        setImageList(fileList);
    }

    const onPreviewImages = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }

        setPreviewImage(src);
        setPreviewVisible(true);
        let _previewTitle = file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
        setPreviewTitle(_previewTitle);

    };
    const onRemove = async file => {

        // remove 
    }
    // save image
    const saveImage = async () => {
        let arrImage = [];
        if (imageList.length > 0) {
            for (const item of imageList) {
                if (item.status === 'done') {
                    if (item.response.code === 200 && item.response.data && item.response.data.filename) {
                        let idImage = await axiosInstance.post(`${CONSTANT.API.EXTRA.SAVE_FILE}`, {
                            filename: item.response.data.filename,
                            type: "service"
                        })
                            .then(async res => {
                                if (res && res.data && res.data.code === 200) {
                                    let filename = res.data.data.url;
                                    if (typeof filename !== 'undefined') {
                                        return await axiosInstance.post(`${CONSTANT.API.SERVICE.IMAGE}`, {
                                            filename
                                        })
                                            .then(mRes => {
                                                if (mRes && mRes.data && mRes.data.code === 200) {
                                                    const { data } = mRes.data;
                                                    if (data) {
                                                        return data._id;
                                                    }
                                                }
                                            }
                                            )
                                    }
                                }
                            });
                        arrImage.push(idImage);
                    }
                }
                else {
                    arrImage.push(item.uid);
                }
            }
        }
        return arrImage;
    };

    /*================= Select ============== */
    const onChangeSelect = (type) => async (value) => {
        switch (type) {
            case 'area':
                setCountryData([]);
                setCityData([]);
                form.setFieldsValue({
                    country: null,
                    city: null
                })
                // load country
                await axiosInstance.get(`${CONSTANT.API.DESTINATION.COUNTRY}?perPage=-1&area=${value}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            setCountryData(res.data.data.docs);
                        }
                    });
                break;
            case 'country':
                setCityData([]);
                form.setFieldsValue({
                    city: null
                })
                // load city
                await axiosInstance.get(`${CONSTANT.API.DESTINATION.CITY}?perPage=-1&country=${value}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            setCityData(res.data.data.docs);
                        }
                    });
                break;
            default:
                break;
        }
    }

    // submit
    const onFinish = async (values) => {

        let images = await saveImage();
        let content = [
            {
                key: 'overview',
                value: values.overview
            },
            {
                key: 'what_included',
                value: values.what_included
            },
            {
                key: 'additional_info',
                value: values.additional_info
            },
            {
                key: 'cancellation_policy',
                value: values.cancellation_policy
            }
        ];
        let dataSave = {
            name: values.name,
            time: values.time,
            promotion: values.promotion,
            content: content,
            area: values.area,
            country: values.country,
            city: values.city,
            category: values.category,
            price: values.price,
            currency: values.currency,
            images: images,
            related: [],
            //is_active : values.is_active === 'true' ? true : false,
            status: values?.status
        }
        //Action tour
        if (!id) {
            await axiosInstance.post(`${CONSTANT.API.SERVICE.SERVICE}`, dataSave)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Thêm mới dịch vụ thành công', 1).then(() => props.history.push('/service'));
                    }
                    else {
                        message.error('Thêm mới thất bại! Vui lòng thử lại sau.');
                    }
                });
        }
        else {
            await axiosInstance.patch(`${CONSTANT.API.SERVICE.SERVICE}/${id}`, dataSave)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Sửa thông tin thành công!', 1)
                            .then(() => props.history.push('/service'))
                    }
                    else {
                        message.error('Sửa thông tin thất bại! Vui lòng thử lại sau.');
                    }
                });
        }
    };

    // effect
    useEffect(() => {
        // load area
        loadArea();
        // load categorys
        loadCategory();
        //Load tour
        if (id && id !== '') {
            loadTour();
        }
    }, []);

    // load Area
    const loadArea = async () => {
        await axiosInstance.get(`${CONSTANT.API.DESTINATION.AREA}?perPage=-1&is_parent=true`)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    setAreaData(res.data.data.docs);
                }
            });
    }

    // load category
    const loadCategory = async () => {
        await axiosInstance.get(`${CONSTANT.API.SERVICE.CATEGORY}?perPage=-1&is_parent=true&is_active=true`)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    setcategoriesData(res.data.data.docs);
                }
            });
    }
    const loadTour = async () => {
        setLoading(true);
        await axiosInstance.get(`${CONSTANT.API.SERVICE.SERVICE}/${id}`)
            .then(async res => {
                if (res.data.data && res.data.data.area && res.data.data.area._id) {
                    await axiosInstance.get(`${CONSTANT.API.DESTINATION.COUNTRY}?perPage=-1&area=${res.data.data.area._id}`)
                        .then(async res1 => {
                            if (res1 && res1.data && res1.data.code === 200) {
                                await setCountryData(res1.data.data.docs);
                            }

                        });
                }
                // load city by country if exist country
                if (res.data.data && res.data.data.area && res.data.data.country._id) {
                    // load city
                    await axiosInstance.get(`${CONSTANT.API.DESTINATION.CITY}?perPage=-1&country=${res.data.data.country._id}`)
                        .then(async res2 => {
                            if (res2 && res2.data && res2.data.code === 200) {
                                await setCityData(res2.data.data.docs);
                            }
                        });
                }
                if (res && res.data && res.data.code === 200) {
                    const { data } = res.data;

                    form.setFieldsValue({
                        _id: data._id,
                        name: data.name,
                        time: data.time,
                        area: data.area ? data.area._id : '',
                        country: data.country._id,
                        city: data.city ? data.city._id : '',
                        total_vote: data.total_vote,
                        currency: data.currency,
                        price: data.price,
                        category: data?.category?._id,
                        promotion: data.promotion,
                        total_vote: "",
                        status: data?.status,
                        overview: data.overview,
                        what_included: data.what_included,
                        additional_info: data.additional_info,
                        cancellation_policy: data.cancellation_policy,
                        //work_place: data.work_place,
                        //address_service: data.address_service,
                    });

                    if (res.data.data.images && res.data.data.images.length > 0) {
                        let images = [];
                        for (let i = 0; i < res.data.data.images.length; i++) {
                            let image = res.data.data.images[i];
                            if (image) {
                                images = images.concat({
                                    uid: image._id,
                                    name: CONSTANT.STATIC_URL + image.filename,
                                    status: 'done-add',
                                    url: CONSTANT.STATIC_URL + image.filename,
                                    thumbUrl: CONSTANT.STATIC_URL + image.filename
                                });
                            }
                        }
                        setImageList(images);
                    }
                }
            })
        //.catch(error => message.warning('Dịch vụ không tồn tại!' + error.message, 2.5).then(() => props.history.push('/service')))
        setLoading(false);
    }
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/service">Dịch vụ</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {id && id !== '' ? 'Sửa thông tin' : 'Thêm mới'}
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {
                    loading &&
                    <div style={{ textAlign: 'center' }}>
                        <Spin />
                    </div>
                }

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={{
                        name: "",
                        time: "",
                        promotion: "",
                        area: "",
                        country: "",
                        city: "",
                        total_vote: "",
                        currency: 'VND',
                        price: null,
                        overview: '',
                        what_included: '',
                        additional_info: '',
                        cancellation_policy: '',
                        related: [],
                        category: null,
                        images: null,
                        is_active: "false",

                    }}>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={18}>
                            <Form.Item
                                label="Tên"
                                name="name"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập tên dịch vụ!' },
                                ]}
                            >
                                <Input placeholder="Tên dịch vụ" />
                            </Form.Item>
                            <Form.Item
                                label="Thời gian"
                                name="time"
                                /*required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập thời gian!' },
                                ]}*/
                                hidden
                            >
                                <Input placeholder="Nhập thời gian" />
                            </Form.Item>

                            <Collapse defaultActiveKey={['1']} bordered={false}>
                                <Panel header="Tổng quan" key="1" forceRender={true}>
                                    <Form.Item
                                        name="overview"
                                        rules={[
                                            { required: true, message: 'Vui lòng nhập mô tả' },
                                        ]}>
                                        <JoditEditor
                                            config={{ height: 300 }}
                                        />
                                    </Form.Item>
                                </Panel>
                                <Panel header="Dịch vụ bao gồm" key="2" forceRender={true}>
                                    <Form.Item
                                        name="what_included"
                                    >
                                        <JoditEditor
                                            config={{ height: 300 }}
                                        />
                                    </Form.Item>
                                </Panel>

                                <Panel header="Thông tin khác" key="5" forceRender={true}>
                                    <Form.Item
                                        name="additional_info"
                                    >
                                        <JoditEditor
                                            config={{ height: 300 }}
                                        />
                                    </Form.Item>
                                </Panel>
                                <Panel header="Chính sách hủy" key="6" forceRender={true}>
                                    <Form.Item
                                        name="cancellation_policy"
                                    >
                                        <JoditEditor
                                            config={{ height: 300 }}
                                        />
                                    </Form.Item>
                                </Panel>
                            </Collapse>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                            {/* Begin Images */}
                            <Form.Item label="Hình ảnh">
                                <ImgCrop
                                    rotate
                                    modalTitle="Sửa ảnh"
                                    modalOk="Đồng ý"
                                    aspect={1200 / 628}
                                    quality={0.8}
                                    modalWidth={800}
                                >
                                    <Upload
                                        listType="picture"
                                        action={`${CONSTANT.API.EXTRA.UPLOAD_IMAGE}`}
                                        fileList={imageList}
                                        onPreview={onPreviewImages}
                                        onChange={onChangeImages}
                                        headers={{
                                            'X-TK': CONSTANT.API.KEY
                                        }}
                                        onRemove={onRemove}
                                    >
                                        {
                                            imageList.length < 15 &&
                                            <Button icon={<UploadOutlined />}>Chọn ảnh tải lên</Button>
                                        }
                                    </Upload>
                                </ImgCrop>
                                <Modal
                                    visible={previewVisible}
                                    title={previewTitle}
                                    footer={null}
                                    onCancel={() => setPreviewVisible(false)}>
                                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </Form.Item>
                            {/* End Images */}
                            <Form.Item label="Khu vực"
                                name="area"
                                required tooltip="Khu vực: Châu Á, Châu Âu, Châu Mỹ"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn khu vực!' }
                                ]}>
                                <Select
                                    placeholder="Chọn khu vực"
                                    onChange={onChangeSelect('area')}
                                    style={{ width: '100%' }}>
                                    {
                                        areaData.length > 0 &&
                                        areaData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Quốc gia"
                                name="country"
                                required tooltip="Trường này bắt buộc chọn"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn quốc gia!' }
                                ]}>
                                <Select
                                    showSearch
                                    placeholder="Chọn quốc gia"
                                    optionFilterProp="children"
                                    onChange={onChangeSelect('country')}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: '100%' }}
                                    onFocus={COMMON.offAutoCompleteSelect()}>
                                    {
                                        countryData.length > 0 &&
                                        countryData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Thành phố"
                                name="city"
                                required tooltip="Trường này bắt buộc chọn"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn thành phố!' }
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Chọn thành phố"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: '100%' }}
                                    onFocus={COMMON.offAutoCompleteSelect()}
                                >
                                    {
                                        cityData.length > 0 &&
                                        cityData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Danh mục"
                                tooltip="Chọn danh mục của dịch vụ"
                                name="category"
                                placeholder="Chọn danh mục của dịch vụ"
                                required
                                rules={[
                                    { required: true, message: 'Vui lòng chọn danh mục của dịch vụ !' }
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    placeholder="Vui lòng chọn danh mục"
                                    style={{ width: '100%' }}
                                >
                                    {
                                        categoriesData.length > 0 &&
                                        categoriesData.map(lv1 => (
                                            <React.Fragment key={lv1.uid}>
                                                <Select.Option key={lv1._id}>{`♦ ${lv1.name}`}</Select.Option>
                                                {
                                                    lv1.childs.length > 0 &&
                                                    lv1.childs.map(lv2 => (
                                                        <React.Fragment key={lv2.uid}>
                                                            <Select.Option key={lv2._id}>{`   └ ${lv2.name}`}</Select.Option>
                                                            {
                                                                lv2.childs.length > 0 &&
                                                                lv2.childs.map(lv3 => (
                                                                    <Select.Option key={lv3._id}>{`        └ ${lv3.name}`}</Select.Option>
                                                                ))
                                                            }
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </React.Fragment>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Loại tiền tệ"
                                name="currency"
                                hidden
                            >
                                <Select
                                    placeholder="Loại tiền tệ"
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}>
                                    {
                                        currencys.length > 0 &&
                                        currencys.map(item => <Select.Option key={item.value}>{item.label}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Giá"
                                name="price"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập giá' },
                                ]}

                            >
                                <InputNumber
                                    placeholder="Nhập giá"
                                    formatter={value => `VND ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(`VND`, '').replace(/\\s?|(,*)/g, '')}
                                    style={{ width: '100%' }}
                                    min={0}
                                />
                            </Form.Item>

                            <Form.Item label="Trạng thái" name="status">
                                <Select
                                    placeholder="Chọn trạng thái"
                                    style={{ width: '100%' }}>
                                    {
                                        status.map(item => <Select.Option key={item.value}>{item.label}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className="footer-toolbar">
                        <div className="right">
                            <Button type="primary" icon={<SaveOutlined />}
                                htmlType="submit"
                            >
                                Lưu thông tin
                            </Button>
                        </div>

                    </div>
                </Form>

            </Layout.Content>

        </div>
    )

}

export default ServiceAction;
