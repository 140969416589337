import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Table, Button, Divider, Form, Input, Row, Col, Avatar, Space, Popconfirm,
    Select, message, Tag
} from 'antd';
import {
    HomeOutlined, PlusOutlined, EyeOutlined, DeleteOutlined,
    SearchOutlined, RedoOutlined
} from '@ant-design/icons';
import moment from 'moment';

import { axiosInstance } from '../../../config/api';
import CONSTANT from '../../../config/constant';
import { currencyFormat } from '../../../config/common';


const getRandomuserParams = params => ({
    perPage: params.pagination.pageSize,
    page: params.pagination.current,
    //is_deleted: false,
    sort: params.sort ? params.sort : `{"created_at": -1}`,
    ...params.options
});

export default class ServiceBooking extends React.PureComponent {

    constructor(props) {
        super(props);

        // define column table
        this.columns = [
            { title: '#', dataIndex: 'uid' },
            { title: 'Tên dịch vụ', dataIndex: 'items', render: items => items.length > 0 ? items[0]?.service?.name : '' },
            {
                title: 'Tổng cộng', dataIndex: 'total',
                render: total => `${currencyFormat(total)} VND`
            },
            {
                title: 'Trạng thái', dataIndex: 'status', width: '120px',
                render: status => <Tag color={`${CONSTANT.STATUS_COLOR[`${status}`]}`}>{CONSTANT.STATUS[`${status}`]}</Tag>
            },
            {
                title: 'Ngày đặt', dataIndex: 'created_at', sorter: true, width: '170px',
                render: created_at => `${moment(new Date(created_at)).format(CONSTANT.DATE_FORMAT)}`
            },

            {
                title: 'Hành động', width: '180px',
                render: (text, record) => (
                    <Space size="middle">
                        <Link to={`/service/booking/${record._id}`}><EyeOutlined /> Xem</Link>
                        <Divider type="vertical" />
                        <Popconfirm title="Bạn có chắc xóa dịch vụ này?" onConfirm={() => this.handleDelete(record._id)}>
                            <a><DeleteOutlined /> Xóa</a>
                        </Popconfirm>
                    </Space>
                )
            }
        ];
        this.state = {
            selectedRowKeys: [],
            loading: false,
            data: [],
            pagination: {
                current: 1,
                pageSize: 10
            }
        };
    }

    handleDelete = async (key) => {
        if (key) {
            await axiosInstance.delete(`${CONSTANT.API.SERVICE.ORDER}/${key}`)
                .then((res) => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Xóa thành công!');
                        const dataSource = [...this.state.data];
                        this.setState({
                            data: dataSource.filter((item) => item._id !== key),
                        });
                    }
                    else {
                        message.error('Xóa không thành công');
                    }
                })
                .catch(err => message.error('Xóa không thành công'))
        }
    }

    async componentDidMount() {
        const { pagination } = this.state;
        this.fetch({ pagination });
    }

    handleTableChange = (pagination, filters, sorter) => {
        let option = { pagination };
        if (Object.keys(sorter).length > 0) {
            option.sort = `{"${sorter.field}": ${sorter.order === 'ascend' ? 1 : -1}}`;
        }
        this.fetch(option);
    }

    fetch = async (params = {}) => {
        this.setState({ loading: true });
        let query = new URLSearchParams(getRandomuserParams(params)).toString();
        await axiosInstance.get(`${CONSTANT.API.SERVICE.ORDER}?${query}`)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    const { docs, total } = res.data.data;
                    this.setState({
                        loading: false,
                        data: docs,
                        pagination: {
                            ...params.pagination,
                            total
                        }
                    })
                }

            })
            .catch(err => {
                message.warning('Đã có lỗi xảy ra! Vui lòng kiểm tra lại');
                this.setState({ loading: false })
            })
    }
    // on search
    onSearch = async (values) => {
        const { pagination } = this.state;
        let options = {};
        if (values.field === 'name')
            options.name = values.q;
        if (values.field === 'uid')
            options.uid = values.q.replace('TNL-T', '');

        this.fetch({
            pagination,
            options
        });
    }

    reload = () => {
        const { pagination } = this.state;
        this.fetch({ pagination });
        this.formRef.resetFields();
    }

    render() {
        const { loading, data, pagination } = this.state;

        return (
            <div>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item >
                        <Link to="/"><HomeOutlined /></Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/service">Dịch vụ</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        Booking
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Layout.Content className="layout-content">
                    {/* Begin form search */}
                    <Form
                        layout="inline"
                        onFinish={this.onSearch}
                        style={{ marginBottom: 20 }}
                        ref={ref => this.formRef = ref}
                        initialValues={{
                            field: 'uid'
                        }}
                    >
                        <Form.Item name="q" required rules={[
                            { required: true, message: 'Vui lòng nhập ID hoặc Email' }
                        ]}>
                            <Input
                                type="text"
                                placeholder="Nhập ID hoặc Email"
                                style={{ width: 500 }}
                            />
                        </Form.Item>
                        <Form.Item name="field">
                            <Select
                                style={{ width: 150 }}
                            >
                                <Select.Option value="uid">ID</Select.Option>
                                <Select.Option value="name">Email</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
                                Tìm
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button icon={<RedoOutlined />} onClick={this.reload}>
                                Đặt lại
                            </Button>
                        </Form.Item>
                    </Form>
                    {/* End form search */}
                    <Divider />


                    <Table
                        columns={this.columns}
                        dataSource={data}
                        loading={loading}
                        pagination={pagination}
                        rowKey={record => record._id}
                        onChange={this.handleTableChange}
                    />
                </Layout.Content>
            </div>
        )
    }
}
