import React, { useRef, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Form, Input, Select, message, Button, Tabs, Spin, Upload
} from 'antd';
import { HomeOutlined, SaveOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

import CONSTANT from '../../../config/constant';
import * as Service from '../../../config/service';
import * as COMMON from '../../../config/common';

const status = [
    { value: 'published', label: 'Công khai' },
    { value: 'draft', label: 'Bản nháp' },
];

const EditCity = (props) => {

    const [form] = Form.useForm();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    const [loadingAvatar, setLoadingAvatar] = useState(false);
    const [avatar, setAvatar] = useState();
    const [image, setImage] = useState();
    const [countries, setCountries] = useState([]);

    // on change upload image
    const onChangeImages = async ({ file }) => {
        if (file.status === 'uploading')
            setLoadingAvatar(true);
        if (file.status === 'done') {
            setLoadingAvatar(false);
            setAvatar(file?.response?.data.filename);
        }
    }

    // xử lý lưu image
    const saveImage = async () => {
        if (avatar) {
            return await Service.post(`${CONSTANT.API.EXTRA.SAVE_FILE}`, {
                filename: avatar,
                type: 'city'
            })
                .then(res => {
                    if (res?.data.code === 200) {
                        return res.data.data.url;
                    }
                })
        }
        return null;
    }

    // Load các thông tin ban đầu
    useEffect(() => {
        // load thông tin quốc gia
        const loadDetail = async () => {
            if (typeof id !== 'undefined') {
                return await Service.get(`${CONSTANT.API.DESTINATION.CITY}/${id}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            return res.data.data;
                        }
                    })
                    .catch(error => {
                        message.warning('Lỗi lấy thông tin thành phố!');
                    });
            }
        }
        // load thông tin quốc gia
        const loadCountries = async () => {
            return await Service.get(`${CONSTANT.API.DESTINATION.COUNTRY}`, { perPage: -1 })
                .then(res => {
                    if (res?.data.code === 200)
                        return res.data.data.docs;
                })
                .catch(error => {
                    message.warning('Không lấy được thông tin khu vực!');
                });
        }

        let mounted = true;
        setLoading(true);
        loadDetail().then(data => {
            if (mounted) {
                if (data) {
                    form.setFieldsValue({
                        name: data.name,
                        description: data.description,
                        name_en: data.translations.en.name,
                        description_en: data.translations.en.description,
                        country: data.country,
                        status: data.is_active === true ? 'published' : 'draft'
                    });
                    setImage(data.image);
                }
                setLoading(false);
            }
        })

        loadCountries().then(data => {
            if (mounted) {
                setCountries(data);
                setLoading(false);
            }
        })

        return () => {
            mounted = false;
        }

    }, []);


    // submit
    const onFinish = async (values) => {
        if (avatar) {
            let image = await saveImage();
            values.image = image;
        }
        values.is_active = values.status === 'published' ? true : false;
        values.translations = {
            en: {
                name: values.name_en,
                description: values.description_en
            }
        }
        setSaving(true);
        // Create
        await Service.patch(`${CONSTANT.API.DESTINATION.CITY}/${id}`, values)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    message.success('Lưu thông tin thành công!', 1).then(() => props.history.push('/location/city'))
                }
                else {
                    message.error('Lưu thông tin thất bại! Vui lòng thử lại sau.');
                }
                setSaving(false);
            }).catch(err => {
                message.error('Lưu thông tin thất bại! Vui lòng thử lại sau.');
                setSaving(false);
            });

    };


    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/page">Thành phố</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Sửa
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {loading && <div style={{ textAlign: 'center' }}><Spin /></div>}
                {
                    !loading &&
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        initialValues={{
                            status: "draft"
                        }}
                    >
                        <Row gutter={16}>
                            <Col xs={24} sm={24} md={18}>
                                <Tabs defaultActiveKey="vi" type="card" size="large">
                                    <Tabs.TabPane tab="Tiếng Việt" key="vi" forceRender={true}>
                                        <Form.Item
                                            label="Tên" name="name"
                                            required tooltip="Trường bắt buộc nhập"
                                            rules={[
                                                { required: true, message: 'Vui lòng nhập tên!' },
                                            ]}
                                        >
                                            <Input placeholder="Tên" />
                                        </Form.Item>
                                        <Form.Item
                                            label="Mô tả ngắn gọn" name="description"
                                        >
                                            <Input.TextArea placeholder="Nhập mô tả ngắn gọn" />
                                        </Form.Item>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane forceRender={true} tab="Tiếng Anh" key="en">
                                        <Form.Item
                                            label="Tên" name="name_en"
                                            required tooltip="Trường bắt buộc nhập"
                                            rules={[
                                                { required: true, message: 'Vui lòng nhập tên!' },
                                            ]}
                                        >
                                            <Input placeholder="Tên" />
                                        </Form.Item>
                                        <Form.Item
                                            label="Mô tả ngắn gọn" name="description_en"
                                        >
                                            <Input.TextArea placeholder="Nhập mô tả ngắn gọn" />
                                        </Form.Item>
                                    </Tabs.TabPane>
                                </Tabs>
                            </Col>
                            <Col xs={24} sm={24} md={6}>

                                <Form.Item
                                    label="Hình ảnh"
                                >
                                    <ImgCrop
                                        rotate
                                        modalTitle="Sửa ảnh"
                                        modalOk="Đồng ý"
                                        aspect={1200 / 800}
                                        quality={0.8}
                                        modalWidth={800}
                                    >
                                        <Upload
                                            action={`${CONSTANT.API.EXTRA.UPLOAD_IMAGE}`}
                                            data={{ type: 'area' }}
                                            listType="picture-card"
                                            showUploadList={false}
                                            onChange={onChangeImages}
                                        >
                                            {
                                                typeof avatar !== 'undefined'
                                                    ? <img src={`${CONSTANT.STATIC_URL_TMP}${avatar}`} alt="avatar" style={{ width: '100%' }} />
                                                    : <>
                                                        {
                                                            image ? <img src={`${COMMON.getImage(image, 'location')}`} alt="avatar" style={{ width: '100%' }} />
                                                                :
                                                                <div>
                                                                    {loadingAvatar ? <LoadingOutlined /> : <PlusOutlined />}
                                                                    <div style={{ marginTop: 8 }}>Tải ảnh</div>
                                                                </div>
                                                        }

                                                    </>
                                            }

                                        </Upload>
                                    </ImgCrop>
                                    {/*  */}
                                </Form.Item>

                                <Form.Item
                                    label="Quốc gia"
                                    name="country"
                                >
                                    <Select
                                        placeholder="Chọn quốc gia"
                                        style={{ width: '100%' }}
                                    >
                                        {
                                            countries?.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Trạng thái"
                                    name="status"
                                >
                                    <Select
                                        placeholder="Chọn trạng thái"
                                        style={{ width: '100%' }}
                                    >
                                        {
                                            status.map(item => <Select.Option key={item.value}>{item.label}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="footer-toolbar">
                            <div className="right">
                                <Button
                                    type="primary" icon={<SaveOutlined />} htmlType="submit"
                                    loading={saving}
                                >
                                    {saving ? 'Đang lưu' : 'Lưu thông tin'}
                                </Button>
                            </div>

                        </div>
                    </Form>
                }


            </Layout.Content>

        </div>
    )

}

export default EditCity;
