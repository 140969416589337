import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Form, Input, Select, message, Button
} from 'antd';
import { HomeOutlined, SaveOutlined } from '@ant-design/icons';

import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';


const status = [
    { value: 'published', label: 'Kích hoạt' },
    { value: 'draft', label: 'Không kích hoạt' },
];

const AddUser = (props) => {

    const [form] = Form.useForm();
    const {role} = useParams();

    const [saving, setSaving] = useState(false);


    // submit
    const onFinish = async (values) => {
        if(typeof role !== 'undefined'){
            values.role = role;
            values.is_active = values.status === 'published' ? true : false;
            // Create
            setSaving(true);
            await axiosInstance.post(`${CONSTANT.API.USER.USER}`, values)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Lưu thông tin thành công!').then(() => props.history.push(`/user/${role}`))
                    }
                    else {
                        message.error('Lưu thông tin thất bại! Vui lòng thử lại sau.');
                        setSaving(false);
                    }
                }).catch(err => {
                    message.error('Lưu thông tin thất bại! Vui lòng thử lại sau.')
                    setSaving(false);
                });
        }
    };

    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/page">Quản lý tài khoản</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Thêm mới
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={{
                        status: "draft"
                    }}
                >
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                label="Họ" name="firstname"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập Họ!' },
                                ]}
                            >
                                <Input placeholder="Họ" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12}>
                            <Form.Item
                                label="Tên" name="lastname"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập tên!' },
                                ]}
                            >
                                <Input placeholder="Tên" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                            <Form.Item
                                label="Email" name="email"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'E-mail chưa đúng định dạng !',
                                    },
                                    {
                                        required: true,
                                        message: 'Vui lòng nhập email E-mail!',
                                    },
                                ]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12}>
                            <Form.Item
                                label="Mật khẩu" name="password"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập mật khẩu!' },
                                ]}
                            >
                                <Input.Password placeholder="Nhập mật khẩu" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12}>
                            <Form.Item
                                label="Nhập lại mật khẩu" name="re_password"
                                dependencies={['password']}
                                hasFeedback
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Vui lòng nhập mật khẩu nhập lại!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Mật khẩu nhập lại không đúng!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder="Nhập lại mật khẩu" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>

                            <Form.Item
                                label="Trạng thái"
                                name="status"
                            >
                                <Select
                                    placeholder="Chọn trạng thái"
                                    style={{ width: '100%' }}
                                >
                                    {
                                        status.map(item => <Select.Option key={item.value}>{item.label}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="footer-toolbar">
                        <div className="right">
                            <Button
                                type="primary" icon={<SaveOutlined />} htmlType="submit"
                                loading={saving}
                            >
                                {saving ? 'Đang lưu' : 'Lưu thông tin'}
                            </Button>
                        </div>

                    </div>
                </Form>

            </Layout.Content>

        </div>
    )

}

export default AddUser;
