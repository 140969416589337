import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Button,
} from "antd";
import { HomeOutlined, SaveOutlined } from "@ant-design/icons";

import JoditEditor from "jodit-react";

import CONSTANT from "../../config/constant";
import { axiosInstance } from "../../config/api";
import Editor from "../../config/editor";

const status = [
  { value: "published", label: "Công khai" },
  { value: "draft", label: "Bản nháp" },
];

const AddPage = (props) => {
  const [form] = Form.useForm();

  /*================= Editor ============== */
  const editorRef = useRef(null);
  const [content, setContent] = useState("");
  const [saving, setSaving] = useState(false);

  // submit
  const onFinish = async (values) => {
    values.is_active = values.status === "published" ? true : false;
    // Create
    setSaving(true);
    await axiosInstance
      .post(`${CONSTANT.API.EXTRA.PAGE}`, values)
      .then((res) => {
        if (res && res.data && res.data.code === 200) {
          message
            .success("Lưu thông tin thành công!")
            .then(() => props.history.push("/page"));
        } else {
          message.error("Lưu thông tin thất bại! Vui lòng thử lại sau.");
          setSaving(false);
        }
      })
      .catch((err) => {
        message.error("Lưu thông tin thất bại! Vui lòng thử lại sau.");
        setSaving(false);
      });
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to="/">
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/page">Trang nội dung</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Thêm mới</Breadcrumb.Item>
      </Breadcrumb>
      <Layout.Content className="layout-content">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            status: "draft",
          }}
        >
          <Row gutter={16}>
            <Col xs={24} sm={24} md={18}>
              <Form.Item
                label="Tên trang"
                name="name"
                required
                tooltip="Trường bắt buộc nhập"
                rules={[
                  { required: true, message: "Vui lòng nhập tên trang!" },
                ]}
              >
                <Input placeholder="Tên trang" />
              </Form.Item>

              <Form.Item label="Mô tả ngắn gọn" name="description">
                <Input.TextArea placeholder="Nhập mô tả ngắn gọn" />
              </Form.Item>

              <Form.Item label="Nội dung" name="content">
                <JoditEditor
                  ref={editorRef}
                  value={content}
                  config={Editor.config}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item label="Trạng thái" name="status">
                <Select placeholder="Chọn trạng thái" style={{ width: "100%" }}>
                  {status.map((item) => (
                    <Select.Option key={item.value}>{item.label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <div className="footer-toolbar">
            <div className="right">
              <Button
                type="primary"
                icon={<SaveOutlined />}
                htmlType="submit"
                loading={saving}
              >
                {saving ? "Đang lưu" : "Lưu thông tin"}
              </Button>
            </div>
          </div>
        </Form>
      </Layout.Content>
    </div>
  );
};

export default AddPage;
