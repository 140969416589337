import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Form, Input, Select, Upload, message, Button, Modal, Spin
} from 'antd';
import { HomeOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import Autocomplete from "react-google-autocomplete";
import JoditEditor from "jodit-react";
import CONSTANT from '../../../config/constant';
import { axiosInstance } from '../../../config/api';
import * as COMMON from '../../../config/common';

const status = [
    { value: 'publish', label: 'Công khai' },
    { value: 'approve', label: 'Chờ duyệt' },
    { value: 'draft', label: 'Bản nháp' },
];

const HospitalAction = (props) => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    /*================= Editor ============== */

    //define variable
    const [typeData, setTypeData] = useState([]);
    const [facilitiesData, setFacilitiesData] = useState([]);
    const [areaData, setAreaData] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [wardData, setWardData] = useState([]);
    const [loc, setLoc] = useState(null);
    /*================= Images ============== */
    const [imageList, setImageList] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewTitle, setPreviewTitle] = useState('');
    const [previewImage, setPreviewImage] = useState('');
    const onChangeImages = ({ fileList }) => {
        setImageList(fileList);
    }
    const onPreviewImages = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }

        setPreviewImage(src);
        setPreviewVisible(true);
        let _previewTitle = file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
        setPreviewTitle(_previewTitle);

    };
    const onRemove = async file => {
        // remove 
    }
    // save image
    const saveImage = async () => {
        let arrImage = [];
        if (imageList.length > 0) {
            for (const item of imageList) {
                if (item.status === 'done') {
                    if (item.response.code === 200 && item.response.data && item.response.data.filename) {
                        let idImage = await axiosInstance.post(`${CONSTANT.API.EXTRA.SAVE_FILE}`, {
                            filename: item.response.data.filename,
                            type: "hospital"
                        })
                            .then(async res => {
                                if (res && res.data && res.data.code === 200) {
                                    let filename = res.data.data.url;
                                    if (typeof filename !== 'undefined') {
                                        return await axiosInstance.post(`${CONSTANT.API.HOSPITAL.IMAGE}`, {
                                            filename
                                        })
                                            .then(mRes => {
                                                if (mRes && mRes.data && mRes.data.code === 200) {
                                                    const { data } = mRes.data;
                                                    if (data) {
                                                        return data._id;
                                                    }
                                                }
                                            }
                                            )
                                    }
                                }
                            });
                        arrImage.push(idImage);
                    }
                }
                else {
                    arrImage.push(item.uid);
                }
            }
        }
        return arrImage;
    };

    /*================= Select ============== */
    const onChangeSelect = (type) => async (value) => {
        switch (type) {
            case 'type':
                break;
            case 'area':
                setCountryData([]);
                setCityData([]);
                form.setFieldsValue({
                    country: null,
                    city: null,
                    ward: null
                })
                // load country
                await axiosInstance.get(`${CONSTANT.API.DESTINATION.COUNTRY}?perPage=-1&area=${value}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            setCountryData(res.data.data.docs);
                        }
                    });
                break;
            case 'country':
                setCityData([]);
                form.setFieldsValue({
                    city: null,
                    ward: null
                })
                // load city
                await axiosInstance.get(`${CONSTANT.API.DESTINATION.CITY}?perPage=-1&country=${value}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            setCityData(res.data.data.docs);
                        }
                    });
                break;
            case 'city':
                setWardData([]);
                form.setFieldsValue({
                    ward: null
                })
                // load city
                await axiosInstance.get(`${CONSTANT.API.DESTINATION.WARD}?perPage=-1&city=${value}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            setWardData(res.data.data.docs);
                        }
                    });
                break;
            default:
                break;
        }
    }

    // submit
    const onFinish = async (values) => {
        values.images = await saveImage();
        values.loc = loc;
        if(!id){
            await axiosInstance.post(`${CONSTANT.API.HOSPITAL.HOSPITAL}`, values)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    message.success('Thêm thông tin thành công.')
                        .then(() => props.history.push('/hospital'))
                }
                else {
                    message.error('Thêm thông tin thất bại! Vui lòng thử lại sau.');
                }
            });
        }
        else{
            await axiosInstance.patch(`${CONSTANT.API.HOSPITAL.HOSPITAL}/${id}`, values)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    message.success('Sửa thông tin thành công.')
                        .then(() => props.history.push('/hospital'))
                }
                else {
                    message.error('Sửa thông tin thất bại! Vui lòng thử lại sau.');
                }
            });
        }
        
    };

    // effect
    useEffect(() => {
        // load area
        loadArea();
        // load type
        loadTypeHospital();
        // load Facilitie
        loadFacility();
        //Load hospital
        loadHospital();
    }, []);

    // load category hospital
    const loadTypeHospital = async () => {
        await axiosInstance.get(`${CONSTANT.API.HOSPITAL.TYPE}?perPage=-1`)
            .then(async res => {
                if (res && res.data && res.data.code === 200) {
                    setTypeData(res.data.data.docs);
                }
            });
    }
    // load Area
    const loadArea = async () => {
        await axiosInstance.get(`${CONSTANT.API.DESTINATION.AREA}?perPage=-1&is_parent=true`)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    setAreaData(res.data.data.docs);
                }
            });
    }

    // load FACILITY
    const loadFacility = async () => {
        await axiosInstance.get(`${CONSTANT.API.HOSPITAL.FACILITY}?perPage=-1`)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    setFacilitiesData(res.data.data.docs);
                }
            });
    }    
    const loadHospital = async () => {
        setLoading(true);
        await axiosInstance.get(`${CONSTANT.API.HOSPITAL.HOSPITAL}/${id}`)
            .then(async res => {
                if (res?.data?.code === 200) {
                    let data = res?.data?.data;
                    if (data?.area) {
                        await axiosInstance.get(`${CONSTANT.API.DESTINATION.COUNTRY}?perPage=-1&area=${data.area._id}`)
                            .then(async res => {
                                if (res && res.data && res.data.code === 200) {
                                    await setCountryData(res.data.data.docs);
                                }

                            });
                    }
                    // load city by country if exist country
                    if (data?.country) {
                        // load city
                        await axiosInstance.get(`${CONSTANT.API.DESTINATION.CITY}?perPage=-1&country=${data.country._id}`)
                            .then(async res => {
                                if (res && res.data && res.data.code === 200) {
                                    await setCityData(res.data.data.docs);
                                }
                            });
                    }
                    // load ward by city if exist city
                    if (data?.city) {
                        // load city
                        await axiosInstance.get(`${CONSTANT.API.DESTINATION.DISTRICT}?perPage=-1&city=${data.city._id}`)
                            .then(async res => {
                                if (res && res.data && res.data.code === 200) {
                                    await setWardData(res.data.data.docs);
                                }
                            });
                    }
                    if (data) {
                        setLoc(data.loc);
                        form.setFieldsValue({
                            type: data?.type?._id || null,
                            name: data?.name,
                            description: data?.description,
                            content: data?.content,
                            address: data?.address || null,
                            area: data?.area?._id || null,
                            country: data?.country?._id || null,
                            city: data?.city?._id || null,
                            district: data?.district?._id || null,
                            total_vote: data.total_vote,
                            facilities: data.facilities && data.facilities.length > 0 ? data.facilities.map(i => i._id) : [],
                            status: data?.status
                        });
                        if (data.images && data.images.length > 0) {
                            let images = [];
                            for (let i = 0; i < data.images.length; i++) {
                                let image = data.images[i];
                                images = images.concat({
                                    uid: image._id,
                                    name: CONSTANT.STATIC_URL + image.filename,
                                    status: 'done-add',
                                    url: CONSTANT.STATIC_URL + image.filename,
                                    thumbUrl: CONSTANT.STATIC_URL + image.filename
                                });
                            }
                            setImageList(images);
                        }
                    }
                }

            })
            .catch(error => message.warning('Cơ sở y tế không tồn tại!', 5).then(() => {} /*props.history.push('/hospital')*/))
        
        setLoading(false);
    }    
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Cơ sở y tế
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {id ? 'Sửa thông tin' : "Thêm mới"}
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {
                    loading &&
                    <div style={{ textAlign: 'center' }}>
                        <Spin />
                    </div>
                }

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={{
                        _id:"",
                        name: "",
                        description: "",
                        address: "",
                        area: "",
                        country: "",
                        city: "",
                        total_vote: "",
                        loc: null,
                        related: [],
                        facilities: [],
                        specialties: [],
                        images: null,
                        type: "",
                        isolation: false,
                        status: 'draft'
                    }}>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={18}>
                            <Row gutter={24}>
                                <Col xs={24} sm={10} md={6}>
                                    <Form.Item
                                        label="Loại cơ sở y tế"
                                        name="type"
                                        required tooltip="Trường bắt buộc nhập"
                                        rules={[
                                            { required: true, message: 'Vui lòng chọn loại cơ sở y tế !' }
                                        ]}
                                    >
                                        <Select
                                            placeholder="Chọn loại cơ sở y tế"
                                            optionFilterProp="children"
                                            onChange={onChangeSelect('type')}
                                            style={{ width: '100%' }}>
                                            {
                                                typeData.length > 0 &&
                                                typeData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={14} md={18}>
                                    <Form.Item
                                        label="Tên cơ sở y tế"
                                        name="name"
                                        required tooltip="Trường bắt buộc nhập"
                                        rules={[
                                            { required: true, message: 'Vui lòng nhập tên cơ sở y tế!' },
                                        ]}
                                    >
                                        <Input placeholder="Tên cơ sở y tế" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                label="Mô tả" name="description"
                                tooltip="Mô tả ngắn gọn"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập mô tả ngắn gọn' },
                                ]}>
                                <Input.TextArea placeholder="Nhập mô tả ngắn gọn" rows={2} />
                            </Form.Item>
                            <Form.Item
                                label="Tiện nghi" name="facilities"
                                tooltip="Tiện nghi của cơ sở y tế"
                            >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    placeholder="Vui lòng chọn tiện nghi"
                                    style={{ width: '100%' }}
                                >
                                    {
                                        facilitiesData.length > 0 &&
                                        facilitiesData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Địa chỉ" name="address">
                                <Autocomplete
                                    apiKey={'AIzaSyBVKrdPwfYZpv9qzUSSTwAxNt2u-ozJMww'}
                                    options={{
                                        types: ["address"]
                                    }}
                                    language="vi"
                                    className='ant-input'
                                    onPlaceSelected={async (place) => {
                                        await setLoc({
                                            "coordinates": [
                                                place.geometry.location.lat(),
                                                place.geometry.location.lng()
                                            ],
                                            "type": "Point"
                                        });
                                        form.setFieldsValue({ address: place.formatted_address });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Giới thiệu tổng quan"
                                name="content"
                                rules={[
                                    { required: true, message: 'Vui lòng giới thiệu tổng quan' },
                                ]}>
                                <JoditEditor
                                    config={{ height: 500 }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                            <Col xs={24} sm={24} md={24}>
                                {/* Begin Images */}
                                <Form.Item label="Hình ảnh">
                                    <ImgCrop
                                        rotate
                                        modalTitle="Sửa ảnh"
                                        modalOk="Đồng ý"
                                        aspect={800 / 600}
                                        quality={0.8}
                                        modalWidth={800}
                                    >
                                        <Upload
                                            listType="picture-card"
                                            action={`${CONSTANT.API.EXTRA.UPLOAD_IMAGE}`}
                                            fileList={imageList}
                                            onPreview={onPreviewImages}
                                            onChange={onChangeImages}
                                            headers={{
                                                'X-TK': CONSTANT.API.KEY
                                            }}
                                            onRemove={onRemove}
                                        >
                                            {
                                                imageList.length < 15 &&
                                                <div>
                                                    <PlusOutlined />
                                                    <div style={{ marginTop: 8 }}>Chọn hình</div>
                                                </div>
                                            }
                                        </Upload>
                                    </ImgCrop>
                                    <Modal
                                        visible={previewVisible}
                                        title={previewTitle}
                                        footer={null}
                                        onCancel={() => setPreviewVisible(false)}>
                                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                    </Modal>
                                </Form.Item>
                                {/* End Images */}
                            </Col>
                            <Col xs={24} sm={24} md={24}>
                                <Form.Item label="Khu vực"
                                    name="area"
                                    required tooltip="Khu vực: Châu Á, Châu Âu, Châu Mỹ"
                                    rules={[
                                        { required: true, message: 'Vui lòng chọn khu vực!' }
                                    ]}>
                                    <Select
                                        placeholder="Chọn khu vực"
                                        onChange={onChangeSelect('area')}
                                        style={{ width: '100%' }}>
                                        {
                                            areaData.length > 0 &&
                                            areaData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24}>
                                <Form.Item label="Quốc gia"
                                    name="country"
                                    required tooltip="Trường này bắt buộc chọn"
                                    rules={[
                                        { required: true, message: 'Vui lòng chọn quốc gia!' }
                                    ]}>
                                    <Select
                                        showSearch
                                        placeholder="Chọn quốc gia"
                                        optionFilterProp="children"
                                        onChange={onChangeSelect('country')}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        style={{ width: '100%' }}
                                        onFocus={COMMON.offAutoCompleteSelect()}>
                                        {
                                            countryData.length > 0 &&
                                            countryData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24}>
                                <Form.Item
                                    label="Thành phố"
                                    name="city"
                                    required tooltip="Trường này bắt buộc chọn"
                                    rules={[
                                        { required: true, message: 'Vui lòng chọn thành phố!' }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Chọn thành phố"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        style={{ width: '100%' }}
                                        onChange={onChangeSelect('city')}
                                        onFocus={COMMON.offAutoCompleteSelect()}
                                    >
                                        {
                                            cityData.length > 0 &&
                                            cityData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24}>
                                <Form.Item
                                    label="Quận/huyện"
                                    name="district"
                                    required tooltip="Trường này bắt buộc chọn"
                                    rules={[
                                        { required: true, message: 'Vui lòng chọn quận/huyện!' }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Chọn quận/huyện"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        style={{ width: '100%' }}
                                        onFocus={COMMON.offAutoCompleteSelect()}
                                    >
                                        {
                                            wardData.length > 0 &&
                                            wardData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Trạng thái" name="status">
                                    <Select
                                        placeholder="Chọn trạng thái"
                                        style={{ width: '100%' }}>
                                        {
                                            status.map(item => <Select.Option key={item.value}>{item.label}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Col>
                    </Row>

                    <div className="footer-toolbar">
                        <div className="right">
                            <Button type="primary" icon={<SaveOutlined />}
                                htmlType="submit"
                            >
                                Lưu thông tin
                            </Button>
                        </div>

                    </div>
                </Form>
            </Layout.Content>
        </div>
    )

}

export default HospitalAction;
