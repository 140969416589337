import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
	Layout, Breadcrumb, Row, Col, Form, Input, Upload, message, Button,
	InputNumber, Radio, Checkbox, Modal, Spin
} from 'antd';
import { HomeOutlined, PlusOutlined, LoadingOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

import CONSTANT from '../../../config/constant';
import { axiosInstance } from '../../../config/api';
import * as COMMON from '../../../config/common';


const playbackTypeData = [
	{ value: 'random', label: 'Outdoor Tour' },
	{ value: 'quest', label: 'Quest' }
];

const status = [
	{ value: 'published', label: 'Công khai' },
	{ value: 'limited', label: 'Giới hạn' },
	{ value: 'draft', label: 'Bản nháp' },
];

const EditRoom = (props) => {

	const { idHotel, idRoom } = useParams();

	const [form] = Form.useForm();

	//define variable
	const [room, setRoom] = useState({});
	const [facilityData, setFacilityData] = useState([]);
	const [saving, setSaving] = useState(false);
	const [loading, setLoading] = useState(false);


	/* ================= Images ============== */

	const [imageList, setImageList] = useState([]);
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewTitle, setPreviewTitle] = useState('');
	const [previewImage, setPreviewImage] = useState('');

	const onChangeImages = async ({ fileList }) => {
		setImageList(fileList);
	}
	const onPreviewImages = async file => {
		let src = file.url;
		if (!src) {
			src = await new Promise(resolve => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}

		setPreviewImage(src);
		setPreviewVisible(true);
		let _previewTitle = file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
		setPreviewTitle(_previewTitle);
	};

	// upload image
	const saveImage = async () => {
		let arrImage = [];
		if (imageList.length > 0) {
			for (const item of imageList) {
				if (item.response && item.response.code === 200 && item.response.data && item.response.data.filename) {
					let filename = await axiosInstance.post(`${CONSTANT.API.EXTRA.SAVE_FILE}`, {
						filename: item.response.data.filename,
						type: "hotel"
					})
						.then(res => {
							if (res && res.data && res.data.code === 200) {
								let filename = res.data.data.url;
								filename = filename.indexOf('http') >= 0 ? filename.replace(`${CONSTANT.STATIC_URL}`, '') : filename;
								return filename;
							}
						});
					arrImage.push(filename);
				}
				else{
					arrImage.push(item.name);
				}

			}
		}
		if (arrImage.length > 0) {
			return arrImage.join(',');
		}
	};

	// onFinish
	const onFinish = async (values) => {
		let image = await saveImage();
		values.image = image;
		// Create room
		setSaving(true);
		await axiosInstance.patch(`${CONSTANT.API.HOTEL.ROOM}/${idRoom}`, values)
			.then(res => {
				if (res && res.data && res.data.code === 200) {
					message.success('Lưu thông tin thành công!')
						.then(() => props.history.push(`/hotel/${idHotel}/room`))
				}
				else {
					message.error('Lưu thông tin thất bại! Vui lòng thử lại sau.');
				}
				setSaving(false);
			});

	};

	//onFinish Failed
	const onFinishFailed = () => {
		message.warning('Vui lòng nhập đầy đủ các thông tin bắt buộc!');
	}

	// effect
	useEffect(() => {
		// load room
		loadRoom();
		// load facility
		loadFacility();
	}, []);

	// load room
	const loadRoom = async () => {
		if (typeof idRoom !== 'undefined') {
			setLoading(true);
			await axiosInstance.get(`${CONSTANT.API.HOTEL.ROOM}/${idRoom}`)
				.then(res => {
					if (res && res.data && res.data.code === 200) {
						setRoom(res.data.data);
						// create list image
						const {image} = res.data.data;
						if(image.length > 0){
							let arrImages = image.split(',');	
							let imageList = [];
							for(const [index,itemImage] of arrImages.entries()){
								imageList.push({	
										uid: index,
										name: itemImage,
										status: 'done',
										url: `${CONSTANT.STATIC_URL}${itemImage}`,
								})
							}
							setImageList(imageList);
						}
					}
					setLoading(false);
				})
				.catch(error => {
					message.warning('Phòng không tồn tại!', 2.5).then(() => props.history.goBack());
					setLoading(false);
				});
		}
		else {
			props.history.goBack();
		}
	}

	// load facility
	const loadFacility = async () => {
		await axiosInstance.get(`${CONSTANT.API.HOTEL.FACILITY}?perPage=-1&type=room`)
			.then(res => {
				if (res && res.data && res.data.code === 200) {
					setFacilityData(res.data.data.docs);
				}
			});
	}


	return (
		<div>
			<Breadcrumb style={{ margin: '16px 0' }}>
				<Breadcrumb.Item >
					<Link to="/"><HomeOutlined /></Link>
				</Breadcrumb.Item>
				<Breadcrumb.Item>
					<Link to="/hotel">Khách sạn</Link>
				</Breadcrumb.Item>
				<Breadcrumb.Item>
					<Link to={`/hotel/${idHotel}/room`}>Danh sách phòng</Link>
				</Breadcrumb.Item>
				<Breadcrumb.Item>
					Sửa phòng
				</Breadcrumb.Item>
			</Breadcrumb>
			<Layout.Content className="layout-content">
				{
					loading &&
					<div style={{ textAlign: 'center' }}>
						<Spin />
					</div>
				}
				{
					!loading && Object.keys(room).length > 0 && 
					<Form
						form={form}
						layout="vertical"
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						initialValues={{
							price: room.price,
							num_room: room.num_room,
							num_room_empty: room.num_room_empty, 
							num_twinbed: room.num_twinbed, 
							num_bed: room.num_bed,
							breakfast: room.breakfast, 
							extrabed: room.extrabed, 
							acreage: room.acreage, 
							num_person: room.num_person,
							is_active: room.is_active,
							note: room.note,
							facilities: room.facilities,
							name: room.name
						}}
					>

						<Row gutter={16}>
							<Col xs={24} sm={24} md={18}>
								<Form.Item
									label="Tên phòng" name="name"
									required tooltip="Trường bắt buộc nhập"
									rules={[
										{ required: true, message: 'Vui lòng nhập tên phòng!' },
									]}
								>
									<Input placeholder="Tên phòng" />
								</Form.Item>
								<Row gutter={16}>
									<Col xs={8} sm={8} md={8}>
										<Form.Item
											label="Giá phòng" name="price"
										>
											<InputNumber
												formatter={value => `đ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
												parser={value => value.replace(/\đ\s?|(,*)/g, '')}
												style={{ width: '100%' }}
												min={0}
											/>
										</Form.Item>
									</Col>

									<Col xs={8} sm={8} md={8}>
										<Form.Item
											label="Số lượng phòng" name="num_room"
										>
											<InputNumber
												style={{ width: '100%' }}
												min={0}
											/>
										</Form.Item>
									</Col>
									<Col xs={8} sm={8} md={8}>
										<Form.Item
											label="Số phòng còn trống" name="num_room_empty"
										>
											<InputNumber
												style={{ width: '100%' }}
												min={0}
												max={form.getFieldValue('num_room')}
											/>
										</Form.Item>
									</Col>
								</Row>

								<Form.Item
									name="breakfast"
									label="Ăn sáng"
								>
									<Radio.Group>
										<Radio value="no">Không bao gồm bữa sáng</Radio>
										<Radio value="yes">Bao gồm bữa sáng</Radio>
									</Radio.Group>
								</Form.Item>

								<Row gutter={16}>
									<Col xs={12} sm={12} md={12}>
										<Form.Item
											label="Số giường đơn" name="num_bed"
										>
											<InputNumber
												style={{ width: '100%' }}
												min={0}
											/>
										</Form.Item>
									</Col>

									<Col xs={12} sm={12} md={12}>
										<Form.Item
											label="Số giường đôi" name="num_twinbed"
										>
											<InputNumber
												style={{ width: '100%' }}
												min={0}
											/>
										</Form.Item>
									</Col>
								</Row>

								<Form.Item
									name="extrabed"
									label="Giường phụ"
								>
									<Radio.Group>
										<Radio value="yes">Có</Radio>
										<Radio value="no">Không</Radio>
									</Radio.Group>
								</Form.Item>

								<Row gutter={16}>
									<Col xs={12} sm={12} md={12}>
										<Form.Item
											label="Diện tích" name="acreage"
										>
											<InputNumber
												formatter={value => `Mét vuông ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
												parser={value => value.replace(/\Mét vuông\s?|(,*)/g, '')}
												style={{ width: '100%' }}
												min={0}
											/>
										</Form.Item>
									</Col>

									<Col xs={12} sm={12} md={12}>
										<Form.Item
											label="Số người" name="num_person"
										>
											<InputNumber
												style={{ width: '100%' }}
												min={0}
											/>
										</Form.Item>
									</Col>
								</Row>

								<Form.Item
									label="Tiện nghi" name="facilities"
									required tooltip="Trường bắt buộc nhập"
									rules={[
										{ required: true, message: 'Vui lòng chọn ít nhất một tiện nghi!' },
									]}
								>
									<Checkbox.Group>
										<Row gutter={16}>
											{
												facilityData.map((item, index) => (
													<Col md={6} key={index}>
														<Checkbox value={item._id} style={{ lineHeight: '32px' }}>
															{item.name}
														</Checkbox>
													</Col>
												))
											}
										</Row>
									</Checkbox.Group>

								</Form.Item>


								<Form.Item
									label="Ghi chú" name="note"
								>
									<Input.TextArea placeholder="Nhập ghi chú" rows={3} />
								</Form.Item>

								<Form.Item
									name="is_active"
									label="Trạng thái phòng"
								>
									<Radio.Group>
										<Radio value={true}>Hoạt động</Radio>
										<Radio value={false}>Tạm đóng</Radio>
									</Radio.Group>
								</Form.Item>


							</Col>
							<Col xs={24} sm={24} md={6}>
								<Form.Item
									label="Hình ảnh"
								>
									<ImgCrop
										rotate
										modalTitle="Sửa ảnh"
										modalOk="Đồng ý"
										aspect={1200 / 628}
										quality={0.8}
										modalWidth={800}
									>
										<Upload
											action={`${CONSTANT.API.EXTRA.UPLOAD_IMAGE}`}
											listType="picture"
											fileList={imageList}
											onPreview={onPreviewImages}
											onChange={onChangeImages}
											headers={{
												'X-TK': CONSTANT.API.KEY
											}}
										>
											{
												imageList.length < 11 &&
												<Button icon={<UploadOutlined />}>Chọn ảnh tải lên</Button>
											}
										</Upload>
									</ImgCrop>
									{/*  */}
								</Form.Item>


							</Col>
						</Row>
						<div className="footer-toolbar">
							<div className="right">
								<Button
									type="primary" icon={<SaveOutlined />} htmlType="submit"
									loading={saving}
								>
									{saving ? 'Đang lưu' : 'Lưu thông tin'}
								</Button>
							</div>
						</div>
					</Form>
				}

			</Layout.Content>

			{/* Modal preview image */}
			<Modal
				visible={previewVisible}
				title={previewTitle}
				footer={null}
				onCancel={() => setPreviewVisible(false)}
			>
				<img alt="example" style={{ width: '100%' }} src={previewImage} />
			</Modal>

		</div>
	)

}

export default EditRoom;
