import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Row, Col, Tag, Button, Popconfirm, Input, message, Spin, } from 'antd';
import CONSTANT from '../../../config/constant';
import * as COMMON from '../../../config/common';
import { axiosInstance } from '../../../config/api';
import moment from 'moment';
const arrStatus = {
    'wait-paid': { text: "Chờ thanh toán", color: "orange" },
    'paid': { text: 'Đã thanh toán', color: 'green' },
    'confirmed': { text: 'Đã xác nhận', color: 'blue' },
    'packing': { text: 'Đang đóng gói', color: 'orange' },
    'delivery-in-progress': { text: 'Đang giao hàng', color: 'orange' },
    'customer-received': { text: 'Khách đã nhận hàng', color: 'green' },
    'completed': { text: 'Đã hoàn thành', color: 'green' },
    'abandoned': { text: 'Khách đã hủy', color: 'red' },
    'cancelled': { text: 'Đối tác đã hủy đơn này', color: 'red' },
    'refunded': { text: 'Đã hoàn hoàn tiền', color: 'orange' },
};
const ModalShopDetailOrder = (props) => {
    const [order, setOrder] = useState(null);
    const [transferId, setTransferId] = useState('');
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleCancel = useCallback(() => {
        props.cancel(reload);
    }, [reload]);
    useEffect(() => {
        loadOrder();
    }, [props.orderId]);
    // load Agency    
    const loadOrder = async () => {
        setLoading(true);
        if (props && props.orderId) {
            await axiosInstance.get(`${CONSTANT.API.SHOP.ORDER}/${props.orderId}`)
                .then(async res => {
                    if (res && res.data && res.data.code === 200) {
                        setOrder(res.data.data)
                    }
                })
        }
        setLoading(false);
    }
   const updateTransferId = async () => {
        setLoading(true);
        if (props.orderId && order) {
            let transferValue = {
                payment_id: transferId,
                order: props.orderId,
                status: 'completed',
                amount: order?.total,
                currency: order?.currency,
                service: 'product',
                gateway: 'bank_transfer',
                type: 'bank_transfer',
            }
            debugger;
            await axiosInstance.post(`${CONSTANT.API.SHOP.PAYMENT}/${props.orderId}/payment`, transferValue)
                .then(async res1 => {
                    debugger;
                    await axiosInstance.patch(`${CONSTANT.API.SHOP.ORDER}/${props.orderId}`, { payment_id: transferId, payment_method: 'bank_transfer', payment_method_title: 'Chuyển khoản ngân hàng', actUpd: 'udpStatus', status: 'paid' })
                        .then(async res => {                           
                            if (res && res.data && res.data.code === 200) {
                                await setOrder(res.data.data);                                
                                message.success('Cập nhật thanh toán thành công!')
                            }
                            else {
                                message.error('Cập nhật thanh toán không thành công!');
                            }
                            setLoading(false);
                        })
                        .catch(error => {
                            debugger;
                            setLoading(false);
                            message.error('Cập nhật mã giao dịch không thành công!' + error.message);
                        })
                }).catch(error => {
                    setLoading(false);
                    debugger;
                    message.error('Cập nhật mã giao dịch không thành công!' + error.message);
                })

        }
        else {
            setLoading(false);
            message.error('Đơn hàng không tồn tại !');
        }
    }
    return (
        <Modal title="Chi tiết đơn đặt hàng"
            visible={props.visible}
            onOk={handleCancel}
            onCancel={handleCancel}
            cancelText="Hủy"
            okText="Đóng"
            className="modal-book-facility"
            centered
            okButtonProps={{ className: "btn-global bg" }}
            width={900}
            cancelButtonProps={{ style: { display: 'none' } }}
            maskClosable={false}
        >
            {
                order && <Row gutter={24}>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Họ tên người đặt:
                    </Col>
                    <Col xs={12} md={19}>
                        {order.shipping.first_name + ' ' + order.shipping.last_name}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Địa chỉ giao hàng:
                    </Col>
                    <Col xs={12} md={19}>
                        {order.shipping.address_1 + ',' + order.shipping.address_2 + ',' + order.shipping.city + ',' + order.shipping.country}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Email người đặt:
                    </Col>
                    <Col xs={12} md={7}>
                        {order.shipping.email}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Đ.Thoại người đặt:
                    </Col>
                    <Col xs={12} md={7}>
                        {'(+' + order.shipping.dial_code + ')' + order.shipping.phone}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Ngày đặt hàng:
                    </Col>
                    <Col xs={12} md={7}>
                        {`${moment(new Date(order.created_at)).format('DD/MM/YYYY HH:mm')}`}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Ngày thanh toán:
                    </Col>
                    <Col xs={12} md={7}>
                        {order.date_paymented && `${moment(new Date(order.date_paymented)).format('DD/MM/YYYY HH:mm')}`}
                    </Col>
                    <Col xs={12} md={5} style={{ fontWeight: 'bold', paddingBottom: '15px' }}>
                        Trạng thái:
                    </Col>
                    <Col xs={12} md={7}>
                        {(order?.status && arrStatus[order?.status]) ? <Tag color={arrStatus[order?.status].color}>{arrStatus[order?.status].text}</Tag> : <Tag color='black'>Rác</Tag>}
                    </Col>

                    {
                        order?.status && order?.status === 'wait-paid' && <>
                            <Col xs={12} md={6}>
                                <Input placeholder="Nhập mã giao dịch" onChange={(value) => {debugger; setTransferId(value.target.value)}}/>
                            </Col>
                            <Col xs={12} md={6}>
                                <Popconfirm title={<div>Vui lòng kiểm tra mã chính xác trước khi xác nhận !</div>}
                                    okText="Xác nhận" cancelText="Đóng" onConfirm={() => updateTransferId()}>
                                    <Button type='primary'>
                                        Khách đã chuyển khoản
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </>
                    }
                    <Col xs={24} md={24}>
                        <div className="ant-table">
                            <table className='table' style={{ tableLayout: "auto", width: "100%" }}>
                                <thead className="ant-table-thead">
                                    <tr>
                                        <th className="ant-table-cell">Hình ảnh</th>
                                        <th className="ant-table-cell">Tên sản phẩm</th>
                                        <th className="ant-table-cell">Số lượng</th>
                                        <th className="ant-table-cell">Đơn giá</th>
                                        <th className="ant-table-cell">Thành tiền</th>
                                    </tr>
                                </thead>
                                <tbody className="ant-table-tbody">
                                    {
                                        order.shop && order.shop.map((shop) => (
                                            shop.items && shop.items.length > 0 && shop.items.map((item) => (
                                                <tr className="ant-table-row" key={item._id}>
                                                    <td className="ant-table-cell" style={{ width: '120px' }}>
                                                        <img src={COMMON.getImageVariant(item)} style={{ width: '100%' }}
                                                            alt={item.product.name}
                                                        />
                                                    </td>
                                                    <td className="ant-table-cell" style={{ width: '300px' }}>
                                                        <h4 className="product-title">{item.product.name}</h4>
                                                        <p className="product-attribute">
                                                            {
                                                                item.variant && item.variant.attributes.map((attr, indexAttr) => {
                                                                    let str = attr.options[0].name;
                                                                    if (indexAttr < item.variant.attributes.length - 1)
                                                                        str += " / ";
                                                                    return str + ' ';
                                                                })
                                                            }
                                                        </p>
                                                    </td>
                                                    <td className="ant-table-cell" style={{ textAlign: 'right' }}>
                                                        {item.quantity}
                                                    </td>
                                                    <td className="ant-table-cell" style={{ textAlign: 'right' }}>
                                                        {COMMON.currencyFormat(parseFloat(item.price))} {COMMON.getCurrency(item.product.currency)}
                                                    </td>
                                                    <td className="ant-table-cell" style={{ textAlign: 'right' }}>
                                                        {COMMON.currencyFormat(parseFloat(item.price * item.quantity))} {COMMON.getCurrency(item.product.currency)}
                                                    </td>
                                                </tr>
                                            ))))
                                    }
                                </tbody>
                            </table>
                            <div className="ant-table-footer">
                                <div style={{ float: 'left', width: '100%', padding: '10px 0', borderBottom: '1px #ccc solid' }}>
                                    <span style={{ float: 'left', fontWeight: 700 }}>Tổng tiền hàng</span>
                                    <span style={{ float: 'right', fontWeight: 700 }}>
                                        {order.subtotal && `${COMMON.currencyFormat(order.subtotal)} ${COMMON.getCurrency(order?.currency)}` || 0}
                                    </span>
                                </div>
                                <div style={{ float: 'left', width: '100%', padding: '10px 0', borderBottom: '1px #ccc solid' }}>
                                    <span style={{ float: 'left', fontWeight: 700 }}>Giảm giá</span>
                                    <span style={{ float: 'right', fontWeight: 700 }}>
                                        {order.discount_total && `${COMMON.currencyFormat(order.discount_total)} ${COMMON.getCurrency(order?.currency)}` || 0}
                                    </span>
                                </div>
                                <div style={{ float: 'left', width: '100%', padding: '10px 0', borderBottom: '1px #ccc solid' }}>
                                    <span style={{ float: 'left', fontWeight: 700 }}>Tổng thành tiền</span>
                                    <span style={{ float: 'right', fontWeight: 700 }}>
                                        {order.total && `${COMMON.currencyFormat(order.total)} ${COMMON.getCurrency(order?.currency)}` || 0}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            }
            {
                loading &&
                <div style={{ textAlign: 'center' }}>
                    <Spin />
                </div>
            }
        </Modal>
    )
}
export default ModalShopDetailOrder;
