import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Row, Col, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { isLoggedIn, setAuthTokens } from 'axios-jwt';
import queryString from 'query-string';

import './login.css';
import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';

const Login = (props) => {
    const [form] = Form.useForm();
    const [, forceUpdate] = useState({});
    const [loging, setLoging] = useState(false);

    useEffect(() => {
        forceUpdate({});
        if (isLoggedIn()) window.location.href = '/';

    }, []);

    const onFinish = async (values) => {

        setLoging(true);
        await axiosInstance.post(CONSTANT.API.AUTH.LOGIN,
            {
                type: 0,
                role: 'admin',
                email: values.email,
                password: values.password
            },
            {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            }
        )
            .then(res => {
                setLoging(false);
                if (res && res.data && res.data.code === 200 && res.data.data) {
                    const { role } = res.data.data.user;
                    if (role !== 'admin') {
                        message.warning('Tài khoản này không có quyền truy hệ thống');
                        return;
                    }
                    const { accessToken, refreshToken } = res.data.data.token;
                    setAuthTokens({
                        accessToken, refreshToken
                    });
                    // save to localStorage
                    localStorage.setItem(CONSTANT.STORAGE_KEY_USER, JSON.stringify(res.data.data.user));
                    message.success('Đăng nhập thành công!', 1)
                        .then(() => {
                            let params = queryString.parse(props.location.search);
                            let back = params.back ? params.back : '/';
                            window.location.href = back;
                        })

                }
                else if (res.data.code === 400) {
                    if (res.data.message === 'wrong_email')
                        message.error('Email không đúng!');
                    if (res.data.message === 'wrong_pass')
                        message.error('Mật khẩu không đúng!');
                    return;
                }
                else if (res.data.code === 403) {
                    message.warning('Tài khoản chưa được kích hoạt!'); return;
                }
                else {
                    message.warning('Đăng nhập thất bại. Vui lòng thử lại sau!'); return;
                }
            })
    }


    return (
        <div className="bg-login login">
            <Row>
                <Col
                    lg={{ span: 12, offset: 6 }} xs={{ span: 20, offset: 2 }} sm={{ span: 12, offset: 6 }}
                    md={{ span: 12, offset: 6 }} xl={{ span: 6, offset: 9 }}
                >
                    <div className="logo">
                        <img src="logo-vivmedic.png" alt="logo" />
                    </div>
                    <div className="slogan">Đăng nhập hệ thống quản trị nội dung Vivmedic</div>
                    <Form
                        name="normal_login"
                        className="login-form"
                        onFinish={onFinish}
                        form={form}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                { type: 'email', message: 'Email không hợp lệ!' },
                                { required: true, message: 'Vui lòng nhập Email!' },
                            ]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" className="form-control" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Mật khẩu"
                                className="form-control"
                            />
                        </Form.Item>
                        <Form.Item shouldUpdate>
                            {
                                () => (
                                    <Button
                                        type="primary" htmlType="submit" className="login-form-button"
                                        disabled={
                                            !form.isFieldsTouched(true) ||
                                            !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                        }
                                        loading={loging}
                                    >
                                        Đăng nhập
                                    </Button>
                                )
                            }

                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    )

}
export default Login;