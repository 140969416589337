import React from "react";
import { Link } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Card,
  Select,
  Button,
  Row,
  Col,
  Descriptions,
  Input,
  Spin,
  message, Divider
} from "antd";
import { HomeOutlined, PrinterOutlined } from "@ant-design/icons";
import moment from "moment";
import { axiosInstance } from "../../../config/api";
import CONSTANT from "../../../config/constant";
import { currencyFormat } from "../../../config/common";
import ReactToPrint from "react-to-print";
import './style.print.css';
export default class DetailServiceBooking extends React.PureComponent {
  componentRef = null;
  constructor(props) {
    super(props);
    this.state = {
      arrStatus: [
        { label: "Chờ thanh toán", value: "wait_paid" },
        { label: "Đã thanh toán", value: "paid" },
        { label: "Đã xác nhận", value: "confirmed" },
        { label: "Đã khám", value: "completed" },
        { label: "Không đến", value: "not-come" },
        { label: "Hủy lịch hẹn", value: "cancelled" },
        { label: "Nháp", value: "trash" },
        { label: "Đã hoàn hoàn tiền", value: "refunded" },
      ],
      loading: false,
      detailOrder: null,
      total_date: 0,
      statusOrder: "",
      transferId: "",
      saving: false,
      savingTransaction: false,
      dataTransction: {},
    };
  }

  onChangeSelect = async (value) => {
    this.setState({ statusOrder: value });
  };

  async componentDidMount() {
    // load order
    this.fetch();
    // load transaction
    this.fetchTransaction();
  }

  // load du lieu
  fetch = async () => {
    this.setState({ loading: true });
    if (this.props.match.params.id) {
      await axiosInstance
        .get(`${CONSTANT.API.SERVICE.ORDER}/${this.props.match.params.id}`)
        .then(async (res) => {
          if (res && res.data && res.data.code === 200) {
            console.log(res.data.data);
            await this.setState({
              loading: false,
              detailOrder: res.data.data,
              statusOrder: res.data.data.status,
            });
          }
        });
    } else {
      this.setState({ loading: false });
      message
        .warning("Booking không tồn tại!")
        .then((res) => this.props.history.goBack());
    }
  };
  // load payment transaction
  fetchTransaction = async () => {
    await axiosInstance
      .get(`${CONSTANT.API.TRANSACTION}?order=${this.props.match.params.id}`)
      .then((res) => {
        if (res && res.data && res.data.code === 200) {
          if (
            res.data.data &&
            res.data.data.docs &&
            res.data.data.docs.length > 0
          ) {
            this.setState({
              dataTransction: res.data.data.docs[0],
              transferId: res.data.data.docs[0].payment_id,
            });
          }
        }
      });
  };

  // cap nhat status
  updateStatus = async () => {
    this.setState({ saving: true });
    if (this.props.match.params.id) {
      await axiosInstance
        .patch(`${CONSTANT.API.SERVICE.ORDER}/${this.props.match.params.id}`, {
          status: this.state.statusOrder,
          actUpd: "udpStatus",
        })
        .then((res) => {
          if (res && res.data && res.data.code === 200) {
            message.success("Cập nhật trạng thái thành công!");
          } else {
            message.error("Cập nhật trạng thái không thành công!");
          }
          setTimeout(() => {
            this.setState({ saving: false, detailOrder: res.data.data });
          }, 1000);
        })
        .catch((error) => {
          message.error(
            "Cập nhật trạng thái không thành công!" + error.message
          );
          this.setState({ saving: false });
        });
    } else {
      this.setState({ saving: false });
      message.error("Cập nhật trạng thái không thành công!");
    }
  };
  saveTransaction = async () => {
    const { transferId, detailOrder } = this.state;
    this.setState({ savingTransaction: true });
    // tao moi transaction
    let dataTransction = {
      payment_id: this.state.transferId,
      order: detailOrder._id,
      status: "completed",
      amount: detailOrder.total,
      currency: detailOrder.currency,
      service: "service",
      gateway: "bankTransfer",
      date_completed: new Date(),
      type: "deposit",
    };
    await axiosInstance
      .post(`${CONSTANT.API.TRANSACTION}`, dataTransction)
      .then(async (res) => {
        if (res && res.data && res.data.code === 200) {
          // cap nhat payment id vao order
          await axiosInstance
            .patch(`${CONSTANT.API.SERVICE.ORDER}/${detailOrder._id}`, {
              payment_id: this.state.transferId,
              actUpd: "udpPaymentTransfer",
            })
            .then((res) => {
              if (res && res.data && res.data.code === 200) {
                message.success("Cập nhật mã giao dịch thành công!");
              } else {
                message.error("Cập nhật mã giao dịch không thành công!");
              }
              this.setState({ savingTransaction: false });
            })
            .catch((error) => {
              this.setState({ savingTransaction: false });
              message.error(
                "Cập nhật mã giao dịch không thành công!" + error.message
              );
            });
        }
      })
      .catch((error) => {
        this.setState({ savingTransaction: false });
        message.error(
          "Cập nhật mã giao dịch không thành công!" + error.message
        );
      });
  };
  handleAfterPrint = () => {
    this.setState({ loadingPrint: false })
  };

  handleBeforePrint = () => {
    this.setState({ loadingPrint: true });
  };

  handleOnBeforeGetContent = () => {
    this.setState({ loadingPrint: true });

    return new Promise((resolve) => {
      setTimeout(() => {
        this.setState(
          { loadingPrint: false },
          resolve
        );
      }, 2000);
    });
  };
  setComponentRef = (ref) => {
    this.componentRef = ref;
  };

  reactToPrintContent = () => {
    return this.componentRef;
  };

  reactToPrintTrigger = () => {
    return <Button type="primary" loading={this.state.loadingPrint}><PrinterOutlined />In phiếu hẹn</Button>;
  };
  render() {
    const { loading, detailOrder, saving, savingTransaction, dataTransction } =
      this.state;
    return (
      <div>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>
            <Link to="/">
              <HomeOutlined />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/service">Dịch vụ yu tế</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/service/booking">Booking</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Thông tin chi tiết booking</Breadcrumb.Item>
        </Breadcrumb>
        <Layout.Content>
          {loading && (
            <div style={{ textAlign: "center" }}>
              <Spin />
            </div>
          )}
          {!loading && detailOrder ? (
            <Row gutter={16}>
              <Col xs={24} sm={24} md={18}>
                <div className="layout-content">
                  <Descriptions
                    extra={<ReactToPrint
                      content={this.reactToPrintContent}
                      documentTitle="Phiếu hẹn khám dịch vụ"
                      onAfterPrint={this.handleAfterPrint}
                      onBeforeGetContent={this.handleOnBeforeGetContent}
                      onBeforePrint={this.handleBeforePrint}
                      removeAfterPrint
                      trigger={this.reactToPrintTrigger}
                    />}
                    title={"Booking: #VIVMEDIC-S" + detailOrder.uid}
                    column={{ md: 2 }}
                  >
                    <Descriptions.Item
                      label="Họ tên người đặt"
                      labelStyle={{ color: "#777" }}
                    >
                      {detailOrder?.contact?.fullname || "-"}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Email"
                      labelStyle={{ color: "#777" }}
                    >
                      {detailOrder?.contact.email}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Điện thoại"
                      labelStyle={{ color: "#777" }}
                    >
                      {"+" +
                        detailOrder?.contact.dial_code +
                        detailOrder?.contact.phone}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Địa chỉ"
                      labelStyle={{ color: "#777" }}
                    >
                      {detailOrder?.contact.address || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Ngày đặt"
                      labelStyle={{ color: "#777" }}
                    >
                      {moment(detailOrder.created_at).format("DD/MM/YYYY HH:mm")}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Ngày hẹn khám"
                      labelStyle={{ color: "#777" }}
                    >
                      {moment(detailOrder.date).format("DD/MM/YYYY")} {moment(detailOrder.time).format("HH:mm")}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Tên dịch vụ"
                      labelStyle={{ color: "#777" }}
                    >
                      {detailOrder?.items &&
                        detailOrder?.items.length > 0 &&
                        detailOrder?.items[0].service.name}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Giá dịch vụ"
                      labelStyle={{ color: "#777" }}
                    >
                      {detailOrder?.items &&
                        detailOrder?.items.length > 0 &&
                        `${currencyFormat(detailOrder?.items[0].price)} VNĐ`}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Địa điểm khám"
                      labelStyle={{ color: "#777" }}
                    >
                      {(detailOrder?.work_place === 'at_guest' ? 'Tại nhà khách hàng ' : detailOrder?.place_name)}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Địa chỉ khám"
                      labelStyle={{ color: "#777" }}
                    >
                      {detailOrder?.address_work}
                    </Descriptions.Item>
                  </Descriptions>
                  {
                    detailOrder?.total > 0 &&
                    <Descriptions title="Giao dịch thanh toán" column={{ md: 2 }}>
                      <Descriptions.Item
                        label="Phương thức thanh toán"
                        labelStyle={{ color: "#777" }}
                      >
                        {CONSTANT.PAY_METHOD[`${detailOrder?.payment_method}`] ||
                          "-"}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Tình trạng giao dịch"
                        labelStyle={{ color: "#777" }}
                      >
                        {CONSTANT.STATUS[`${this.state.statusOrder}`]}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Ngày tạo giao dịch"
                        labelStyle={{ color: "#777" }}
                      >
                        {dataTransction && dataTransction.created_at
                          ? moment(dataTransction.created_at).format(
                            "DD/MM/YYYY HH:mm"
                          )
                          : "-"}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Ngày hoàn thành giao dịch"
                        labelStyle={{ color: "#777" }}
                      >
                        {dataTransction && dataTransction.date_completed
                          ? moment(dataTransction.date_completed).format(
                            "DD/MM/YYYY HH:mm"
                          )
                          : "-"}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Mã giao dịch "
                        labelStyle={{ color: "#777" }}
                      >
                        {this.state.transferId || "-"}
                      </Descriptions.Item>
                    </Descriptions>
                  }
                  {
                    detailOrder?.voucher && <Descriptions title={`Phiếu áp dụng chương trình: ${detailOrder.voucher.name}`} column={{ md: 2 }}></Descriptions>
                  }
                  <Row>
                    <Col
                      md={20}
                      style={{ lineHeight: "32px", fontWeight: 500 }}
                    >
                      Thành tiền dịch vụ
                    </Col>
                    <Col md={4} style={{ textAlign: 'right' }}>
                      {currencyFormat(detailOrder?.items[0].price)}{" "}
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      md={20}
                      style={{ lineHeight: "32px", fontWeight: 500 }}
                    >
                      Phí dịch vụ
                    </Col>
                    <Col md={4} style={{ textAlign: 'right' }}>
                      0
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md={20} style={{ lineHeight: "32px", fontWeight: 500 }}>VAT (10%)</Col>                    
                    <Col md={4} style={{ textAlign: 'right' }}>
                      {currencyFormat(detailOrder?.discount_tax)}
                    </Col>
                  </Row> */}
                  {
                    detailOrder?.overtime_fee > 0 &&
                    <Row>
                      <Col
                        md={20}
                        style={{ lineHeight: "32px", fontWeight: 500 }}
                      >
                        Phí ngoài giờ
                      </Col>
                      <Col md={4} style={{ textAlign: 'right' }}>
                        {currencyFormat(detailOrder?.overtime_fee)}
                      </Col>
                    </Row>
                  }

                  {detailOrder?.transpot_fee > 0 &&
                    <Row>
                      <Col
                        md={20}
                        style={{ lineHeight: "32px", fontWeight: 500 }}
                      >
                        Phí đi lại
                      </Col>
                      <Col md={4} style={{ textAlign: 'right' }}>
                        {currencyFormat(detailOrder?.transpot_fee)}
                      </Col>
                    </Row>
                  }
                  {
                    detailOrder.discount && detailOrder.discount?.value > 0 && <><Row>
                      <Col md={20} style={{ lineHeight: "32px", fontWeight: 500 }}>Giảm giá </Col>
                      <Col md={4} style={{ textAlign: 'right' }}>
                        {currencyFormat(detailOrder.discount.value_type === 'cash' ? detailOrder.discount.value : detailOrder.discount.value_type === 'percent' ? (detailOrder.subtotal + detailOrder.total_tax + detailOrder.transpot_fee + detailOrder.overtime_fee) * detailOrder.discount.value / 100 : 0)}
                      </Col>
                    </Row>
                      <Divider /></>
                  }


                  <Row>
                    <Col
                      md={20}
                      style={{ fontWeight: 700, lineHeight: "32px" }}
                    >
                      Tổng tiền dịch vụ (VNĐ)
                    </Col>
                    <Col
                      md={4}
                      style={{ fontWeight: 700, lineHeight: "32px", textAlign: 'right' }}
                    >
                      {detailOrder?.total && detailOrder?.total > 0
                        ? `${currencyFormat(detailOrder.total)}`
                        : "Miễn phí"}
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={24} sm={24} md={6}>
                <Card
                  title="Cập nhật trạng thái hóa đơn"
                  bordered={false}
                  size="small"
                >
                  <Select
                    placeholder="Chọn trạng thái"
                    onChange={this.onChangeSelect}
                    value={this.state.statusOrder}
                    style={{ width: "100%" }}
                  >
                    {this.state.arrStatus.map((item) => (
                      <Select.Option key={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                  <Row justify="end" style={{ margin: "20px 0" }}>
                    <Col>
                      <Button
                        type="primary"
                        loading={saving}
                        onClick={() => this.updateStatus()}
                        disabled={
                          detailOrder.status === this.state.statusOrder ||
                          saving
                        }
                      >
                        Cập nhật
                      </Button>
                    </Col>
                  </Row>
                </Card>

                <Card
                  title="Cập nhật thông tin giao dịch"
                  style={{ marginTop: 15 }}
                  bordered={false}
                  size="small"
                >
                  <label style={{ fontSize: 12, marginTop: -10 }}>
                    Dành cho trường hợp thanh toán chuyển khoản ngân hàng
                  </label>
                  <Input
                    placeholder="Nhập mã giao dịch"
                    value={this.state.transferId}
                    onChange={(e) =>
                      this.setState({ transferId: e.target.value })
                    }
                  />
                  <Row justify="end" style={{ margin: "20px 0" }}>
                    <Col>
                      <Button
                        type="primary"
                        loading={savingTransaction}
                        onClick={() => this.saveTransaction()}
                        disabled={
                          !(
                            this.state.transferId !== "" &&
                            this.state.transferId !== detailOrder.payment_id
                          ) || savingTransaction
                        }
                      >
                        Lưu mã giao dịch
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
              {
                <div className="content-print">
                  <div className="invoice-box" ref={this.setComponentRef}>
                    <table>
                      <tbody>
                        <tr>
                          <td style={{ verticalAlign: 'left' }}>
                            <img src="vivmedic.png" style={{ width: '100%', maxWidth: '120px' }} />
                          </td>
                          <td style={{ textAlign: 'left' }}>
                            <strong>CÔNG TY CỔ PHẦN CÔNG NGHỆ VIVMEDIC</strong>
                            <br></br>Địa chỉ:&nbsp;Số 24 Mỹ Thái 2A, Phường Tân Phú, Quận 7, Thành phố Hồ Chí Minh, Việt Nam
                            <br></br>Email:&nbsp;info@vivmedic.com - Điện thoại:&nbsp;(+84) 818 889 668
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="title">
                      <strong>PHIẾU HẸN</strong>
                    </div>
                    <table>
                      <tbody>
                        <tr>
                          <td colSpan={2} style={{ textAlign: 'center' }}>
                            <strong>Mã phiếu:&nbsp;</strong>
                            <strong>{"VIVMEDIC-S" + detailOrder.uid}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} style={{ textAlign: 'center' }}>
                            <strong>Ngày lập:</strong>&nbsp;{moment(new Date()).format("DD/MM/YYYY")}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <strong>Họ tên người đặt:</strong>&nbsp;{detailOrder?.contact?.fullname || "-"}
                          </td>
                        </tr>
                        <tr>
                          <td width="50%">
                            <strong>Email người đặt:</strong>&nbsp;{detailOrder?.contact?.email || "-"}
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            <strong>Số điện thoại:</strong>&nbsp;{"(+" +
                              detailOrder?.contact.dial_code + ")" +
                              detailOrder?.contact.phone}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <strong>Địa chỉ:</strong> &nbsp;{detailOrder?.contact?.address || "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="table-detail">
                      <tbody>
                        <tr className="item">
                          <td style={{ textAlign: 'left' }}>
                            <strong>Tên dịch vụ</strong>
                          </td>
                          <td style={{ textAlign: 'left' }}>
                            <strong>Số lượng</strong>
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            <strong>Đơn giá</strong>
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            <strong>Thành tiền</strong>
                          </td>
                        </tr>
                        <tr className="item">
                          <td style={{ textAlign: 'left' }}>{detailOrder?.items[0].service.name}</td>
                          <td style={{ textAlign: 'right', width: "100px" }}>1</td>
                          <td style={{ textAlign: 'right', width: "100px" }}>{currencyFormat(detailOrder?.items[0].price)}</td>
                          <td style={{ textAlign: 'right', width: "100px" }}>{currencyFormat(detailOrder?.items[0].price)}</td>
                        </tr>
                        <tr>
                          <td colSpan={1} rowSpan="5" style={{ verticalAlign: 'text-top' }}>
                            <strong>Thời gian hẹn khám: </strong>{
                              `${moment(detailOrder?.date).format('DD/MM/YYYY')} ${moment(detailOrder?.time).format('HH:mm')}`
                            }
                            <br></br>
                            {!detailOrder?.items[0]?.service?.is_online && (
                              <><strong>Địa điểm khám: </strong>{detailOrder?.work_place === 'at_agency' ? detailOrder?.place_name : 'Tại nhà khách hàng'}<br></br><strong>Địa chỉ: </strong>{detailOrder?.address_work} <br></br></>
                            )}
                            {detailOrder?.items[0]?.service?.is_online && (
                              <>
                                <strong>Hình thức khám: </strong>Tư vấn từ xa - Link khám từ xa:&nbsp; {`${CONSTANT.BASE_MEET}/${detailOrder?.verify_code}`}<br></br>
                              </>
                            )}
                            {detailOrder?.total > 0 &&
                              <>
                                <strong>Hình thức thanh toán: </strong>{CONSTANT.PAY_METHOD[detailOrder.payment_method] || "-"}<br></br>
                                <strong>Mã giao dịch: </strong>{this.state.transferId || "-"} <br></br>
                                <strong>Ngày hoàn thành giao dịch: </strong>{dataTransction && dataTransction.date_completed
                                  ? moment(dataTransction.date_completed).format("DD/MM/YYYY HH:mm")
                                  : "-"}
                              </>
                            }
                            {
                              detailOrder?.voucher && <><br></br><strong>Phiếu áp dụng chương trình:</strong> {detailOrder?.voucher?.name}</>
                            }
                          </td>
                          <td colSpan={2} style={{ textAlign: 'right' }}><strong>Tiền khám</strong></td>
                          <td style={{ textAlign: 'right' }}>{currencyFormat(detailOrder?.subtotal)}</td>
                        </tr>
                        <tr>
                          <td colSpan={2} style={{ textAlign: 'right' }}><strong>Phí dịch vụ</strong></td>
                          <td style={{ textAlign: 'right' }}>0</td>
                        </tr>
                        {/* <tr>
                          <td colSpan={2} style={{ textAlign: 'right' }}><strong>VAT (10%)</strong></td>
                          <td style={{ textAlign: 'right' }}>{currencyFormat(detailOrder?.total_tax)}</td>
                        </tr> */}
                        {
                          detailOrder.transpot_fee > 0 &&
                          <tr>
                            <td colSpan={2} style={{ textAlign: 'right' }}><strong>Phí đi lại</strong></td>
                            <td style={{ textAlign: 'right' }}>{currencyFormat(detailOrder?.transpot_fee)}</td>
                          </tr>
                        }
                        {
                          detailOrder.overtime_fee > 0 &&
                          <tr>
                            <td colSpan={2} style={{ textAlign: 'right' }}><strong>Phí ngoài giờ</strong></td>
                            <td style={{ textAlign: 'right' }}>{currencyFormat(detailOrder?.overtime_fee)}</td>
                          </tr>
                        }
                        {
                          detailOrder?.discount && detailOrder.discount?.value > 0 &&
                          <tr>
                            <td colSpan={2} style={{ textAlign: 'right' }}><strong>Giảm giá</strong></td>
                            <td style={{ textAlign: 'right', borderBottom: "1px solid #ccc" }}>- {currencyFormat(detailOrder.discount.value_type === 'cash' ? detailOrder.discount.value : detailOrder.discount.value_type === 'percent' ? (detailOrder.subtotal + detailOrder.total_tax + detailOrder.transpot_fee + detailOrder.overtime_fee) * detailOrder.discount.value / 100 : 0)}</td>
                          </tr>
                        }
                        <tr>
                          <td colSpan={2} style={{ textAlign: 'right' }}>
                            <strong>Tổng cộng (VNĐ)</strong>
                          </td>
                          <td style={{ textAlign: 'right' }}>{currencyFormat(detailOrder.total)}</td>
                        </tr>
                      </tbody>
                    </table>
                    <table>
                      <tbody>
                        <tr>
                          <td style={{ textAlign: 'left' }} width="70%"></td>
                          <td style={{ textAlign: 'center' }} width="30%"><strong>Người lập phiếu</strong></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              }
            </Row>
          ) : null}
        </Layout.Content>
      </div>
    );
  }
}
