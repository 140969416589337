import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Table, Button, Divider, Row, Col, Avatar, Space, Popconfirm,
    message, Tag
} from 'antd';
import { HomeOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { axiosInstance } from '../../../config/api';
import CONSTANT from '../../../config/constant';
const TAGS = {
    'completed': { text: 'Completed', color: 'green' },
    'processing': { text: 'Processing', color: 'red' },
    'failed': { text: 'Failed', color: 'red' },
    'abandoned': { text: 'Abandoned', color: 'orange' },
    'unpaid': { text: 'Unpaid', color: 'red' },
    'paid': { text: 'Paid', color: 'blue' },
    'wait-paid': { text: 'Wait Paid', color: 'orange' },
    'fulfill': { text: 'Fulfill', color: 'green' },
    'on-hold': { text: 'On hold', color: '' },
    'refunded': { text: 'Refunded', color: '' },
    'cancelled': { text: 'Cancelled', color: '' },
    'pending': { text: 'Pending', color: '' },
    'change': { text: 'Change', color: 'orange' },
    'change_date': { text: 'Change', color: 'orange' },
    'change_room': { text: 'Change', color: 'orange' },
    'trash': { text: 'Trash', color: '' },
    '-': { text: 'Trash', color: 'red' }
};
const getRandomuserParams = params => ({
    perPage: params.pagination.pageSize,
    page: params.pagination.current,
    sort: params.sort ? params.sort : `{"created_at": -1}`
});

export default class ListBooking extends React.PureComponent {
    constructor(props) {
        super(props);
        // define column table
        this.columns = [
            {
                title: 'Email',
                dataIndex: 'contact',
                render: contact => contact.email
            },
            { title: 'Tổng cộng', dataIndex: 'total' },
            {
                title: 'Trạng thái', type: 'tag', dataIndex: 'status',
                render: status =><Tag color={TAGS[status].color}>{TAGS[status].text}</Tag>                
            },
            {
                title: 'Ngày tạo', dataIndex: 'created_at', sorter: true, width: '170px',
                render: created_at => `${moment(new Date(created_at)).format(CONSTANT.DATE_FORMAT)}`
            },
            {
                title: 'Ngày thanh toán', dataIndex: 'date_paymented', sorter: true, width: '170px',
                render: date_paymented => date_paymented ? `${moment(new Date(date_paymented)).format(CONSTANT.DATE_FORMAT)}` : ""
            },
            {
                title: 'Hành động', width: '300px',
                render: (text, record) => (
                    <Space size="middle">
                        <Link to={`/hotel/booking/${record._id}`}><EditOutlined /> Chi tiết</Link>                        
                    </Space>
                )
            },
        ];
        this.state = {
            selectedRowKeys: [],
            loading: false,
            dataOrders: [],
            pagination: {
                current: 1,
                pageSize: 10
            }
        };
    }
    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    }
    
    async componentDidMount() {
        const { pagination } = this.state;
        this.fetch({ pagination });
    }

    fetch = async (params = {}) => {
        debugger;
        this.setState({ loading: true });
        let agencyId = localStorage.getItem('tatinta-agency');
        let query = new URLSearchParams(getRandomuserParams(params)).toString();
        if (agencyId) {
            this.setState({ loading: true });
            await axiosInstance.get(`${CONSTANT.API.HOTEL.ORDER}?${query}&agency=${agencyId}`)
                .then(res => {
                    debugger;
                    if (res && res.data && res.data.code === 200) {
                        const {docs, total} = res.data.data;
                        this.setState({
                            loading: false,
                            dataOrders: docs,
                            pagination: {
                                ...params.pagination,
                                total
                            }
                        })
                    }
                })
        }
        else {
            this.setState({loading: false});
            message.warning('Không có hóa đơn nào !').then(res => this.props.history.goBack());
        }
    }

    handleTableChange = (pagination, filters, sorter) => {
        let options = { pagination };
        if (Object.keys(sorter).length > 0)
            options.sort = `{"${sorter.field}": ${sorter.order === 'ascend' ? 1 : -1}}`;
        this.fetch(options);
    }
    render() {
        const { loading, selectedRowKeys, dataOrders ,pagination} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item >
                        <Link to="/"><HomeOutlined /></Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/hotel">Khách sạn</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Danh sách hóa đơn
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Layout.Content className="layout-content">
                    <Table
                        rowSelection={rowSelection}
                        columns={this.columns}
                        dataSource={dataOrders}
                        loading={loading}
                        pagination={pagination}
                        rowKey={record => record._id}
                        onChange={this.handleTableChange}
                    />
                </Layout.Content>
            </div>
        )
    }
}
