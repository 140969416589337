import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Table, Button, Divider, Form, Input, Row, Col, Space, Popconfirm,
    Select, message, Tag
} from 'antd';
import {
    HomeOutlined, PlusOutlined, EditOutlined, DeleteOutlined,
    SearchOutlined, RedoOutlined
} from '@ant-design/icons';

import * as Service from '../../../config/service';
import CONSTANT from '../../../config/constant';
import * as COMMON from '../../../config/common';


const getRandomuserParams = params => ({
    perPage: params.pagination.pageSize,
    page: params.pagination.current,
    sort: params.sort ? params.sort : `{"created_at": -1}`,
    ...params.options
});

const ListArea = (props) => {
    const [form] = Form.useForm();
    // define column table
    const columns = [
        {
            title: 'Ảnh', dataIndex: 'image', width: '100px',
            render: image => image ? <img src={`${COMMON.getImage(image, 'location')}`} alt="image" style={{ width: 70 }} /> : ''
        },
        { title: 'Tên', dataIndex: 'name' },
        {
            title: 'Trạng thái', dataIndex: 'is_active', width: '120px',
            render: is_active => is_active ? <Tag color="green">Công khai</Tag> : <Tag color="red">Bản nháp</Tag>
        },
        {
            title: 'Hành động', width: '180px',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/location/area/edit/${record._id}`}><EditOutlined /> Sửa</Link>
                    <Divider type="vertical" />
                    <Popconfirm title="Bạn có chắc xóa trang này?" onConfirm={() => handleDelete(record._id)}>
                        <a href="#"><DeleteOutlined /> Xóa</a>
                    </Popconfirm>
                </Space>
            )
        }
    ];
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

    const fetch = async (params = {}) => {
        setLoading(true);
        await Service.get(`${CONSTANT.API.DESTINATION.AREA}`, getRandomuserParams(params))
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    const { docs, total } = res.data.data;
                    setData(docs);
                    setLoading(false);
                    setPagination({
                        ...params.pagination,
                        total
                    })

                }

            })
    }

    const onSelectChange = selectedRowKeys => {
        setSelectedRowKeys(selectedRowKeys)
    }
    const handleDelete = async (key) => {
        if (key) {
            await Service.remove(`${CONSTANT.API.DESTINATION.AREA}/${key}`)
                .then((res) => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Xóa thành công!');
                        const dataSource = [...data];
                        setData(dataSource.filter((item) => item._id !== key))
                    }
                    else {
                        message.error('Xóa không thành công');
                    }
                })
                .catch(err => message.error('Xóa không thành công'))
        }
    }

    useEffect(() => {
        fetch({ pagination })
    }, []);

    const handleTableChange = (pagination, filters, sorter) => {

        fetch({
            sort: `{"${sorter.field}": ${sorter.order === 'ascend' ? 1 : -1}}`,
            pagination
        });
    }


    // on search
    const onSearch = async (values) => {
        let options = {};
        if (values.field === 'name')
            options.name = values.q;
        if (values.field === 'uid')
            options.uid = values.q;

        fetch({
            pagination,
            options
        });
    }

    const reload = () => {
        fetch({ pagination });
        form.resetFields();
    }
    
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Khu vực
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {/* Begin form search */}
                <Form
                    layout="inline"
                    onFinish={onSearch}
                    style={{ marginBottom: 20 }}
                    initialValues={{
                        field: 'name'
                    }}
                    form={form}
                >
                    <Form.Item name="q" required rules={[
                        { required: true, message: 'Vui lòng nhập tên hoặc id' }
                    ]}>
                        <Input
                            type="text"
                            placeholder="Nhập tên hoặc Id"
                            style={{ width: 500 }}
                        />
                    </Form.Item>
                    <Form.Item name="field">
                        <Select
                            style={{ width: 150 }}
                        >
                            <Select.Option value="name">Tên</Select.Option>
                            <Select.Option value="uid">Id</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
                            Tìm
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button icon={<RedoOutlined />} onClick={reload}>
                            Đặt lại
                        </Button>
                    </Form.Item>
                </Form>
                {/* End form search */}
                <Divider />
                <Row justify="end" style={{ marginBottom: 20 }}>
                    <Col>
                        <Button type="primary" icon={<PlusOutlined />} onClick={() => props.history.push('/location/area/add')} >
                            Thêm mới
                        </Button>
                    </Col>
                </Row>

                <Table
                    //rowSelection={rowSelection}
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    pagination={pagination}
                    rowKey={record => record._id}
                    onChange={handleTableChange}
                />
            </Layout.Content>
        </div>
    )
}

export default ListArea;
