import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Form, Input, Select, message, Button, Tabs, Upload
} from 'antd';
import { HomeOutlined, SaveOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

import CONSTANT from '../../../config/constant';
import * as Service from '../../../config/service';

const status = [
    { value: 'published', label: 'Công khai' },
    { value: 'draft', label: 'Bản nháp' },
];

const ServiceAddCategory = (props) => {

    const [form] = Form.useForm();

    const [saving, setSaving] = useState(false);
    const [loadingAvatar, setLoadingAvatar] = useState(false);
    const [avatar, setAvatar] = useState();
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        let mounted = true;

        //  load danh muc
        const loadCategories = async () => {
            return await Service.get(`${CONSTANT.API.SERVICE.CATEGORY_TREE}`, { perPage: -1 })
                .then(res => {
                    if (res?.data.code === 200)
                        return res.data.data;
                })
                .catch(error => {
                    message.warning('Không lấy được thông tin danh mục!');
                });
        }

        loadCategories().then(data => {
            if (mounted) {
                setCategories(data);
            }
        });

        return () => { mounted = false; }

    }, []);

    // on change upload image
    const onChangeImages = async ({ file }) => {
        if (file.status === 'uploading')
            setLoadingAvatar(true);
        if (file.status === 'done') {
            setLoadingAvatar(false);
            setAvatar(file?.response?.data.filename);
        }
    }

    // xử lý lưu image
    const saveImage = async () => {
        if (avatar) {
            return await Service.post(`${CONSTANT.API.EXTRA.SAVE_FILE}`, {
                filename: avatar,
                type: 'service_medical_category'
            })
                .then(res => {
                    if (res?.data.code === 200) {
                        return res.data.data.url;
                    }
                })
        }
        return null;
    }

    // submit
    const onFinish = async (values) => {
        if (values.parent === 'root')
            delete values.parent;

        let image = await saveImage();
        values.avatar = image;
        values.is_active = values.status === 'published' ? true : false;
        // Create
        values.translations = {
            en: {
                name: values.name_en,
                info: values.info_en
            }
        }
        setSaving(true);
        await Service.post(`${CONSTANT.API.SERVICE.CATEGORY}`, values)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    message.success('Lưu thông tin thành công!', 1).then(() => props.history.push('/service/category'))
                }
                else {
                    message.error('Lưu thông tin thất bại! Vui lòng thử lại sau.');
                    setSaving(false);
                }
            }).catch(err => {
                message.error('Lưu thông tin thất bại! Vui lòng thử lại sau.')
                setSaving(false);
            });

    };

    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/page">Danh mục</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Thêm mới
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={{
                        status: "draft"
                    }}
                >
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={18}>
                            <Tabs defaultActiveKey="vi" type="card" size="large">
                                <Tabs.TabPane tab="Tiếng Việt" key="vi" forceRender={true}>
                                    <Form.Item
                                        label="Tên" name="name"
                                        required tooltip="Trường bắt buộc nhập"
                                        rules={[
                                            { required: true, message: 'Vui lòng nhập tên!' },
                                        ]}
                                    >
                                        <Input placeholder="Tên" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Mô tả ngắn gọn" name="info"
                                    >
                                        <Input.TextArea placeholder="Nhập mô tả ngắn gọn" />
                                    </Form.Item>
                                </Tabs.TabPane>
                                <Tabs.TabPane forceRender={true} tab="Tiếng Anh" key="en">
                                    <Form.Item
                                        label="Tên" name="name_en"
                                        required tooltip="Trường bắt buộc nhập"
                                        rules={[
                                            { required: true, message: 'Vui lòng nhập tên!' },
                                        ]}
                                    >
                                        <Input placeholder="Tên" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Mô tả ngắn gọn" name="info_en"
                                    >
                                        <Input.TextArea placeholder="Nhập mô tả ngắn gọn" />
                                    </Form.Item>
                                </Tabs.TabPane>
                            </Tabs>
                            <Form.Item
                                label="Cấp danh mục" name="level"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập cấp danh mục!' },
                                ]}
                            >
                                <Input placeholder="Nhập cấp danh mục" />
                            </Form.Item>
                            <Form.Item
                                label="Thứ tự" name="order"
                            >
                                <Input placeholder="Thứ tự" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={6}>

                            <Form.Item
                                label="Hình ảnh"
                            >
                                <ImgCrop
                                    rotate
                                    modalTitle="Sửa ảnh"
                                    modalOk="Đồng ý"
                                    aspect={500 / 500}
                                    quality={0.8}
                                    modalWidth={800}
                                >
                                    <Upload
                                        action={`${CONSTANT.API.EXTRA.UPLOAD_IMAGE}`}
                                        data={{ type: 'area' }}
                                        listType="picture-card"
                                        showUploadList={false}
                                        onChange={onChangeImages}
                                    >
                                        {avatar ? <img src={`${CONSTANT.STATIC_URL_TMP}${avatar}`} alt="avatar" style={{ width: '100%' }} /> : (
                                            <div>
                                                {loadingAvatar ? <LoadingOutlined /> : <PlusOutlined />}
                                                <div style={{ marginTop: 8 }}>Tải ảnh</div>
                                            </div>
                                        )}
                                    </Upload>
                                </ImgCrop>
                                {/*  */}
                            </Form.Item>

                            <Form.Item
                                label="Danh mục cha"
                                name="parent"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn danh mục cha!' },
                                ]}
                            >
                                <Select
                                    placeholder="Chọn danh mục cha"
                                    style={{ width: '100%' }}

                                >
                                    <Select.Option key='root'>Danh mục cha</Select.Option>
                                    {
                                        categories.map(lv1 =>
                                            <React.Fragment key={lv1?.uid}>
                                                <Select.Option key={lv1._id}>{`♦ ${lv1.name}`}</Select.Option>
                                                {
                                                    lv1.childs.length > 0 &&
                                                    lv1.childs.map(lv2 => (
                                                        <React.Fragment key={lv2?.uid}>
                                                            <Select.Option key={lv2._id}>{`   └ ${lv2.name}`}</Select.Option>
                                                            {
                                                                lv2.childs.length > 0 &&
                                                                lv2.childs.map(lv3 => (
                                                                    <Select.Option key={lv3._id}>{`        └ ${lv3.name}`}</Select.Option>
                                                                ))
                                                            }
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </React.Fragment>
                                        )

                                    }
                                </Select>

                            </Form.Item>
                            <Form.Item
                                label="Trạng thái"
                                name="status"
                            >
                                <Select
                                    placeholder="Chọn trạng thái"
                                    style={{ width: '100%' }}
                                >
                                    {
                                        status.map(item => <Select.Option key={item.value}>{item.label}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>

                        </Col>
                    </Row>
                    <div className="footer-toolbar">
                        <div className="right">
                            <Button
                                type="primary" icon={<SaveOutlined />} htmlType="submit"
                                loading={saving}
                            >
                                {saving ? 'Đang lưu' : 'Lưu thông tin'}
                            </Button>
                        </div>

                    </div>
                </Form>

            </Layout.Content>

        </div>
    )

}

export default ServiceAddCategory;
