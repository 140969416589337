import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Table, Button, Divider, Form, Row, Col, Space, Popconfirm, message
} from 'antd';
import {
    HomeOutlined, PlusOutlined, EditOutlined, DeleteOutlined
} from '@ant-design/icons';

import * as Service from '../../../config/service';
import CONSTANT from '../../../config/constant';


const getRandomuserParams = params => ({
    perPage: params.pagination.pageSize,
    sort: params.sort ? params.sort : `{"created_at": -1}`,
    ...params.options
});

const ShopListCategory = (props) => {
    const [form] = Form.useForm();
    // define column table
    const columns = [
        { title: 'Tên', dataIndex: 'name'},
        {
            title: 'Hành động', width: '180px',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/shop/category/edit/${record._id}`}><EditOutlined /> Sửa</Link>
                    <Divider type="vertical" />
                    <Popconfirm title="Bạn có chắc xóa?" onConfirm={() => handleDelete(record._id)}>
                        <a href="#"><DeleteOutlined /> Xóa</a>
                    </Popconfirm>
                </Space>
            )
        }
    ];
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pagination, _] = useState({ current: 1, pageSize: -1 });

    const fetch = async (params = {}) => {
        setLoading(true);
        await Service.get(`${CONSTANT.API.SHOP.CATEGORY_TREE}`, getRandomuserParams(params))
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    if(res?.data?.data.length > 0){
                        for(let lv1 of res.data.data){
                            if(lv1.childs.length > 0){
                                lv1.children = lv1.childs;
                                for(let lv2 of lv1.children){
                                    if(lv2.childs.length > 0){
                                        lv2.children = lv2.childs
                                    }   
                                }
                            }
                        }
                    }
                    setData(res.data.data);
                    setLoading(false);
                }

            })
    }

   
    const handleDelete = async (key) => {
        if (key) {
            await Service.remove(`${CONSTANT.API.SHOP.CATEGORY}/${key}`)
                .then((res) => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Xóa thành công!');
                        fetch({pagination});
                    }
                    else {
                        message.error('Xóa không thành công');
                    }
                })
                .catch(err => message.error('Xóa không thành công'))
        }
    }

    useEffect(() => {
        fetch({ pagination })
    }, []);

    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Danh mục
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                
                <Row justify="end" style={{ marginBottom: 20 }}>
                    <Col>
                        <Button type="primary" icon={<PlusOutlined />} onClick={() => props.history.push('/shop/category/add')} >
                            Thêm mới
                        </Button>
                    </Col>
                </Row>

                <Table
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    pagination={false}
                    rowKey={record => record.uid}
                    expandable={{
                        defaultExpandAllRows: true
                    }}
                    key="list"
                />
            </Layout.Content>
        </div>
    )
}

export default ShopListCategory;
