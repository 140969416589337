import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Table, Button, Divider, Form, Input, Row, Col, Space, Popconfirm, Typography, Avatar,
    Select, message, Tag
} from 'antd';
import {
    HomeOutlined, PlusOutlined, EditOutlined, DeleteOutlined,
    SearchOutlined, RedoOutlined
} from '@ant-design/icons';
import moment from 'moment';

import * as Service from '../../../config/service';
import CONSTANT from '../../../config/constant';


const getRandomuserParams = params => ({
    perPage: params.pagination.pageSize,
    page: params.pagination.current,
    sort: params.sort ? params.sort : `{"created_at": -1}`,
    ...params.options
});

const ShopListProduct = (props) => {
    const [form] = Form.useForm();
    // define column table
    const columns = [
        {
            title: 'Ảnh', dataIndex: 'images',
            render: images => images && images.length > 0 && <Avatar size={48} shape="square" src={`${CONSTANT.STATIC_URL}${images[0].filename}`} />,
            width: '50px'
        },
        { title: 'Tên', dataIndex: 'name' },
        {
            title: 'Danh mục', dataIndex: 'categories',
            render: categories => categories[0].name
        },
        {
            title: 'Trạng thái', dataIndex: 'status', width: '120px',
            render: status => status === 'publish' ? <Tag color="green">Công khai</Tag> : <Tag color="red">Bản nháp</Tag>
        },
        {
            title: 'Ngày tạo', dataIndex: 'created_at', sorter: true, width: '170px',
            render: created_at => `${moment(new Date(created_at)).format(CONSTANT.DATE_FORMAT)}`
        },
        {
            title: 'Hành động', width: '180px',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/shop/product/action/${record._id}`}><EditOutlined /> Sửa</Link>
                    <Divider type="vertical" />
                    <Popconfirm title="Bạn có chắc xóa?" onConfirm={() => handleDelete(record._id)}>
                        <a href="#"><DeleteOutlined /> Xóa</a>
                    </Popconfirm>
                </Space>
            )
        }
    ];
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

    const fetch = async (params = {}) => {
        setLoading(true);
        await Service.get(`${CONSTANT.API.SHOP.PRODUCT}`, getRandomuserParams(params))
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    const { docs, total } = res.data.data;
                    setData(docs);
                    setLoading(false);
                    setPagination({
                        ...params.pagination,
                        total
                    })

                }

            })
    }

    const onSelectChange = selectedRowKeys => {
        setSelectedRowKeys(selectedRowKeys)
    }
    const handleDelete = async (key) => {
        if (key) {
            await Service.remove(`${CONSTANT.API.SHOP.PRODUCT}/${key}`)
                .then((res) => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Xóa thành công!');
                        const dataSource = [...data];
                        setData(dataSource.filter((item) => item._id !== key))
                    }
                    else {
                        message.error('Xóa không thành công');
                    }
                })
                .catch(err => message.error('Xóa không thành công'))
        }
    }

    useEffect(() => {
        fetch({ pagination })
    }, []);

    const handleTableChange = (pagination, filters, sorter) => {
        let options = {};
        if (Object.keys(sorter).length > 0)
            options.sort = `{"${sorter.field}": ${sorter.order === 'ascend' ? 1 : -1}}`;
        /*if(this.state.keySearch !== ''){
            options.name = this.state.keySearch;
        }*/
        fetch({ pagination, options });
    }


    // on search
    const onSearch = async (values) => {
        let options = {};
        if (values.field === 'name')
            options.name = values.q;
        if (values.field === 'uid')
            options.uid = values.q;

        fetch({
            pagination,
            options
        });
    }

    const reload = () => {
        fetch({ pagination });
        form.resetFields();
    }
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    // xử lý xóa các dòng đã chọn
    const removeSelected = async () => {
        if (selectedRowKeys.length > 0) {
            let deleteCount = 0;
            for (let [index, item] of selectedRowKeys.entries()) {
                await Service.remove(`${CONSTANT.API.SHOP.PRODUCT}/${item}`)
                    .then(async (res) => {
                        if (res && res.data && res.data.code === 200) {
                            deleteCount += 1;
                        }
                    });
                if (index === selectedRowKeys.length - 1) {
                    message.info(`Đã xóa thành công ${deleteCount} dòng`, 2).then(() => {
                        fetch({ pagination });
                        setSelectedRowKeys([]);
                    });

                }
            }
        }
    }

    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Mua sắm
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Sản phẩm
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {/* Begin form search */}
                <Form
                    layout="inline"
                    onFinish={onSearch}
                    style={{ marginBottom: 20 }}
                    initialValues={{
                        field: 'name'
                    }}
                    form={form}
                >
                    <Form.Item name="q" required rules={[
                        { required: true, message: 'Vui lòng nhập tên hoặc id' }
                    ]}>
                        <Input
                            type="text"
                            placeholder="Nhập tên hoặc Id"
                            style={{ width: 500 }}
                        />
                    </Form.Item>
                    <Form.Item name="field">
                        <Select
                            style={{ width: 150 }}
                        >
                            <Select.Option value="name">Tên</Select.Option>
                            <Select.Option value="uid">Id</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
                            Tìm
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button icon={<RedoOutlined />} onClick={reload}>
                            Đặt lại
                        </Button>
                    </Form.Item>
                </Form>
                {/* End form search */}
                <Divider />
                <Row justify="space-between" style={{ marginBottom: 20 }} gutter={16}>
                    <Col>
                        <Popconfirm title="Bạn có chắc xóa?" onConfirm={() => removeSelected()}>
                            <Button disabled={selectedRowKeys?.length === 0} type="primary" icon={<DeleteOutlined />} >
                                Xóa
                            </Button>
                        </Popconfirm>
                        {
                            selectedRowKeys.length > 0 &&
                            <Typography.Text type="secondary" style={{ marginLeft: 10 }}>Đã chọn {selectedRowKeys.length} dòng </Typography.Text>
                        }
                    </Col>
                    <Col>
                        <Button type="primary" icon={<PlusOutlined />} onClick={() => props.history.push('/shop/product/action')} >
                            Thêm mới
                        </Button>
                    </Col>
                </Row>

                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    pagination={pagination}
                    rowKey={record => record._id}
                    onChange={handleTableChange}
                />
            </Layout.Content>
        </div>
    )
}

export default ShopListProduct;
