import React, { useState, useCallback, useEffect } from 'react';
import {
    Form, Modal, Input, message, Select, Upload, InputNumber, Tabs
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

import CONSTANT from '../../../config/constant';
import * as Service from '../../../config/service'
import * as COMMON from '../../../config/common';

const status = [
    { value: 'published', label: 'Công khai' },
    { value: 'draft', label: 'Bản nháp' },
];

const ModalAction = (props) => {
    const actionTitle = props?.data ? "Sửa chuyên khoa" : "Thêm chuyên khoa";
    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [loadingAvatar, setLoadingAvatar] = useState(false);
    const [avatar, setAvatar] = useState();
    const [image, setImage] = useState();

    // on change upload image
    const onChangeImages = async ({ file }) => {
        if (file.status === 'uploading')
            setLoadingAvatar(true);
        if (file.status === 'done') {
            setLoadingAvatar(false);
            setAvatar(file?.response?.data.filename);
        }
    }

    // xử lý lưu image
    const saveImage = async () => {
        if (avatar) {
            return await Service.post(`${CONSTANT.API.EXTRA.SAVE_FILE}`, {
                filename: avatar,
                type: 'hospital'
            })
                .then(res => {
                    if (res?.data.code === 200) {
                        return res.data.data.url;
                    }
                })
        }
        return null;
    }

    // Hủy
    const handleCancel = useCallback(() => {
        form.resetFields();
        props.cancel();
        setConfirmLoading(false);
        setImage(undefined);
        setAvatar(undefined);
    }, [form]);

    // Load thong tin cho form
    const loadForm = () => {
        form.setFieldsValue({
            name: props.data?.name || '',
            description: props.data?.description || '',
            name_en: props.data?.translations?.en.name || '',
            description_en: props.data?.translations?.en.description || '',
            status: props.data?.is_active === true ? 'published' : 'draft'
        });
        setImage(props.data?.avatar);
    }

    // Xử lý lưu
    const onSubmit = useCallback(async (values) => {
        setConfirmLoading(true);
        values.is_active = values.status === 'published' ? true : false;
        values.translations = {
            en: {
                name: values.name_en,
                description: values.description_en
            }
        }
        // Nếu thêm mới
        if (!props.data?._id) {
            let image = await saveImage();
            values.avatar = image;
            await Service.post(`${CONSTANT.API.HOSPITAL.SPECIALTY}`, values)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Lưu thông tin thành công!', 1);
                    }
                    else {
                        message.error('Lưu thông tin thất bại! Vui lòng thử lại sau.');
                    }
                }).catch(err => {
                    message.error('Lưu thông tin thất bại! Vui lòng thử lại sau.')
                });
        }
        else {
            if (avatar) {
                let image = await saveImage();
                values.avatar = image;
            }
            await Service.patch(`${CONSTANT.API.HOSPITAL.SPECIALTY}/${props.data._id}`, values)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Lưu thông tin thành công!', 1);
                    }
                    else {
                        message.error('Lưu thông tin thất bại! Vui lòng thử lại sau.');
                    }
                }).catch(err => {
                    message.error('Lưu thông tin thất bại! Vui lòng thử lại sau.');
                });
        }

        setTimeout(() => {
            setConfirmLoading(false);
            // đóng modal
            handleCancel();
            // load lai danh sách
            props.reload()
        }, 2000);
    }, [props, avatar]);

    useEffect(() => {
        loadForm();
    }, [props.data]);


    return (
        <Modal title={actionTitle}
            visible={props.isModalVisible}
            onOk={form.submit}
            onCancel={handleCancel}
            confirmLoading={confirmLoading}
            cancelText="Hủy"
            okText="Lưu thông tin"
            forceRender
            destroyOnClose={true}
        >
            <Form
                name="basic"
                layout="vertical"
                onFinish={onSubmit}
                onFinishFailed={() => { }}
                form={form}
                initialValues={{
                    status: "draft", order: 1
                }}
            >
                <Form.Item
                    label="Hình ảnh"
                >
                    <ImgCrop
                        rotate
                        modalTitle="Sửa ảnh"
                        modalOk="Đồng ý"
                        aspect={200 / 200}
                        quality={0.8}
                        modalWidth={800}
                    >
                        <Upload
                            action={`${CONSTANT.API.EXTRA.UPLOAD_IMAGE}`}
                            data={{ type: 'area' }}
                            listType="picture-card"
                            showUploadList={false}
                            onChange={onChangeImages}
                        >

                            {
                                typeof avatar !== 'undefined'
                                    ? <img src={`${CONSTANT.STATIC_URL_TMP}${avatar}`} alt="avatar" style={{ width: '100%' }} />
                                    : <>
                                        {
                                            typeof image !== 'undefined' ? <img src={`${COMMON.getImage(image, 'location')}`} alt="avatar" style={{ width: '100%' }} />
                                                :
                                                <div>
                                                    {loadingAvatar ? <LoadingOutlined /> : <PlusOutlined />}
                                                    <div style={{ marginTop: 8 }}>Tải ảnh</div>
                                                </div>
                                        }

                                    </>
                            }

                        </Upload>
                    </ImgCrop>

                </Form.Item>
                <Tabs defaultActiveKey="vi" type="card" size="large">
                    <Tabs.TabPane tab="Tiếng Việt" key="vi" forceRender={true}>
                        <Form.Item
                            label="Tên" name="name"
                            required tooltip="Trường bắt buộc nhập"
                            rules={[
                                { required: true, message: 'Vui lòng nhập tên!' },
                            ]}
                        >
                            <Input placeholder="Tên" />
                        </Form.Item>
                        <Form.Item
                            label="Mô tả ngắn gọn" name="description"
                        >
                            <Input.TextArea placeholder="Nhập mô tả ngắn gọn" />
                        </Form.Item>
                    </Tabs.TabPane>
                    <Tabs.TabPane forceRender={true} tab="Tiếng Anh" key="en">
                        <Form.Item
                            label="Tên" name="name_en"
                            required tooltip="Trường bắt buộc nhập"
                            rules={[
                                { required: true, message: 'Vui lòng nhập tên!' },
                            ]}
                        >
                            <Input placeholder="Tên" />
                        </Form.Item>
                        <Form.Item
                            label="Mô tả ngắn gọn" name="description_en"
                        >
                            <Input.TextArea placeholder="Nhập mô tả ngắn gọn" />
                        </Form.Item>
                    </Tabs.TabPane>
                </Tabs>
                <Form.Item
                    label="Thứ tự sắp xếp"
                    name="order"
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    label="Trạng thái"
                    name="status"
                >
                    <Select
                        placeholder="Chọn trạng thái"
                        style={{ width: '100%' }}
                    >
                        {
                            status.map(item => <Select.Option key={item.value}>{item.label}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default ModalAction;