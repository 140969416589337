import React, { useState, useCallback } from 'react';
import {
    Form, Input, message, Modal
} from 'antd';

import CONSTANT from '../../../config/constant';
import { axiosInstance } from '../../../config/api';



const ModalChagePass = ({isModalVisible, handleCancel, role, email}) => {

    const layoutForm = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);

    const onCancel = useCallback(() => {
        form.resetFields();
        setSaving(false);
        handleCancel();
    }, [form]);

    const onChangePass = useCallback(async (values) => {
        if(typeof role !== 'undefined' && typeof email !== 'undefined'){
            values.role = role;
            values.email = email;
            setSaving(true);
            await axiosInstance.post(`${CONSTANT.API.AUTH.CHANGE_PASS}`,values,{
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            })
            .then(res => {
                console.log(res.data.code)
                if(res && res.data && res.data.code === 200){
                    message.success('Đổi mật khẩu thành công!', 1).then(res => {
                        form.resetFields();
                        handleCancel();
                    });
                }
                else if (res && res.data && res.data.code === 400){
                    message.warning('Mật khẩu cũ không đúng!');
                    setSaving(false);
                }
                else{
                    message.error('Đổi mật khẩu thất bại! Vui lòng thử lại!');
                    setSaving(false);
                }
            })
        }
        
    }, [form]);


    return (
        <Modal title="Đổi mật khẩu"
            visible={isModalVisible}
            onOk={form.submit} onCancel={onCancel}
            okButtonProps={{
                loading: saving
            }}
            cancelText="Hủy"
            okText="Đổi mật khẩu"
        >
            <Form {...layoutForm} name="add-age" form={form}
                onFinish={onChangePass}
                initialValues={
                    { max: 0 }
                }
            >
                <Form.Item
                    label="Mật khẩu" name="password"
                    required tooltip="Trường bắt buộc nhập"
                    rules={[
                        { required: true, message: 'Vui lòng nhập mật khẩu!' },
                    ]}
                >
                    <Input.Password placeholder="Nhập mật khẩu" />
                </Form.Item>
                <Form.Item
                    label="Mật khẩu mới" name="newpassword"
                    required tooltip="Trường bắt buộc nhập"
                    rules={[
                        { required: true, message: 'Vui lòng nhập mật khẩu mới!' },
                    ]}
                >
                    <Input.Password placeholder="Nhập mật khẩu mới" />
                </Form.Item>
                <Form.Item
                    label="Nhập lại mật khẩu mới" name="re_password"
                    dependencies={['newpassword']}
                    hasFeedback
                    required tooltip="Trường bắt buộc nhập"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng nhập mật khẩu nhập lại!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newpassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Mật khẩu nhập lại không đúng!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder="Nhập lại mật khẩu" />
                </Form.Item>
            </Form>
        </Modal>
    )

}

export default ModalChagePass;
