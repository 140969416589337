import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Form, Input, Select, Upload, message, Button, InputNumber, Modal, Spin, Collapse, Space, Checkbox
} from 'antd';
import { HomeOutlined, UploadOutlined, SaveOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import JoditEditor from "jodit-react";

import CONSTANT from '../../../config/constant';
import { axiosInstance } from '../../../config/api';
const { Panel } = Collapse;
const status = [
    { value: 'publish', label: 'Công khai' },
    { value: 'draft', label: 'Bản nháp' },
];

const currencys = [
    { value: 'VND', label: 'VND' },
];
const types = [
    { value: 'simple', name: 'Sản phẩm' },
    { value: 'sim', name: 'Sim du lịch' }
]
const ShopActionProduct = (props) => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    /*================= Editor ============== */

    //define variable
    const [categoriesData, setcategoriesData] = useState([]);
    /*================= Images ============== */
    const [imageList, setImageList] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewTitle, setPreviewTitle] = useState('');
    const [previewImage, setPreviewImage] = useState('');
    const [haveVariant, setHaveVariant] = useState(false);

    const onChangeImages = (index) => ({ fileList }) => {
        if (index === -1)
            setImageList(fileList);
        else {
            const values = form.getFieldValue('variants');
            values[index].images = fileList;
            form.setFieldsValue({ variants: values });
        }
    }

    const onPreviewImages = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }

        setPreviewImage(src);
        setPreviewVisible(true);
        let _previewTitle = file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
        setPreviewTitle(_previewTitle);

    };
    const onRemove = async file => {

        // remove 
    }
    // save image
    const saveImage = async (images) => {
        let arrImage = [];
        if (images.length > 0) {
            for (const item of images) {
                if (item.status === 'done') {
                    if (item.response.code === 200 && item.response.data && item.response.data.filename) {
                        let idImage = await axiosInstance.post(`${CONSTANT.API.EXTRA.SAVE_FILE}`, {
                            filename: item.response.data.filename,
                            type: "shop_product"
                        })
                            .then(async res => {
                                if (res && res.data && res.data.code === 200) {
                                    let filename = res.data.data.url;
                                    if (typeof filename !== 'undefined') {
                                        return await axiosInstance.post(`${CONSTANT.API.SHOP.PRODUCT_IMAGE}`, {
                                            filename
                                        })
                                            .then(mRes => {
                                                if (mRes && mRes.data && mRes.data.code === 200) {
                                                    const { data } = mRes.data;
                                                    if (data) {
                                                        return data._id;
                                                    }
                                                }
                                            }
                                            )
                                    }
                                }
                            });
                        arrImage.push(idImage);
                    }
                }
                else {
                    arrImage.push(item.uid);
                }
            }
        }
        return arrImage;
    };
    // submit
    const onFinish = async (values) => {
        let productSave = {
            name: values.name,
            content: values.content,
            description: values.description,
            categories: values.categories,
            price: values.price,
            currency: values.currency,
            status: values.status,
            images: await saveImage(imageList),
            stock_quantity: values.stock_quantity,
            type: values.type,
            is_hot: values.is_hot
        }

        let productId = await saveProduct(productSave);
        let statusFinish = true
        if (productId && values.variants) {
            statusFinish = await saveVariants(values.variants, values.attributes, productId);
        }
        if (id) {
            if (productId && statusFinish) {
                message.success('Sửa sản phẩm thành công!', 1).then(() => props.history.push('/shop/product'));

            }
            else {
                message.success('Sửa sản phẩm không thành công!')
            }
        }
        else {
            if (productId && statusFinish) {
                message.success('Thêm mới sản phẩm thành công!', 1).then(() => props.history.push('/shop/product'))
            }
            else {
                message.success('Thêm mới sản phẩm không thành công!')
            }
        }
    };
    const saveVariants = async (variants, attributes, productId) => {
        let save = false;
        if (!id) {
            for (let variant of variants) {
                variant.product = productId;
                variant.images = await saveImage(variant.images);
                for (let [index, attribute] of attributes.entries()) {
                    if (attributes.length === variant.name.split('/').length) {
                        let optAdd = [{
                            name: variant.name.split('/')[index],
                            value: variant.name.split('/')[index],
                        }]
                        const attributeAdd = {
                            name: attribute.name,
                            code: attribute.name,
                            options: optAdd
                        }
                        await axiosInstance.post(`${CONSTANT.API.SHOP.ATTRIBUTE}`, attributeAdd)
                            .then(res => {
                                if (res && res.data && res.data.code === 200) {
                                    variant.attributes.push(res.data.data._id);
                                }
                            }).catch(error => { return false; });
                    }
                }
                await axiosInstance.post(`${CONSTANT.API.SHOP.PRODUCT}/${productId}/variants`, variant)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            save = true
                        }
                    }).catch(error => { return false; });
            }
        }
        else {
            for (let variant of variants) {
                variant.product = productId;
                variant.images = await saveImage(variant.images);
                await axiosInstance.patch(`${CONSTANT.API.SHOP.VARIANT}/${variant._id}`, variant)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            save = true
                        }
                    }).catch(error => { return false; });
            }
        }
        return save;
    }
    const saveProduct = async (product) => {
        //        debugger;
        if (!id) {
            return await axiosInstance.post(`${CONSTANT.API.SHOP.PRODUCT}`, product)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        return res.data.data._id;
                    }
                    else {
                        message.error('Thêm mới thất bại! Vui lòng thử lại sau.');
                    }
                });
        }
        else {
            return await axiosInstance.patch(`${CONSTANT.API.SHOP.PRODUCT}/${id}`, product)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        return res.data.data._id;
                    }
                    else {
                        message.error('Sửa thông tin thất bại! Vui lòng thử lại sau.');
                    }
                });
        }
    }
    useEffect(() => {
        loadCategory();
        if (id && id !== '') {
            loadProduct();
        }
    }, []);
    const loadCategory = async () => {
        await axiosInstance.get(`${CONSTANT.API.SHOP.CATEGORY_TREE}?perPage=-1`)
            .then(async res => {
                if (res && res.data && res.data.code === 200) {
                    let cates = await sortCategory(res.data.data);
                    setcategoriesData(cates);
                }
            });
    }
    const sortCategory = (data) => {
        let arr = [];
        data.forEach(level_1 => {
            arr.push(level_1);
            // level_1
            if (level_1.childs.length > 0) {
                level_1.childs.forEach(level_2 => {
                    arr.push(level_2);
                    // level_2
                    if (level_2.childs.length > 0) {
                        level_2.childs.forEach(level_3 => {
                            arr.push(level_3);
                            // level_3
                            if (level_3.childs.length > 0) {
                                level_3.childs.forEach(level_4 => {
                                    arr.push(level_4);
                                })
                            }
                        })
                    }
                });
            }
        })
        arr.map((aData) => {
            if (aData.level === 2) {
                aData.tmpName = '   └ ' + aData.name;
            }
            else if (aData.level === 3) {
                aData.tmpName = '        └ ' + aData.name;
            }
            else if (aData.level === 4) {
                aData.tmpName = '             └ ' + aData.name;
            }
            else {
                aData.tmpName = '♦ ' + aData.name;
            }
        })

        return arr;
    }
    const loadProduct = async () => {
        setLoading(true);
        await axiosInstance.get(`${CONSTANT.API.SHOP.PRODUCT}/${id}`)
            .then(async res => {
                if (res && res.data && res.data.code === 200) {
                    const { data } = res.data;
                    form.setFieldsValue({
                        name: data.name,
                        content: data.content,
                        description: data.description,
                        categories: data.categories && data.categories.length > 0 ? data.categories.map(i => i._id) : [],
                        price: data.price,
                        currency: data.currency,
                        status: data.status,
                        images: [],
                        stock_quantity: data.stock_quantity,
                        type: data.type,
                        is_hot: data.is_hot
                    });
                    if (data.images && data.images.length > 0) {
                        let images = [];
                        for (let i = 0; i < res.data.data.images.length; i++) {
                            let image = res.data.data.images[i];
                            if (image) {
                                images = images.concat({
                                    uid: image._id,
                                    name: CONSTANT.STATIC_URL + image.filename,
                                    status: 'done-add',
                                    url: CONSTANT.STATIC_URL + image.filename,
                                    thumbUrl: CONSTANT.STATIC_URL + image.filename
                                });
                            }
                        }
                        setImageList(images);
                    }
                    await axiosInstance.get(`${CONSTANT.API.SHOP.VARIANT}?product=${id}&perPage=-1`)
                        .then(async res => {
                            if (res && res.data && res.data.code === 200) {
                                for (let variant of res.data.data.docs) {
                                    let imagesLoad = [];
                                    for (let image of variant.images) {
                                        imagesLoad = imagesLoad.concat({
                                            uid: image._id,
                                            name: CONSTANT.STATIC_URL + image.filename,
                                            status: 'done-add',
                                            url: CONSTANT.STATIC_URL + image.filename,
                                            thumbUrl: CONSTANT.STATIC_URL + image.filename
                                        });
                                    }
                                    variant.name = variant.attributes.length > 0 ? variant.attributes.map((item) => item.options[0].name).join('/') : '';
                                    variant.images = imagesLoad;
                                }
                                form.setFieldsValue({
                                    variants: res.data.data.docs
                                });
                                if (res.data.data.docs.length > 0) {
                                    setHaveVariant(true);
                                }
                            }
                        });
                }
            })
            .catch(error => message.warning('Sản phẩm không tồn tại!' + error.message, 2.5).then(() => props.history.push('/product')))
        setLoading(false);
    }
    const autoGeneralProductVariation = async () => {
        form.setFieldsValue({ variants: [] });
        if (form.getFieldValue('attributes') && form.getFieldValue('attributes').length > 0) {
            addProductVariation([], 0,);
        }
        else {
            setHaveVariant(false);
            message.warning('Vui lòng thêm nhóm phân loại sản phẩm', 2.5);
        }
    }
    const addProductVariation = (arr, optionIndex) => {
        if (form.getFieldValue('attributes')) {
            let maxIndexOption = form.getFieldValue('attributes').length - 1;
            let j, l, optionCombinations, optionValue;
            //console.log(form.getFieldValue('attributes')[optionIndex]);
            if (form.getFieldValue('attributes')[optionIndex] && form.getFieldValue('attributes')[optionIndex].name !== '') {
                if (form.getFieldValue('attributes')[optionIndex].opts && form.getFieldValue('attributes')[optionIndex].opts !== '') {
                    for (j = 0, l = form.getFieldValue('attributes')[optionIndex].opts.split(',').length; j < l; j = j + 1) {
                        optionCombinations = arr.slice(0);
                        optionValue = {
                            name: form.getFieldValue('attributes')[optionIndex].opts.split(',')[j]
                        };
                        optionCombinations.push(optionValue);
                        if (optionIndex === maxIndexOption) {
                            let productName = optionCombinations.map((item) => item.name).join('/');
                            let variation = {
                                product: "",
                                name: productName,
                                stock_quantity: 0,
                                price: 0,
                                images: [],
                                attributes: []
                            };
                            let tmpVariant = form.getFieldValue('variants');
                            tmpVariant.push(variation);
                            form.setFieldsValue({
                                variants: tmpVariant
                            });
                            setHaveVariant(true);
                        } else {
                            addProductVariation(optionCombinations, optionIndex + 1, maxIndexOption);
                        }
                    }
                }
                else {
                    message.warning('Vui lòng nhập thuộc tính phân loại (Các thuộc tính cách nhau bởi dấu phẩy. VD: vàng,xanh,đỏ ...)', 5);
                }
            }
            else {
                message.warning('Vui lòng nhập tên phân loại (vd: kích thước, màu sắc ...)', 5);
            }
        }
    }
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/product">Sản phẩm</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {id && id !== '' ? 'Sửa thông tin' : 'Thêm mới'}
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {
                    loading &&
                    <div style={{ textAlign: 'center' }}>
                        <Spin />
                    </div>
                }

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={{
                        currency: "VND",
                        type: "simple"
                    }}>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={18}>
                            <Form.Item
                                label="Tên sản phẩm"
                                name="name"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập tên sản phẩm!' },
                                ]}
                            >
                                <Input placeholder="Tên sản phẩm" />
                            </Form.Item>
                            <Collapse defaultActiveKey={['1']} bordered={false}>
                                <Panel header="Thông số kỹ thuật" key="1">
                                    <Form.Item
                                        name="description"
                                        rules={[
                                            { required: true, message: 'Vui lòng thông số kỹ thuật' },
                                        ]}>
                                        <JoditEditor
                                            config={{ height: 300 }}
                                        />
                                    </Form.Item>
                                </Panel>
                                <Panel header="Mô tả sản phẩm" key="2">
                                    <Form.Item name="content"
                                    >
                                        <JoditEditor
                                            config={{ height: 300 }}
                                        />
                                    </Form.Item>
                                </Panel>
                                <Panel header="Phân loại sản phẩm" key="3">
                                    <Form.List name="attributes">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, opts, fieldKey, ...restField }) => (
                                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'name']}
                                                            fieldKey={[fieldKey, 'name']}
                                                            rules={[{ required: true, message: 'Vui lòng nhập tên nhóm' }]}
                                                        >
                                                            <Input placeholder="Tên nhóm phân loại" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, ['opts']]}
                                                            fieldKey={[fieldKey, ['opts']]}
                                                            rules={[{ required: true, message: 'Vui lòng nhập thuộc tính' }]}
                                                        >
                                                            <Input placeholder="Thuộc tính phân loại cách nhau bởi dấu phẩy" style={{ width: '450px' }} />
                                                        </Form.Item>
                                                        <MinusCircleOutlined onClick={() => {
                                                            remove(name);
                                                            autoGeneralProductVariation();
                                                        }} />
                                                    </Space>
                                                ))}
                                                {
                                                    !id && <Form.Item>
                                                        <Button type="dashed" style={{ width: '200px' }} onClick={() => add()} block icon={<PlusOutlined />}>
                                                            Thêm nhóm phân loại
                                                        </Button>
                                                        <Button type="dashed" style={{ width: '200px', float: 'right' }} onClick={() => autoGeneralProductVariation()} block icon={<PlusOutlined />}>
                                                            Tạo sản phẩm</Button>
                                                    </Form.Item>
                                                }

                                            </>
                                        )}
                                    </Form.List>
                                    <div className="ant-table-content">
                                        <table style={{ tableLayout: "auto" }}>
                                            <thead className="ant-table-thead">
                                                <tr>
                                                    <th className="ant-table-cell">Phân loại</th>
                                                    <th className="ant-table-cell">Số lượng</th>
                                                    <th className="ant-table-cell">Giá bán</th>
                                                    <th className="ant-table-cell">Hình ảnh</th>
                                                </tr>
                                            </thead>
                                            <tbody className="ant-table-tbody">
                                                <Form.List name="variants">
                                                    {(fields, { }) => (
                                                        <>
                                                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                                                                <tr className="ant-table-row" key={name}>
                                                                    <td className="ant-table-cell" style={{ width: '250px' }}>
                                                                        {form.getFieldValue('variants')[name].name}
                                                                    </td>
                                                                    <td className="ant-table-cell" style={{ width: '100px' }}>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'stock_quantity']}
                                                                            fieldKey={[name, 'stock_quantity']}
                                                                            rules={[{ required: true, message: 'Vui lòng nhập số lượng' }]}
                                                                        >
                                                                            <Input placeholder="Nhập số lượng" />
                                                                        </Form.Item>
                                                                    </td>
                                                                    <td className="ant-table-cell" style={{ width: '200px' }}>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, 'price']}
                                                                            fieldKey={[name, 'price']}
                                                                            rules={[{ required: true, message: 'Vui lòng nhập giá bán' }]}                                                                        >
                                                                            <InputNumber
                                                                                placeholder="Nhập giá bán"
                                                                                formatter={value => `${form.getFieldValue('currency')} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                parser={value => value.replace(`${form.getFieldValue('currency')}`, '').replace(/\\s?|(,*)/g, '')}
                                                                                style={{ width: '100%' }}
                                                                                min={0} />
                                                                        </Form.Item>
                                                                    </td>
                                                                    <td className="ant-table-cell">
                                                                        {/* Begin Images */}
                                                                        <Form.Item>
                                                                            <ImgCrop
                                                                                rotate
                                                                                modalTitle="Sửa ảnh"
                                                                                modalOk="Đồng ý"
                                                                                aspect={800 / 672}
                                                                                quality={0.8}
                                                                                modalWidth={800}
                                                                            >
                                                                                <Upload
                                                                                    listType="picture-card"
                                                                                    action={`${CONSTANT.API.EXTRA.UPLOAD_IMAGE}`}
                                                                                    fileList={form.getFieldValue('variants')[name].images}
                                                                                    onPreview={onPreviewImages}
                                                                                    onChange={onChangeImages(name)}
                                                                                    headers={{
                                                                                        'X-TK': CONSTANT.API.KEY
                                                                                    }}
                                                                                    onRemove={onRemove}
                                                                                >
                                                                                    {
                                                                                        form.getFieldValue('variants')[name].images.length < 5 &&
                                                                                        <div>
                                                                                            <PlusOutlined />
                                                                                            <div style={{ marginTop: 8 }}>Chọn hình</div>
                                                                                        </div>
                                                                                    }
                                                                                </Upload>
                                                                            </ImgCrop>
                                                                            <Modal
                                                                                visible={previewVisible}
                                                                                title={previewTitle}
                                                                                footer={null}
                                                                                onCancel={() => setPreviewVisible(false)}>
                                                                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                                                            </Modal>
                                                                        </Form.Item>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    )}
                                                </Form.List>
                                            </tbody>
                                        </table>
                                    </div>

                                </Panel>
                            </Collapse>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                            {/* Begin Images */}
                            <Form.Item label="Hình ảnh">
                                <ImgCrop
                                    rotate
                                    modalTitle="Sửa ảnh"
                                    modalOk="Đồng ý"
                                    aspect={800 / 672}
                                    quality={0.8}
                                    modalWidth={800}
                                >
                                    <Upload
                                        listType="picture"
                                        action={`${CONSTANT.API.EXTRA.UPLOAD_IMAGE}`}
                                        fileList={imageList}
                                        onPreview={onPreviewImages}
                                        onChange={onChangeImages(-1)}
                                        headers={{
                                            'X-TK': CONSTANT.API.KEY
                                        }}
                                        onRemove={onRemove}
                                    >
                                        {
                                            imageList.length < 10 &&
                                            <Button icon={<UploadOutlined />}>Chọn ảnh tải lên</Button>
                                        }
                                    </Upload>
                                </ImgCrop>
                                <Modal
                                    visible={previewVisible}
                                    title={previewTitle}
                                    footer={null}
                                    onCancel={() => setPreviewVisible(false)}>
                                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </Form.Item>
                            {/* End Images */}
                            <Form.Item
                                label="Danh mục"
                                tooltip="Chọn danh mục của sản phẩm"
                                name="categories"
                                placeholder="Chọn danh mục của sản phẩm"
                                required tooltip="Trường này bắt buộc chọn"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn danh mục của sản phẩm !' }
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    placeholder="Vui lòng chọn danh mục"
                                    style={{ width: '100%' }}
                                >
                                    {
                                        categoriesData.length > 0 &&
                                        categoriesData.map(item => <Select.Option key={item._id}>{item.tmpName}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Loại sản phẩm"
                                name="type"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn loại sản phẩm!' }
                                ]}
                            >
                                <Select
                                    placeholder="Chọn loại sản phẩm"
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}>
                                    {
                                        types.length > 0 &&
                                        types.map(item => <Select.Option key={item.value}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Loại tiền tệ"
                                name="currency"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn loại tiền tệ!' }
                                ]}
                            >
                                <Select
                                    placeholder="Loại tiền tệ"
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}>
                                    {
                                        currencys.length > 0 &&
                                        currencys.map(item => <Select.Option key={item.value}>{item.label}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            {
                                haveVariant === false &&
                                <Form.Item
                                    label="Số lượng"
                                    name="stock_quantity"
                                    required tooltip="Trường bắt buộc nhập"
                                    rules={[
                                        { required: true, message: 'Vui lòng số lượng' },
                                    ]}
                                >
                                    <InputNumber
                                        placeholder="Nhập số lượng"
                                        style={{ width: '100%' }}
                                        min={0} />
                                </Form.Item>
                            }
                            <Form.Item
                                label="Giá"
                                name="price"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập giá' },
                                ]}
                            >
                                <InputNumber
                                    placeholder="Nhập giá"
                                    formatter={value => `${form.getFieldValue('currency')} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(`${form.getFieldValue('currency')}`, '').replace(/\\s?|(,*)/g, '')}
                                    style={{ width: '100%' }}
                                    min={0} />
                            </Form.Item>
                            <Form.Item label="Trạng thái" name="status">
                                <Select
                                    placeholder="Chọn trạng thái"
                                    style={{ width: '100%' }}>
                                    {
                                        status.map(item => <Select.Option key={item.value}>{item.label}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item name="is_hot" valuePropName="checked">
                                <Checkbox>Hiển thị lên mục nổi bật trang chủ</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className="footer-toolbar">
                        <div className="right">
                            <Button type="primary" icon={<SaveOutlined />}
                                htmlType="submit"
                            >
                                Lưu thông tin
                            </Button>
                        </div>

                    </div>
                </Form>

            </Layout.Content>

        </div >
    )

}

export default ShopActionProduct;
