import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
	Layout, Breadcrumb, Table, Button, Divider, Form, Input, Row, Col, Avatar, Space, Popconfirm,
	message, Tag
} from 'antd';
import { HomeOutlined, PlusOutlined, EditOutlined, DeleteOutlined, WindowsOutlined } from '@ant-design/icons';
import moment from 'moment';

import { axiosInstance } from '../../../config/api';
import CONSTANT from '../../../config/constant';

export default class ListRoom extends React.PureComponent {

	constructor(props) {
		super(props);

		// define column table
		this.columns = [
			{
				title: 'Ảnh', dataIndex: 'image',
				render: image => image && <Avatar size={64} shape="square" src={`${CONSTANT.STATIC_URL}${image.split(',')[0]}`} />,
				width: '50px'
			},
			{ title: 'Tên', dataIndex: 'name' },
			{
				title: 'SL phòng', dataIndex: 'num_room', width: '100px'
			},
			{
				title: 'Phòng trống', dataIndex: 'num_room_empty', width: '120px'
			},
			{
				title: 'Trạng thái', dataIndex: 'is_active', width: '150px',
				render: is_active => is_active ? <Tag color="green">Hoạt động</Tag> : <Tag color="red">Tạm đóng</Tag>
			},
			{
				title: 'Hành động', width: '180px',
				render: (text, record) => (
					<Space size="middle">
						<Link to={`/hotel/${this.props.match.params.idHotel}/room/edit/${record._id}`}><EditOutlined /> Sửa</Link>
						<Divider type="vertical" />
						<Popconfirm title="Bạn muốn xóa phòng này?" onConfirm={() => this.handleDelete(record._id)}>
							<a><DeleteOutlined /> Xóa</a>
						</Popconfirm>
					</Space>
				)
			}
		];
		this.state = {
			loading: false,
			data: {},
			dataRooms: []
		};
	}
	onSelectChange = selectedRowKeys => {
		this.setState({ selectedRowKeys });
	}
	handleDelete = async (key) => {

		if (key) {
			await axiosInstance.delete(`${CONSTANT.API.HOTEL.ROOM}/${key}`)
				.then((res) => {
					if (res && res.data && res.data.code === 200) {
						message.success('Xóa thành công!');
						let dataSource = [...this.state.dataRooms];
						this.setState({
							dataRooms: dataSource.filter((item) => item._id !== key)
						});
					}
					else {
						message.error('Xóa không thành công');
					}
				})
				.catch(err => message.error('Xóa không thành công'))
		}

	}

	async componentDidMount() {
		this.fetch();
	}

	fetch = async () => {
		const { idHotel } = this.props.match.params;
		if (idHotel) {
			this.setState({ loading: true });
			await axiosInstance.get(`${CONSTANT.API.HOTEL.HOTEL}/${idHotel}`)
				.then(res => {
					if (res && res.data && res.data.code === 200) {
						const data = res.data.data;
						this.setState({
							loading: false,
							data,
							dataRooms: data.rooms
						})
					}
				})
		}
		else {
			message.warning('Hotel không tồn tại').then(res => this.props.history.goBack());
		}


	}


	render() {
		const { loading, data, dataRooms } = this.state;

		return (
			<div>
				<Breadcrumb style={{ margin: '16px 0' }}>
					<Breadcrumb.Item >
						<Link to="/"><HomeOutlined /></Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item >
						<Link to="/hotel">Khách sạn</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						Phòng của khách sạn {data && data.name}
					</Breadcrumb.Item>
				</Breadcrumb>
				<Layout.Content className="layout-content">

					<Row justify="end" style={{ marginBottom: 20 }}>
						<Col>
							<Button type="primary" icon={<PlusOutlined />} onClick={() => this.props.history.push(`/hotel/${this.props.match.params.idHotel}/room/add`)} >
								Thêm mới
							</Button>
						</Col>
					</Row>

					<Table
						columns={this.columns}
						dataSource={dataRooms}
						loading={loading}
						pagination={false}
						rowKey={record => record._id}
					/>
				</Layout.Content>
			</div>
		)
	}
}
