import React from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, Table, Card, Select, Button, Row, Col, Descriptions, Input,Spin,
    message,
} from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import moment from 'moment';
import { axiosInstance } from '../../../config/api';
import CONSTANT from '../../../config/constant';
import * as COMMON from '../../../config/common';

export default class DetailBooking extends React.PureComponent {
    constructor(props) {
        super(props);

        // define column table
        this.columns = [
            {
                title: 'Khách sạn',
                dataIndex: 'hotel',
                render: hotel => hotel.name
            },
            {
                title: 'Phòng',
                dataIndex: 'room',
                render: room => room.name
            },
            {
                title: 'Số lượng',
                dataIndex: 'quantity',
                className: 'text-center'
            },
            {
                title: 'Giá',
                dataIndex: 'price',
                type: 'number',
            },
            {
                title: 'Giường phụ',
                dataIndex: 'quantity_extrabed'

            },
            {
                title: 'Giá giường phụ',
                dataIndex: 'hotel',
                render: hotel => hotel.price_extrabed

            },
            {
                title: 'Thời gian lưu trú',
                render: () => this.state.total_date

            },
            {
                title: 'Thành tiền',
                render: (record) => (
                    record.quantity * record.price * this.state.total_date + (record.hotel.quantity_extrabed && record.hotel.price_extrabed ? record.hotel.quantity_extrabed * record.hotel.price_extrabed * this.state.total_date : 0)
                )
            },
        ];
        this.state = {
            arrStatus: [
                { label: "Hoàn thành", value: "completed" },
                { label: "Đã xác nhận - Chờ thanh toán", value: "wait-paid" },
                { label: "Chờ xác nhận", value: "pending" },
                { label: "Thay đổi Phòng - Chờ xác nhận", value: "change_room" },
                { label: "Thay đổi Ngày - Chờ xác nhận", value: "change_date" },
                { label: "Thay đổi thông tin - Chờ thanh toán", value: "change" },
                { label: "Đã thanh toán", value: "paid" },
                { label: "Đang xử lý", value: "processing" },
                { label: "Hủy", value: "cancelled" },
                { label: "Hoàn tiền", value: "refunded" },
            ],
            loading: false,
            detailOrder: null,
            total_date: 0,
            statusOrder: '',
            transferId: ''
        };
    }

    onChangeSelect = async (value) => {
        this.setState({ statusOrder: value });
    }

    async componentDidMount() {
        this.fetch();
    }

    fetch = async () => {
        this.setState({ loading: true });
        if (this.props.match.params.id) {
            await axiosInstance.get(`${CONSTANT.API.HOTEL.ORDER}/${this.props.match.params.id}`)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        debugger;
                        this.setState({
                            loading: false,
                            detailOrder: res.data.data,
                            statusOrder: res.data.data.status,
                            transferId: res.data.data.payment_id ? res.data.data.payment_id : "",
                            total_date: COMMON.calculateDate(res.data.data.date_checkin, res.data.data.date_checkout)
                        })
                    }
                })
        }
        else {
            this.setState({ loading: false });
            message.warning('Hóa đơn không tồn tại mã hóa đơn !').then(res => this.props.history.goBack());
        }
    }
    updateStatus = async () => {
        this.setState({ loading: true });
        if (this.props.match.params.id) {
            await axiosInstance.patch(`${CONSTANT.API.HOTEL.ORDER}/${this.props.match.params.id}`, { status: this.state.status })
                .then(res => {
                    this.setState({ loading: false });
                    if (res && res.data && res.data.code === 200) {                        
                        message.success('Cập nhật trạng thái thành công!')
                    }
                    else{
                        message.error('Cập nhật trạng thái không thành công!');
                    }
                })
                .catch(error => {
                    message.error('Cập nhật trạng thái không thành công!' + error.message);
                    this.setState({ loading: false });
                });
        }
        else {
            this.setState({ loading: false });
            message.error('Cập nhật trạng thái không thành công!');
        }
    }
    updateTransferId = async () => {
        this.setState({ loading: true });
        if (this.props.match.params.id) {
            await axiosInstance.patch(`${CONSTANT.API.HOTEL.ORDER}/${this.props.match.params.id}`, { payment_id: this.state.transferId })
                .then(res => {
                    this.setState({ loading: false });
                    if (res && res.data && res.data.code === 200) {                        
                        message.success('Cập nhật mã giao dịch thành công!')
                    }
                    else{
                        message.error('Cập nhật mã giao dịch không thành công!');
                    }
                })
                .catch(error =>{
                    this.setState({ loading: false });
                    message.error('Cập nhật mã giao dịch không thành công!' + error.message);
                })
        }
        else {
            this.setState({ loading: false });
            message.error('Cập nhật mã giao dịch không thành công!');
        }
    }
    render() {
        const { loading, detailOrder } = this.state;
        return (
            <div>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item >
                        <Link to="/"><HomeOutlined /></Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/hotel/bookings">Danh sách hóa đơn</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Thông tin hóa đơn
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Layout.Content className="layout-content">
                    {
                        detailOrder ? <>
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={18}>
                                    <Descriptions title={"Booking: #TATINTA-H" + detailOrder.uid}>
                                        <Descriptions.Item label="Họ tên">{detailOrder.contact.fullname}</Descriptions.Item>
                                        <Descriptions.Item label="Email">{detailOrder.contact.email}</Descriptions.Item>
                                        <Descriptions.Item label="Điện thoại">{detailOrder.contact.phone}</Descriptions.Item>
                                        <Descriptions.Item label="Checkin">{moment(detailOrder.contact.date_checkin).format(CONSTANT.DATE_FORMAT)}</Descriptions.Item>
                                        <Descriptions.Item label="Checkout">{moment(detailOrder.contact.date_checkout).format(CONSTANT.DATE_FORMAT)}</Descriptions.Item>
                                        <Descriptions.Item label="Thời gian lưu trú">{detailOrder.total_date + ' ngày'}</Descriptions.Item>
                                    </Descriptions>
                                    <Descriptions title="Giao dịch thanh toán">
                                        <Descriptions.Item label="Phương thức thanh toán">{detailOrder.payment_method_title}</Descriptions.Item>
                                        <Descriptions.Item label="Tình trạng giao dịch">{this.state.statusOrder}</Descriptions.Item>
                                        <Descriptions.Item label="Ngày tạo giao dịch">{detailOrder.created_at ? moment(detailOrder.created_at).format(CONSTANT.DATE_FORMAT) : "-"}</Descriptions.Item>
                                        <Descriptions.Item label="Ngày hoàn thành giao dịch">{detailOrder.date_paymented ? moment(detailOrder.date_paymented).format(CONSTANT.DATE_FORMAT) : "-"}</Descriptions.Item>
                                        <Descriptions.Item label="Mã giao dịch ">{this.state.transferId}</Descriptions.Item>
                                    </Descriptions>
                                    <Table
                                        columns={this.columns}
                                        dataSource={detailOrder.items}
                                        loading={loading}
                                        pagination={false}
                                        rowKey={record => record._id}
                                        footer={() => <>
                                            <div style={{ float: 'left', width: '100%', padding: '10px 0', borderBottom: '1px #ccc solid' }}><span style={{ float: 'left' }}>Phí phục vụ</span> <span style={{ float: 'right', marginRight: 20 }}>{detailOrder.service_fee ? detailOrder.service_fee : 'NaN'}</span></div>
                                            <div style={{ float: 'left', width: '100%', padding: '10px 0', borderBottom: '1px #ccc solid' }}><span style={{ float: 'left' }}>Thuế VAT</span> <span style={{ float: 'right', marginRight: 20 }}>{detailOrder.vat_fee ? detailOrder.vat_fee : 'NaN'}</span></div>
                                            <div style={{ float: 'left', width: '100%', padding: '10px 0' }}><span style={{ float: 'left' }}>Tổng cộng</span> <span style={{ float: 'right', marginRight: 20 }}>{detailOrder.total ? detailOrder.total : 'NaN'}</span></div></>}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={6}>
                                    <Card title="Cập nhật trạng thái hóa đơn" bordered={false}>
                                        <Select
                                            placeholder="Chọn trạng thái"
                                            onChange={this.onChangeSelect}
                                            value={this.state.statusOrder}
                                            style={{ width: '100%' }}>
                                            {
                                                this.state.arrStatus.map(item => <Select.Option key={item.value}>{item.label}</Select.Option>)
                                            }
                                        </Select>
                                        <Row justify="end" style={{ margin: '20px 0' }}>
                                            <Col>
                                                <Button type="primary" onClick={() => this.updateStatus()} disabled={detailOrder.status == this.state.statusOrder}>
                                                    Cập nhật
                                                </Button>
                                            </Col>
                                        </Row>

                                    </Card>
                                    {
                                        loading &&
                                        <div style={{ textAlign: 'center' }}>
                                            <Spin />
                                        </div>
                                    }
                                    <Card title="Cập nhật thông tin giao dịch" bordered={false}>
                                        <label style={{ fontSize: 12, marginTop: -10 }}>Dành cho trường hợp thanh toán chuyển khoản ngân hàng</label>
                                        <Input placeholder="Nhập mã giao dịch" value={this.state.transferId} onChange={e => this.setState({ transferId: e.target.value })} />
                                        <Row justify="end" style={{ margin: '20px 0' }}>
                                            <Col>
                                                <Button type="primary" onClick={() => this.updateTransferId()} disabled={!(this.state.transferId !== '' && this.state.transferId !== detailOrder.payment_id)} >
                                                    Lưu mã giao dịch
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row></> : null
                    }

                </Layout.Content>
            </div>
        )
    }
}
