import React, { useState } from 'react';
import { Layout, ConfigProvider } from 'antd';
import viVN from 'antd/lib/locale/vi_VN';

// import component
import MenuSider from '../../components/menu-sider';
import HeaderTop from '../../components/header-top';

const { Footer } = Layout;

export default ({ children }) => {

    const [collapsed, setCollapsed] = useState(false);

    // toogle menu sider
    const toggle = () => {
        setCollapsed(!collapsed)
    }

    return (

        <ConfigProvider locale={viVN}>
            <Layout className="body-height">

                {/* Begin menu sider */}
                <MenuSider collapsed={collapsed} />
                {/* End menu sider */}

                <Layout className="site-layout">

                    {/* Begin header top */}
                    <HeaderTop toggle={toggle} collapsed={collapsed} history={children.props.history} />
                    {/* End header top */}

                    {/* Begin dynamic content page */}
                    <Layout style={{ padding: '0 24px 24px' }}>
                        {children}
                    </Layout>
                    {/* End dynamic content page */}

                    <Footer style={{ textAlign: 'center' }}>Vivmedic ©2021 Thiết kế bởi Vivmedic</Footer>
                </Layout>
            </Layout>
        </ConfigProvider>

    )
}