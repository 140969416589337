import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, DatePicker, Form, Table, Select, Input, message, Tabs, Button, Tag,
} from 'antd';
import { HomeOutlined, RedoOutlined, SearchOutlined, MobileOutlined, UserOutlined, BranchesOutlined, CalendarOutlined, EnvironmentOutlined, DollarOutlined, MailOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import CONSTANT from '../../../config/constant';
import { axiosInstance } from '../../../config/api';
import moment from 'moment';
import * as COMMON from '../../../config/common';
const { TabPane } = Tabs;
const arrStatus = {
    'wait_paid': { text: "Chờ thanh toán", color: "grey" },
    'paid': { text: 'Đã thanh toán', color: 'orange' },
    'wait-confirm': { text: "Đang chờ duyệt", color: "orange" },
    'confirmed': { text: 'Đã chấp nhận', color: '#2db7f5' },
    'completed': { text: 'Đã hoàn thành', color: '#87d068' },
    'not-come': { text: 'Bệnh nhân không đến', color: 'grey' },
    'cancelled': { text: 'Đã từ chối', color: '#f50' },
    'trash': { text: 'Đơn chưa thanh toán', color: '#f50' }
};
const StatisticOrderService = (props) => {
    const [form] = Form.useForm();
    const columns = [
        {
            title: '#',
            dataIndex: 'uid',
        },

        {
            title: 'Thông tin người đặt',
            dataIndex: 'contact',
            render: contact => <label><UserOutlined /> {contact?.fullname}<br></br><MobileOutlined /> ({contact?.dial_code}) {contact?.phone}<br></br><MailOutlined /> {contact.email}</label>
        },
        {
            title: 'Thông tin dịch vụ',
            dataIndex: 'items',
            render: items => <label><BranchesOutlined /> {items[0].service.name}<br></br><DollarOutlined /> {items[0].is_free ? 'Khám miễn phí' : items[0].price.toLocaleString('en-US') + ' ₫'}</label>
        },
        {
            title: 'Thời gian và địa điểm',
            render: (text, record) => <label>
                <CalendarOutlined /> {moment(record?.date).format("DD-MM-YYYY")} -{" "}{moment(record?.time).format("HH:mm")}<br></br>
                <HomeOutlined /> {record.work_place === 'at_guest' ? 'Tại nhà của khách hàng' : 'Tại cơ sở dịch vụ'}<br></br><EnvironmentOutlined /> {record.address_work}</label>
        },
        {
            title: 'Tổng tiền', dataIndex: 'total', width: '150px', align: 'right',
            render: total => COMMON.currencyFormat(parseFloat(total)) + ' ₫'
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status', width: '150px',
            render: status => <Tag color={arrStatus[status]?.color}>{arrStatus[status]?.text}</Tag>
        }
    ];
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [status, setStatus] = useState('all');
    const [total, setTotal] = useState(0);
    const [count, setCount] = useState(0);
    const [countPaging, setCountPaging] = useState(0);
    const [statistics, setStatistics] = useState([]);
    const [optionFilter, setOptionFilter] = useState({});
    const [pagination, setPagination] = useState({
        pageSize: 10,
        total: 0,
        perPage: 10,
        hideOnSinglePage: true,
        showLessItems: true
    });
    // effect
    useEffect(() => {
        loadStatistic({ page: 1, perPage: 10 });
    }, []);
    const changeStatusOrder = async (statusOrder) => {
        let options = { ...optionFilter };
        if (statusOrder !== status) {
            await setOrders([]);
            await setStatus(statusOrder);
            if (statusOrder !== 'all' && statusOrder) {
                options.status = statusOrder;
            }
            else {
                delete options.status;
            }
            loadStatistic({
                page: 1,
                perPage: pagination.perPage,
                ...options
            });
            setOptionFilter(options);
        }
    }
    const loadStatistic = async (queryParam) => {
        setLoading(true);
        let query = Object.keys(queryParam).map(key => key + '=' + queryParam[key]).join('&');
        await axiosInstance.get(`${CONSTANT.API.SERVICE.STATISTIC}?${query}`)
            .then(async res => {
                if (res && res.data && res.data.code === 200 && res.data.data.docs.length > 0) {
                    let arrStatistic = [];
                    let _total = 0;
                    let _count = 0;
                    if (!queryParam.status) {
                        ['wait_paid', 'wait-confirm', 'paid', 'confirmed', 'completed', 'not-come', 'cancelled','trash'].map((item) => {
                            for (const statistic of res.data.data.docs) {
                                if (statistic._id === item) {
                                    arrStatistic.push(statistic);
                                    _total += statistic.total;
                                    _count += statistic.count;
                                    break;
                                }
                            }
                        });
                        setStatistics(arrStatistic);
                        setTotal(_total);
                        setCount(_count);
                        setCountPaging(_count);
                    }
                    else {
                        arrStatistic.push(res.data.data.docs[0]);
                        setCountPaging(res.data.data.docs[0].count)
                    }
                    loadOrder(queryParam);
                }
                else {
                    setStatistics([]);
                    setOrders([]);
                    setCount(0);
                    setTotal(0);
                }
            })
            .catch(error => message.warning('Đã có lỗi kết trong quá trình thống kê! Vui lòng quay lại sau, cảm ơn bạn!' + error.message, 5).then(() => props.history.push('/')))

        setLoading(false);
    }
    const loadOrder = async (queryParam) => {
        setLoading(true);
        let query = Object.keys(queryParam).map(key => key + '=' + queryParam[key]).join('&');
        await axiosInstance.get(`${CONSTANT.API.SERVICE.ORDER}?${query}&statistic=true`)
            .then(async res => {
                if (res && res.data && res.data.code === 200) {
                    // Set giá trị cho pagination
                    let _pagination = { ...pagination, current: queryParam.page };
                    _pagination.total = res.data.data.total;
                    setPagination(_pagination);
                    setOrders(res.data.data.docs);
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                setOrders([]);
                message.warning('Lỗi dữ liệu nhập không đúng!', 3);
            });
        setLoading(false);
    }
    // Xử lý sự kiện search
    const onSearch = async (values) => {
        let options = {};
        if (values.q !== '' && values.q !== undefined) {
            if (values.field === 'name') {
                options.fullname = values.q;
            }

            if (values.field === 'uid') {
                options.uid = values.q.replace('VIVMEDIC-S', '');
            }
        }
        if (values.date_range) {
            options.date_range = JSON.stringify({
                start: moment(values.date_range[0]).format("YYYY-MM-DD"),
                end: moment(values.date_range[1]).format("YYYY-MM-DD")
            });
        }
        if (status !== 'all' && status) {
            options.status = status;
        }
        loadStatistic({
            page: 1,
            perPage: pagination.perPage,
            ...options
        });
        setOptionFilter(options);
    }
    const reload = () => {
        setStatus('all');
        loadOrder({ page: 1, perPage: pagination.perPage });
        form.resetFields();
    }    
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Dịch vụ y tế
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Thống kê dịch vụ
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {/* Begin form search */}
                <Form
                    layout="inline"
                    onFinish={onSearch}
                    initialValues={{
                        field: 'name'
                    }}
                    style={{ margin: '0px -8px 20px -8px' }}
                    form={form}
                >
                    <Form.Item name="q"
                        /*required rules={[
                            { required: true, message: 'Vui lòng nhập Tên người khám hoặc Mã lịch hẹn' }
                        ]}*/
                        extra="(VD: Nguyễn Văn A hoặc VIVMEDIC-S123)"
                    >
                        <Input
                            type="text"
                            placeholder="Nhập Tên người khám hoặc Mã lịch hẹn "
                            style={{ width: 300 }}
                        />
                    </Form.Item>
                    <Form.Item name="field">
                        <Select
                            style={{ width: 150 }}
                        >
                            <Select.Option value="name">Tên người khám</Select.Option>
                            <Select.Option value="uid">Mã lịch hẹn</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="date_range">
                        <DatePicker.RangePicker format="DD-MM-YYYY" />
                    </Form.Item>
                    <Form.Item>
                        <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
                            Tìm
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button icon={<RedoOutlined />} onClick={reload}>
                            Tải lại trang
                        </Button>
                    </Form.Item>
                </Form>
                {/* End form search */}

                <Tabs defaultActiveKey="all" onChange={(key) => {
                    changeStatusOrder(key);
                }}>
                    <TabPane tab={<div style={{ color: '#1890ff' }}>{'Tất cả (' + count + ')'}<br></br>{total.toLocaleString('en-US') + ' ₫'}</div>} key="all">
                    </TabPane>
                    {
                        statistics && statistics.length > 0 && statistics.map((item) => (
                            <TabPane tab={<div style={{ color: arrStatus[item?._id]?.color }}>{arrStatus[item?._id]?.text + ' (' + item.count + ')'}<br></br>{item.total.toLocaleString('en-US') + ' ₫'}</div>} key={item._id}>
                            </TabPane>
                        ))
                    }
                </Tabs>
                {
                    orders && <Table
                        dataSource={orders}
                        columns={columns}
                        loading={loading}
                        pagination={{
                            onChange: async (page, pageSize) => {
                                let para = { page, perPage: pageSize };
                                if (status !== 'all') {
                                    para.status = status;
                                }
                                loadOrder(para);
                            },
                            ...pagination
                        }}
                        rowKey={record => record._id}
                    />
                }
            </Layout.Content>
        </div >
    )

}

export default StatisticOrderService;