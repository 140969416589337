import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
	Layout, Breadcrumb, Table, message, Spin, Space, Tag, Divider, Popconfirm
} from "antd";
import moment from "moment";
import { HomeOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import CONSTANT from "../../../config/constant";
import { axiosInstance } from "../../../config/api";
import ModalInfoOrderService from "../components/info-order";
import * as Service from '../../../config/service';
const arrStatus = {
	wait_paid: { text: "Chờ thanh toán", color: "red" },
	paid: { text: "Đã thanh toán", color: "green" },
	confirmed: { text: "Đã xác nhận", color: "blue" },
	"wait-confirm": { text: "Chờ BS xác nhận", color: "gold" },
	completed: { text: "Khách xác nhận hoàn thành", color: "green" },
	"not-come": { text: "Khách không đến", color: "volcano" },
	abandoned: { text: "Khách đã hủy", color: "red" },
	cancelled: { text: "Bạn đã hủy tour này", color: "red" },
	refunded: { text: "Đã hoàn hoàn tiền", color: "orange" },
};

const ListOrderServiceDoctor = (props) => {
	const columns = [
		{ title: "#", dataIndex: "uid" },
		{ title: "Tên dịch vụ", dataIndex: "items", render: (items) => items.length > 0 && items[0].service.name, },
		{
			title: "Hình thức khám", dataIndex: "items",
			render: (items) =>
				items.length > 0 && items[0]?.service?.is_online ? (
					<Tag color="green">Tư vấn từ xa</Tag>
				) : (
					<Tag color="orange">Thường</Tag>
				),
		},
		{
			title: "Giá khám", width: "150px", dataIndex: "items",
			render: (record) =>
				record.length > 0 && record[0].price > 0
					? record[0].price.toLocaleString("en-US") + " ₫"
					: "Miễn phí",
		},
		{
			title: "Thời gian khám", width: "200px",
			render: (record) =>
				record.date &&
				record.date.day +
				"/" +
				record.date.month +
				"/" +
				record.date.year +
				" - " +
				moment(`${record.time.time_h}:${record.time.time_m}`, "HH:mm").format(
					"HH:mm"
				),
		},
		{
			title: "Ngày đặt", width: "170px", dataIndex: "created_at",
			render: (created_at) =>
				`${moment(new Date(created_at)).format("DD/MM/YYYY HH:mm")}`,
		},
		{
			title: "Trạng thái", type: "tag", dataIndex: "status",
			render: (status) =>
				status && arrStatus[status] ? (
					<Tag color={arrStatus[status].color}>{arrStatus[status].text}</Tag>
				) : (
					<Tag color="black">Rác</Tag>
				),
		},
		{
			title: "Hành động", width: "100px",
			render: (record) => (
				<Space size="small" align="center">
					<Link to={`/doctor/bookings/${record._id}`}>
						<EyeOutlined /> Xem
					</Link>
					<Divider type="vertical" />
					<Popconfirm title="Bạn có chắc xóa?" onConfirm={() => handleDelete(record._id)}>
						<a href="#"><DeleteOutlined /> Xóa</a>
					</Popconfirm>
				</Space>
			),
		},
	];
	const [listService, setListService] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isModalServiceVisible, setIsModalServiceVisible] = useState(false);
	const [orderId, setOrderId] = useState(null);
	const [pagination, setPagination] = useState({
		curent: 1,
		pageSize: 10,
		total: 0,
		hideOnSinglePage: true,
		showLessItems: true
	});

	// effect
	useEffect(() => {
		loadOrder({ page: 1, perPage: pagination.pageSize });
	}, []);

	const loadOrder = async (queryParam) => {
		setLoading(true);
		let query = Object.keys(queryParam)
			.map((key) => key + "=" + queryParam[key])
			.join("&");
		await axiosInstance
			.get(`${CONSTANT.API.DOCTOR.ORDER}?${query}`)
			.then(async (res) => {
				console.log(res)

				if (res && res.data && res.data.code === 200) {
					let _pagination = { ...pagination };
					_pagination.total = res.data.data.total;
					setPagination(_pagination);
					setListService(res.data.data.docs);
				}

			})
			.catch((error) =>
				message
					.warning("Không thể truy cập danh sách đặt khám!" + error.message, 5)
					.then(() => props.history.push("/"))
			);

		setLoading(false);
	};
	const handleDelete = async (key) => {
		if (key) {
			await Service.remove(`${CONSTANT.API.DOCTOR.ORDER}/${key}`)
				.then((res) => {
					if (res && res.data && res.data.code === 200) {
						message.success('Xóa thành công!');
						let _pagination = { ...pagination };
						_pagination.total -= 1;
						setPagination(_pagination);
						const dataSource = [...listService];
						const _listServices = dataSource.filter((item) => item._id !== key);
						setListService(_listServices);
					}
					else {
						message.error('Xóa không thành công');
					}
				})
				.catch(err => message.error('Xóa không thành công'))
		}
	}
	return (
		<div>
			<Breadcrumb style={{ margin: "16px 0" }}>
				<Breadcrumb.Item>
					<Link to="/">
						<HomeOutlined />
					</Link>
				</Breadcrumb.Item>
				<Breadcrumb.Item>Chuyên môn</Breadcrumb.Item>
				<Breadcrumb.Item>Thông tin đặt dịch vụ</Breadcrumb.Item>
			</Breadcrumb>
			<Layout.Content className="layout-content">
				{loading && (
					<div style={{ textAlign: "center" }}>
						<Spin />
					</div>
				)}
				{listService && (
					<Table
						dataSource={listService}
						columns={columns}
						loading={loading}
						pagination={{
							onChange: async (page, pageSize) => {
								loadOrder({ page: page, perPage: pageSize });
							},
							...pagination
						}}
						rowKey={(record) => record._id}
					/>
				)}
			</Layout.Content>
			<ModalInfoOrderService
				visible={isModalServiceVisible}
				orderId={orderId}
				cancel={() => {
					setIsModalServiceVisible(false);
				}}
			/>
		</div>
	);
};

export default ListOrderServiceDoctor;
