const CONSTANT = {};

CONSTANT.BASE_MEET = "https://meet.vivmedic.com";

if (process.env.NODE_ENV === "development") {
  CONSTANT.BASE_API = `http://localhost:8200/v1/`;
  CONSTANT.STATIC_URL = `http://localhost:8200/v1/static/`;
  CONSTANT.BASE_API = `https://api.vivmedic.com/v1/`;
  CONSTANT.STATIC_URL = `https://api.vivmedic.com/v1/static/`;

} else {
  CONSTANT.BASE_API = `https://api.vivmedic.com/v1/`;
  CONSTANT.STATIC_URL = `https://api.vivmedic.com/v1/static/`;
}

CONSTANT.STATIC_URL_TMP = `${CONSTANT.STATIC_URL}tmp/`;

CONSTANT.API = {
  KEY: "hbacrxhfgxhplcwrmfg2cu0946496x",
  AUTH: {
    LOGIN: `${CONSTANT.BASE_API}auth/login`,
    CHANGE_PASS: `${CONSTANT.BASE_API}auth/change-password`,
  },
  USER: {
    CHECK: `${CONSTANT.BASE_API}users/check`,
    ACTIVE: `${CONSTANT.BASE_API}users/active`,
    CHANGE_PASS: `${CONSTANT.BASE_API}auth/change-password`,
    UPDATE: `${CONSTANT.BASE_API}users/`,
    USER: `${CONSTANT.BASE_API}users`,
  },
  AGENCY: {
    AGENCY: `${CONSTANT.BASE_API}agency/agency`,
    IMAGE: `${CONSTANT.BASE_API}agency/image`,
  },
  SHOP: {
    CATEGORY_TREE: `${CONSTANT.BASE_API}shop/product/category/tree`,
    CATEGORY: `${CONSTANT.BASE_API}shop/product/category`,
    PRODUCT: `${CONSTANT.BASE_API}shop/product/product`,
    PRODUCT_IMAGE: `${CONSTANT.BASE_API}shop/product/image`,
    VARIANT: `${CONSTANT.BASE_API}shop/product/variant`,
    ATTRIBUTE: `${CONSTANT.BASE_API}shop/product/attribute`,
    ORDER: `${CONSTANT.BASE_API}shop/product/order`,
    PAYMENT: `${CONSTANT.BASE_API}shop/product/order-checkout`,
    STATISTIC: `${CONSTANT.BASE_API}shop/product/order/statistic`,
  },
  DOCTOR: {
    DOCTOR: `${CONSTANT.BASE_API}doctor/doctor`,
    CATEGORY: `${CONSTANT.BASE_API}doctor/category`,
    IMAGE: `${CONSTANT.BASE_API}doctor/image`,
    ORDER: `${CONSTANT.BASE_API}doctor/order`,
    STATISTIC: `${CONSTANT.BASE_API}doctor/order/statistic`
  },
  SERVICE: {
    SERVICE: `${CONSTANT.BASE_API}service-medial/service`,
    CATEGORY: `${CONSTANT.BASE_API}service-medial/category`,
    CATEGORY_TREE: `${CONSTANT.BASE_API}service-medial/category/tree`,
    IMAGE: `${CONSTANT.BASE_API}service-medial/image`,
    ORDER: `${CONSTANT.BASE_API}service-medial/order`,
    STATISTIC: `${CONSTANT.BASE_API}service-medial/order/statistic`,
  },
  HOSPITAL: {
    TYPE: `${CONSTANT.BASE_API}hospital/type`,
    SPECIALTY: `${CONSTANT.BASE_API}hospital/specialty`,
    HOSPITAL: `${CONSTANT.BASE_API}hospital/hospital`,
    FACILITY: `${CONSTANT.BASE_API}hospital/facility`,
    IMAGE: `${CONSTANT.BASE_API}hospital/image`,
    ORDER: `${CONSTANT.BASE_API}hospital/order`,
  },
  TRANSACTION: `${CONSTANT.BASE_API}transaction/transaction`,
  HOTEL: {
    HOTEL: `${CONSTANT.BASE_API}hotel/hotel`,
    FACILITY: `${CONSTANT.BASE_API}hotel/facility`,
    ROOM: `${CONSTANT.BASE_API}hotel/room`,
    TYPE: `${CONSTANT.BASE_API}hotel/type`,
    IMAGE: `${CONSTANT.BASE_API}hotel/image`,
    ORDER: `${CONSTANT.BASE_API}hotel/order`,
  },
  EXTRA: {
    UPLOAD_AVATAR: `${CONSTANT.BASE_API}extra/upload/avatar`,
    UPLOAD_IMAGE: `${CONSTANT.BASE_API}extra/upload/image`,
    SAVE_FILE: `${CONSTANT.BASE_API}extra/upload/save-file`,
    PAGE: `${CONSTANT.BASE_API}extra/page`,
  },
  DESTINATION: {
    AREA: `${CONSTANT.BASE_API}destination/area`,
    COUNTRY: `${CONSTANT.BASE_API}destination/country`,
    CITY: `${CONSTANT.BASE_API}destination/city`,
    DISTRICT: `${CONSTANT.BASE_API}destination/district`,
  },
  POST: {
    CATEGORY: `${CONSTANT.BASE_API}post/category`,
    CATEGORY_TREE: `${CONSTANT.BASE_API}post/category/tree`,
    POST: `${CONSTANT.BASE_API}post/post`,
    IMAGE: `${CONSTANT.BASE_API}post/image`,
  },
  MEDIA: {
    MEDIA: `${CONSTANT.BASE_API}media/media`,
  },
  QA: `${CONSTANT.BASE_API}qa/qa`,
};

CONSTANT.STORAGE_KEY_USER = `auth-user-${process.env.NODE_ENV}`;
CONSTANT.DATE_FORMAT = "DD/MM/YY - HH:mm";
CONSTANT.STATUS = {
  trash: "Nháp",
  processing: "Đang xử lý",
  completed: "Hoàn thành",
  cancelled: "Đối tác đã hủy",
  abandoned: "Khách đã hủy",
  paid: "Đã thanh toán",
  unpaid: "Không thanh toán",
  wait_paid: "Chờ thanh toán",
  pending: "Chưa giải quyết",
  confirmed: "Đối tác đã xác nhận",
  refunded: "Đã hoàn tiền",
  'wait-confirm': "Chờ xác nhận",
  "agency-begin": "Đối tác xác nhận đã bắt đầu",
  "patient-begin": "Khách xác nhận đã bắt đầu",
  "patient-completed": "Khách đã xác nhận hoàn thành"
};
CONSTANT.STATUS_COLOR = {
  trash: "default",
  abandoned: "red",
  processing: "orange",
  completed: "green",
  cancelled: "red",
  paid: "geekblue",
  unpaid: "volcano",
  wait_paid: "gold",
  pending: "purple",
  confirmed: "geekblue",
  refunded: "purple",
  'wait-confirm': "gold",
  "agency-begin": "volcano",
  "patient-begin": "purple",
  "patient-completed": "green"
};

CONSTANT.PAY_METHOD = {
  bank_transfer: "Thanh toán chuyển khoản",
  onepayDomestic: "Thanh toán qua cổng thanh toán OnePay nội địa",
  onepayInternational: "Thanh toán qua cổng thanh toán OnePay quốc tế",
};

CONSTANT.FEE = {
  VAT: 0.1,
  DOCTOR: 60000,
};

export default CONSTANT;
