import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Form, Input, Select, Upload, message, Button, Tabs, Avatar, Spin, Checkbox, Divider, Popconfirm
} from 'antd';
import { HomeOutlined, LoadingOutlined, SaveOutlined, PlusOutlined, DeleteRowOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import JoditEditor from "jodit-react";
import CONSTANT from '../../config/constant';
import { axiosInstance } from '../../config/api';
import * as COMMON from '../../config/common';
const status = [
    { value: 'approve', label: 'Cần kiểm duyệt' },
    { value: 'draft', label: 'Bản nháp' },
];
const ActionDoctor = (props) => {
    const [form] = Form.useForm();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    /*================= Editor ============== */

    //define variable
    const [categoriesData, setCategoriesData] = useState([]);
    const [categoriesEnData, setCategoriesEnData] = useState([]);
    const [specialties, setSpecialties] = useState([]);
    const [areaData, setAreaData] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [cityData, setCityData] = useState([]);
    //const [wardData, setWardData] = useState([]);
    /*================= Images ============== */
    const [doctorAvatar, setDoctorAvatar] = useState({
        filename: null,
        status: 'done-add',
        url: null,
        thumbUrl: null
    }
    );
    const beforeUploadAvatar = async file => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Bạn chỉ được tải ảnh JPG/PNG!');
        }
        const isLt1M = file.size / 1024 / 1024 < 1;
        if (!isLt1M) {
            message.error('Hình ảnh phải nhỏ hơn 1MB!');
        }
        return isJpgOrPng && isLt1M;
    }
    const changeUploadAvatar = async info => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            const { response } = info.file;
            if (response && response.code === 200) {
                let avatarUp = { ...doctorAvatar };
                avatarUp = {
                    filename: response.data.filename,
                    status: 'done',
                    url: CONSTANT.STATIC_URL + 'tmp/' + response.data.filename,
                    thumbUrl: CONSTANT.STATIC_URL + 'tmp/' + response.data.filename,
                    code: 200
                }
                setDoctorAvatar(avatarUp);
            }
        }
    }

    /*================= Select ============== */
    const onChangeSelect = (type) => async (value) => {
        switch (type) {
            case 'type':
                break;
            case 'area':
                setCountryData([]);
                setCityData([]);
                form.setFieldsValue({
                    country: null,
                    city: null
                })
                // load country
                await axiosInstance.get(`${CONSTANT.API.DESTINATION.COUNTRY}?perPage=-1&area=${value}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            setCountryData(res.data.data.docs);
                        }
                    });
                break;
            case 'country':
                setCityData([]);
                form.setFieldsValue({
                    city: null
                })
                // load city
                await axiosInstance.get(`${CONSTANT.API.DESTINATION.CITY}?perPage=-1&country=${value}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            setCityData(res.data.data.docs);
                        }
                    });
                break;
            default:
                break;
        }
    }
    const saveImage = async () => {
        if (doctorAvatar) {
            if (doctorAvatar.status === 'done') {
                if (doctorAvatar.code === 200) {
                    return await axiosInstance.post(`${CONSTANT.API.EXTRA.SAVE_FILE}`, {
                        filename: doctorAvatar.filename,
                        type: "doctor"
                    }).then(async res => {
                        if (res && res.data && res.data.code === 200) {
                            return res.data.data.url;
                        }
                    });
                }
            }
            else {
                return doctorAvatar.filename;
            }
        }
    };
    // submit
    const onFinish = async (values) => {
        values.image = await saveImage();
        values.translations = {
            en: {
                fullname: values.fullname_en,
                practicing_certificate: values.practicing_certificate_en,
                training_process: values.training_process_en,
                working_process: values.working_process_en,
                practicing_certificate: values.practicing_certificate_en,
                home_office: values.home_office_en,
            }
        }
        for (let i = 0; i < values.addresses.length; i++) {
            let item = values.addresses[i];
            item.translations = {
                en: {
                    name: item?.name_en,
                    address: item?.address_en
                }
            }
        }
        values.is_active = true;
        values.status = "publish"
        if (!id) {
            await axiosInstance.post(`${CONSTANT.API.DOCTOR.DOCTOR}`, values)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Cập nhật thông tin thành công !', 7)
                    }
                    else {
                        message.error('Cập nhật thông tin thất bại! Vui lòng thử lại sau.', 5);
                    }
                });
        }
        else {
            await axiosInstance.patch(`${CONSTANT.API.DOCTOR.DOCTOR}/${id}`, values)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Cập nhật thông tin thành công !', 7)
                    }
                    else {
                        message.error('Cập nhật thông tin thất bại! Vui lòng thử lại sau.', 5);
                    }
                });
        }

    };
    const onFinishFailed = async (values) => {
        message.error('Vui lòng nhập đầy đủ thông tin theo yêu cầu, cảm ơn !', 5);
    }
    // effect
    useEffect(() => {
        loadArea();
        loadCategoriesDoctor();
        loadSpecialties();
        loadDoctor();
    }, []);
    // load Specialties
    const loadSpecialties = async () => {
        await axiosInstance.get(`${CONSTANT.API.HOSPITAL.SPECIALTY}?perPage=-1`)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    setSpecialties(res.data.data.docs);
                }
            });
    }
    // load category hospital
    const loadCategoriesDoctor = async () => {
        await axiosInstance.get(`${CONSTANT.API.DOCTOR.CATEGORY}?perPage=-1`)
            .then(async res => {
                if (res && res.data && res.data.code === 200) {
                    setCategoriesData(res.data.data.docs);
                    const dataEn = res.data.data.docs.map(item => {
                        let itemp = { ...item };
                        itemp.name = itemp.translations.en.name;
                        return itemp;
                    });
                    setCategoriesEnData(dataEn);
                }
            });
    }
    // load Area
    const loadArea = async () => {
        await axiosInstance.get(`${CONSTANT.API.DESTINATION.AREA}?perPage=-1&is_parent=true`)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    setAreaData(res.data.data.docs);
                }
            });
    }
    const loadDoctor = async () => {
        setLoading(true);
        if (id) {
            await axiosInstance.get(`${CONSTANT.API.DOCTOR.DOCTOR}/${id}`)
                .then(async res => {
                    if (res?.data?.code === 200) {
                        let data = res?.data?.data;
                        if (data?.area) {
                            await axiosInstance.get(`${CONSTANT.API.DESTINATION.COUNTRY}?perPage=-1&area=${data.area._id}`)
                                .then(async res => {
                                    if (res && res.data && res.data.code === 200) {
                                        await setCountryData(res.data.data.docs);
                                    }

                                });
                        }
                        // load city by country if exist country
                        if (data?.country) {
                            // load city
                            await axiosInstance.get(`${CONSTANT.API.DESTINATION.CITY}?perPage=-1&country=${data.country._id}`)
                                .then(async res => {
                                    if (res && res.data && res.data.code === 200) {
                                        await setCityData(res.data.data.docs);
                                    }
                                });
                        }
                        if (data) {
                            data?.addresses.forEach(item => {
                                item.name_en = item?.translations?.en.name;
                                item.address_en = item?.translations?.en.address;
                            });
                            form.setFieldsValue({
                                category: data.category ? data.category._id : null,
                                academic_rank: data.academic_rank,
                                degree: data?.degree,
                                fullname: data?.fullname,
                                specialties: data.specialties && data.specialties.length > 0 ? data.specialties.map(i => i._id) : [],
                                practicing_certificate: data.practicing_certificate,
                                training_process: data.training_process,
                                working_process: data.working_process,
                                practicing_certificate: data?.practicing_certificate,
                                home_office: data?.home_office,
                                fullname_en: data?.translations?.en?.fullname,
                                practicing_certificate_en: data?.translations?.en?.practicing_certificate,
                                training_process_en: data?.translations?.en?.training_process,
                                working_process_en: data?.translations?.en?.working_process,
                                practicing_certificate_en: data?.translations?.en?.practicing_certificate,
                                home_office_en: data?.translations?.en?.home_office,
                                addresses: data.addresses,
                                area: data.area ? data.area._id : null,
                                country: data.country ? data.country._id : null,
                                city: data.city ? data.city._id : null,
                                status: "publish"
                                //status: data.status && data.status === "publish" ? "approve" : data.status
                            });
                            setDoctorAvatar({
                                filename: data ? data.image : null,
                                status: 'done-add',
                                url: CONSTANT.STATIC_URL + (data ? data.image : null),
                                thumbUrl: CONSTANT.STATIC_URL + (data ? data.image : null)
                            });
                        }
                    }
                    else {
                        message.warning('Bác sĩ không tồn tại !', 4)
                    }
                })
                .catch(error => message.warning('Không truy cập được thông tin, vui lòng quay lại sau, cảm ơn!' + error.message, 5))
        }
        setLoading(false);
    }
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Dành cho bác sĩ
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Thông tin đăng ký
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {
                    loading &&
                    <div style={{ textAlign: 'center' }}>
                        <Spin />
                    </div>
                }

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={{
                        category: null,
                        academic_rank: null,
                        degree: null,
                        fullname: "",
                        specialties: [],
                        practicing_certificate: '',
                        training_process: '',
                        working_process: '',
                        addresses: [],
                        image: null,
                        area: null,
                        country: null,
                        city: null,
                        total_vote: "",
                        status: "publish"
                    }}>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={18}>
                            <Tabs defaultActiveKey="vi" type="card" size="large">
                                <Tabs.TabPane tab="Tiếng Việt" key="vi" forceRender={true}>
                                    <Row gutter={24}>
                                        <Col xs={24} sm={10} md={6}>
                                            <Form.Item
                                                label="Chức danh chuyên môn"
                                                name="category"
                                                required tooltip="Trường bắt buộc nhập"
                                                rules={[
                                                    { required: true, message: 'Vui lòng chọn chức danh chuyên môn !' }
                                                ]}
                                            >
                                                <Select
                                                    placeholder="Chọn chức danh chuyên môn"
                                                    optionFilterProp="children"
                                                    style={{ width: '100%' }}>
                                                    {
                                                        categoriesData.length > 0 &&
                                                        categoriesData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={14} md={5}>
                                            <Form.Item
                                                label="Học hàm"
                                                name="academic_rank"
                                            >
                                                <Select
                                                    placeholder="Học hàm"
                                                    optionFilterProp="children"
                                                    allowClear
                                                    style={{ width: '100%' }}>
                                                    <Select.Option key='hh1' value="GS">Giáo sư</Select.Option>
                                                    <Select.Option key='hh2' value="PGS">Phó Giáo sư</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={14} md={4}>
                                            <Form.Item
                                                label="Học vị"
                                                name="degree"
                                            >
                                                <Select
                                                    placeholder="Chọn học vị"
                                                    optionFilterProp="children"
                                                    allowClear
                                                    style={{ width: '100%' }}>
                                                    <Select.Option key='hv1' value="TS">Tiến sĩ</Select.Option>
                                                    <Select.Option key='hv2' value="ThS">Thạc sĩ</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={14} md={9}>
                                            <Form.Item
                                                label="Tên đầy đủ"
                                                name="fullname"
                                                required tooltip="Trường bắt buộc nhập"
                                                rules={[
                                                    {
                                                        required: true, message: 'Vui lòng nhập tên đầy đủ!',
                                                        type: 'string', max: 100, message: "Tên đầy đủ tối đa 100 ký tự !"
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Nhập tên đầy đủ" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={15}>
                                            <Form.Item label="Chuyên khoa" name="specialties" required tooltip="Vui lòng chọn trường này">
                                                <Select
                                                    mode="multiple"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {
                                                        specialties.length > 0 &&
                                                        specialties.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={9}>
                                            <Form.Item
                                                label="Số CCHN khám chữa bệnh "
                                                name="practicing_certificate"
                                                required tooltip="Trường bắt buộc nhập"
                                                rules={[
                                                    {
                                                        required: true, message: 'Vui lòng nhập Số chứng chỉ!',
                                                        type: 'string', max: 60, message: "Số chứng chỉ tối đa 60 ký tự !"
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Nhập số chứng chỉ hành nghề" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24}>
                                            <Form.Item
                                                label="Nơi công tác"
                                                name="home_office"
                                                required tooltip="Trường bắt buộc nhập"
                                                rules={[
                                                    {
                                                        required: true, message: 'Vui lòng nhập nơi công tác!',
                                                        type: 'string', max: 150, message: "Nơi công tác tối đa 150 ký tự !"
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Nhập nơi công tác hiện tại" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24}>
                                            <Form.Item
                                                label="Quá trình đào tạo"
                                                name="training_process"
                                                tooltip="Quá trình đào tạo"

                                            >
                                                <JoditEditor
                                                    config={{
                                                        height: 300, buttons: [
                                                            "bold",
                                                            "italic",
                                                            "underline",
                                                            "strikethrough",
                                                            "|",
                                                            "ul",
                                                            "ol",
                                                            "|",
                                                            "center",
                                                            "left",
                                                            "right",
                                                            "justify",
                                                            "|",
                                                            "link"
                                                        ],
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label="Quá trình công tác"
                                                name="working_process"
                                                tooltip="Quá trình công tác"
                                            >
                                                <JoditEditor
                                                    config={{
                                                        height: 300, buttons: [
                                                            "bold",
                                                            "italic",
                                                            "underline",
                                                            "strikethrough",
                                                            "|",
                                                            "ul",
                                                            "ol",
                                                            "|",
                                                            "center",
                                                            "left",
                                                            "right",
                                                            "justify",
                                                            "|",
                                                            "link"
                                                        ]
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane forceRender={true} tab="Tiếng Anh" key="en">
                                    <Row gutter={24}>
                                        <Col xs={24} sm={10} md={6}>
                                            <Form.Item
                                                label="Chức danh chuyên môn"
                                                name="category"
                                                required tooltip="Trường bắt buộc nhập"
                                                rules={[
                                                    { required: true, message: 'Vui lòng chọn chức danh chuyên môn !' }
                                                ]}
                                            >
                                                <Select
                                                    placeholder="Chọn chức danh chuyên môn"
                                                    optionFilterProp="children"
                                                    style={{ width: '100%' }}>
                                                    {
                                                        categoriesEnData.length > 0 &&
                                                        categoriesEnData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={14} md={5}>
                                            <Form.Item
                                                label="Học hàm"
                                                name="academic_rank"
                                            >
                                                <Select
                                                    placeholder="Học hàm"
                                                    optionFilterProp="children"
                                                    allowClear
                                                    style={{ width: '100%' }}>
                                                    <Select.Option key='hh1' value="GS">Professor</Select.Option>
                                                    <Select.Option key='hh2' value="PGS">Associate Professor</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={14} md={4}>
                                            <Form.Item
                                                label="Học vị"
                                                name="degree"
                                            >
                                                <Select
                                                    placeholder="Chọn học vị"
                                                    optionFilterProp="children"
                                                    allowClear
                                                    style={{ width: '100%' }}>
                                                    <Select.Option key='hv1' value="TS">Doctor of Medicine</Select.Option>
                                                    <Select.Option key='hv2' value="ThS">Master</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={14} md={9}>
                                            <Form.Item
                                                label="Tên đầy đủ"
                                                name="fullname_en"
                                                required tooltip="Trường bắt buộc nhập"
                                                rules={[
                                                    {
                                                        required: true, message: 'Vui lòng nhập tên đầy đủ!',
                                                        type: 'string', max: 100, message: "Tên đầy đủ tối đa 100 ký tự !"
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Nhập tên đầy đủ" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={15}>
                                            <Form.Item label="Chuyên khoa" name="specialties" required tooltip="Vui lòng chọn trường này">
                                                <Select
                                                    mode="multiple"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {
                                                        specialties.length > 0 &&
                                                        specialties.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={9}>
                                            <Form.Item
                                                label="Số CCHN khám chữa bệnh "
                                                name="practicing_certificate"
                                                required tooltip="Trường bắt buộc nhập"
                                                rules={[
                                                    {
                                                        required: true, message: 'Vui lòng nhập Số chứng chỉ!',
                                                        type: 'string', max: 60, message: "Số chứng chỉ tối đa 60 ký tự !"
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Nhập số chứng chỉ hành nghề" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24}>
                                            <Form.Item
                                                label="Nơi công tác"
                                                name="home_office_en"
                                                required tooltip="Trường bắt buộc nhập"
                                                rules={[
                                                    {
                                                        required: true, message: 'Vui lòng nhập nơi công tác!',
                                                        type: 'string', max: 150, message: "Nơi công tác tối đa 150 ký tự !"
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Nhập nơi công tác hiện tại" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24}>
                                            <Form.Item
                                                label="Quá trình đào tạo"
                                                name="training_process_en"
                                                tooltip="Quá trình đào tạo"

                                            >
                                                <JoditEditor
                                                    config={{
                                                        height: 300, buttons: [
                                                            "bold",
                                                            "italic",
                                                            "underline",
                                                            "strikethrough",
                                                            "|",
                                                            "ul",
                                                            "ol",
                                                            "|",
                                                            "center",
                                                            "left",
                                                            "right",
                                                            "justify",
                                                            "|",
                                                            "link"
                                                        ],
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label="Quá trình công tác"
                                                name="working_process_en"
                                                tooltip="Quá trình công tác"
                                            >
                                                <JoditEditor
                                                    config={{
                                                        height: 300, buttons: [
                                                            "bold",
                                                            "italic",
                                                            "underline",
                                                            "strikethrough",
                                                            "|",
                                                            "ul",
                                                            "ol",
                                                            "|",
                                                            "center",
                                                            "left",
                                                            "right",
                                                            "justify",
                                                            "|",
                                                            "link"
                                                        ]
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Tabs.TabPane>
                            </Tabs>
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={24}> <Divider>Danh sách địa chỉ khám của bạn</Divider></Col>
                                <Col xs={24} sm={24} md={24}>
                                    <Form.List name="addresses" label="Danh sách địa chỉ">
                                        {(fields, { add, remove }) => {
                                            return (
                                                <>
                                                    {
                                                        fields.map((field, index) => (
                                                            <Row gutter={24} key={field.key}>
                                                                <Col xs={3} sm={3} md={3}>
                                                                    Tiếng Việt
                                                                </Col>
                                                                <Col xs={16} sm={17} md={6}>
                                                                    <Form.Item
                                                                        name={[index, 'name']}
                                                                        required tooltip="Tên địa điểm"
                                                                        rules={[
                                                                            {
                                                                                required: true, message: 'Vui lòng nhập tên địa điểm',
                                                                                type: 'string', max: 100, message: "Tên địa điểm tối đa 100 ký tự !"
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input placeholder="Tên địa điểm" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={17} sm={17} md={10}>
                                                                    <Form.Item
                                                                        name={[index, 'address']}
                                                                        required tooltip="Địa chỉ"
                                                                        rules={[
                                                                            {
                                                                                required: true, message: 'Vui lòng nhập địa chỉ khám bệnh',
                                                                                type: 'string', max: 150, message: "Địa chỉ khám bệnh tối đa 150 ký tự !"
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input placeholder="Địa chỉ" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={3} sm={3} md={3}>
                                                                    <Form.Item
                                                                        name={[index, 'is_default']}
                                                                        required tooltip="Hiển thị mặc định"
                                                                        valuePropName="checked"
                                                                    >
                                                                        <Checkbox>Mặc định</Checkbox>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={2} sm={2} md={2}>
                                                                    <Popconfirm title="Bạn muốn xóa địa chỉ khám này ? Sau khi xóa thông tin lịch khám sẽ tự hủy" okText="Đồng ý" cancelText="Không đồng ý" onConfirm={() => remove(field.name)}>
                                                                        <Button
                                                                            type="link"
                                                                            danger
                                                                            style={{ width: '100%' }}
                                                                            title='Xóa địa chỉ khám này'
                                                                            icon={<DeleteRowOutlined />}
                                                                        >
                                                                        </Button>
                                                                    </Popconfirm>
                                                                </Col>
                                                                <Col xs={3} sm={3} md={3}>
                                                                    Tiếng Anh
                                                                </Col>
                                                                <Col xs={16} sm={17} md={6}>
                                                                    <Form.Item
                                                                        name={[index, 'name_en']}
                                                                        required tooltip="Tên địa điểm"
                                                                        rules={[
                                                                            {
                                                                                required: true, message: 'Vui lòng nhập tên địa điểm',
                                                                                type: 'string', max: 100, message: "Tên địa điểm tối đa 100 ký tự !"
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input placeholder="Tên địa điểm" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={17} sm={17} md={10}>
                                                                    <Form.Item
                                                                        name={[index, 'address_en']}
                                                                        required tooltip="Địa chỉ"
                                                                        rules={[
                                                                            {
                                                                                required: true, message: 'Vui lòng nhập địa chỉ khám bệnh',
                                                                                type: 'string', max: 150, message: "Địa chỉ khám bệnh tối đa 150 ký tự !"
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input placeholder="Địa chỉ" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Divider style={{ margin: '0px 0px 20px 0px' }}></Divider>
                                                            </Row>
                                                        ))}
                                                    <Row gutter={24}>
                                                        <Col xs={24} sm={24} md={24}>
                                                            <Form.Item>
                                                                <Button
                                                                    type="dashed"
                                                                    onClick={() => add()}
                                                                    style={{ width: "100%" }}
                                                                >
                                                                    <PlusOutlined /> Thêm địa chỉ khám
                                                                </Button>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                        }}
                                    </Form.List>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                            <Form.Item label="Ảnh đại diện">
                                <Avatar
                                    size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                                    src={doctorAvatar && doctorAvatar.url}
                                />
                                <ImgCrop
                                    rotate
                                    modalTitle="Sửa ảnh"
                                    modalOk="Đồng ý"
                                    aspect={800 / 800}
                                    quality={0.8}
                                    modalWidth={800}
                                >
                                    <Upload
                                        name="file"
                                        action={`${CONSTANT.API.EXTRA.UPLOAD_AVATAR}`}
                                        showUploadList={false}
                                        beforeUpload={beforeUploadAvatar}
                                        onChange={changeUploadAvatar}
                                        headers={{
                                            'X-TK': CONSTANT.API.KEY
                                        }}
                                    >
                                        <Button type="link">
                                            {loading ? <LoadingOutlined /> : 'Chọn ảnh'}
                                        </Button>
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                            <Form.Item label="Khu vực"
                                name="area"
                                required tooltip="Khu vực: Châu Á, Châu Âu, Châu Mỹ"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn khu vực!' }
                                ]}>
                                <Select
                                    placeholder="Chọn khu vực"
                                    onChange={onChangeSelect('area')}
                                    style={{ width: '100%' }}>
                                    {
                                        areaData.length > 0 &&
                                        areaData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Quốc gia"
                                name="country"
                                required tooltip="Trường này bắt buộc chọn"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn quốc gia!' }
                                ]}>
                                <Select
                                    showSearch
                                    placeholder="Chọn quốc gia"
                                    optionFilterProp="children"
                                    onChange={onChangeSelect('country')}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: '100%' }}
                                    onFocus={COMMON.offAutoCompleteSelect()}>
                                    {
                                        countryData.length > 0 &&
                                        countryData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Thành phố"
                                name="city"
                                required tooltip="Trường này bắt buộc chọn"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn thành phố!' }
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Chọn thành phố"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: '100%' }}
                                    onFocus={COMMON.offAutoCompleteSelect()}
                                >
                                    {
                                        cityData.length > 0 &&
                                        cityData.map(item => <Select.Option key={item._id}>{item.name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            {/* <Form.Item label="Trạng thái" name="status">
                                <Select
                                    placeholder="Chọn trạng thái"
                                    style={{ width: '100%' }}>
                                    {
                                        status.map(item => <Select.Option key={item.value}>{item.label}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item> */}
                        </Col>
                    </Row>

                    <div className="footer-toolbar">
                        <div className="right">
                            <Button type="primary" icon={<SaveOutlined />} data-tour="doctor-1"
                                htmlType="submit"
                            >
                                Lưu thông tin
                            </Button>
                        </div>

                    </div>
                </Form>
            </Layout.Content>
        </div>
    )

}

export default ActionDoctor;
