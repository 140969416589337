import React from 'react';
import { Result } from 'antd';

const PageNotFound = () => (
    <Result
        status="404"
        title="404"
        subTitle="Xin lỗi, trang bạn truy cập không tồn tại!"
    />
)
export default PageNotFound;