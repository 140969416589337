import React, { useRef, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Button,
  Spin,
} from "antd";
import { HomeOutlined, SaveOutlined } from "@ant-design/icons";
import JoditEditor from "jodit-react";

import CONSTANT from "../../config/constant";
import { axiosInstance } from "../../config/api";
import Editor from "../../config/editor";

const status = [
  { value: "published", label: "Công khai" },
  { value: "draft", label: "Bản nháp" },
];

const EditPage = (props) => {
  const [form] = Form.useForm();

  const { id } = useParams();

  /*================= Editor ============== */
  const editorRef = useRef(null);
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  // load age
  const loadPage = async () => {
    if (typeof id !== "undefined") {
      return await axiosInstance
        .get(`${CONSTANT.API.EXTRA.PAGE}/${id}`)
        .then((res) => {
          if (res && res.data && res.data.code === 200) {
            return res.data.data;
          }
        })
        .catch((error) => {
          message.warning("Lỗi lấy thông tin trang!");
        });
    }
  };

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    loadPage().then((data) => {
      if (mounted) {
        if (data) {
          form.setFieldsValue({
            name: data.name,
            description: data.description,
            content: data.content,
            status: data.is_active === true ? "published" : "draft",
          });
        }
        setLoading(false);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  // submit
  const onFinish = async (values) => {
    values.is_active = values.status === "published" ? true : false;
    setSaving(true);
    // Create
    await axiosInstance
      .patch(`${CONSTANT.API.EXTRA.PAGE}/${id}`, values)
      .then((res) => {
        if (res && res.data && res.data.code === 200) {
          message
            .success("Lưu thông tin thành công!")
            .then(() => props.history.push("/page"));
        } else {
          message.error("Lưu thông tin thất bại! Vui lòng thử lại sau.");
        }
        setSaving(false);
      })
      .catch((err) => {
        message.error("Lưu thông tin thất bại! Vui lòng thử lại sau.");
        setSaving(false);
      });
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to="/">
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/page">Trang nội dung</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Sửa</Breadcrumb.Item>
      </Breadcrumb>
      <Layout.Content className="layout-content">
        {loading && (
          <div style={{ textAlign: "center" }}>
            <Spin />
          </div>
        )}
        {!loading && (
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              status: "draft",
            }}
          >
            <Row gutter={16}>
              <Col xs={24} sm={24} md={18}>
                <Form.Item
                  label="Tên trang"
                  name="name"
                  required
                  tooltip="Trường bắt buộc nhập"
                  rules={[
                    { required: true, message: "Vui lòng nhập tên trang!" },
                  ]}
                >
                  <Input placeholder="Tên trang" />
                </Form.Item>

                <Form.Item label="Mô tả ngắn gọn" name="description">
                  <Input.TextArea placeholder="Nhập mô tả ngắn gọn" />
                </Form.Item>

                <Form.Item label="Nội dung" name="content">
                  <JoditEditor
                    ref={editorRef}
                    value={content}
                    config={Editor.config}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6}>
                <Form.Item label="Trạng thái" name="status">
                  <Select
                    placeholder="Chọn trạng thái"
                    style={{ width: "100%" }}
                  >
                    {status.map((item) => (
                      <Select.Option key={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <div className="footer-toolbar">
              <div className="right">
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  htmlType="submit"
                  loading={saving}
                >
                  {saving ? "Đang lưu" : "Lưu thông tin"}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Layout.Content>
    </div>
  );
};

export default EditPage;
