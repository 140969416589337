import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { 
    Layout, Breadcrumb, Table, Button, Divider, Form, Input, Row, Col, Avatar, Space, Popconfirm, message
} from 'antd';
import { HomeOutlined, PlusOutlined, EditOutlined, DeleteOutlined, WindowsOutlined } from '@ant-design/icons';

import 'antd/dist/antd.css';

import moment from 'moment';

import { axiosInstance } from '../../config/api';
import CONSTANT from '../../config/constant';


const getRandomuserParams = params => ({
    perPage: params.pagination.pageSize,
    page: params.pagination.current,
    is_deleted: false,
    sort: params.sort ? params.sort : `{"created_at": -1}`
});

export default class ListHotel extends React.PureComponent {

    constructor(props) {
        super(props);

        // define column table
        this.columns = [
            {
                title: 'Ảnh', dataIndex: 'images',
                render: images => images && images.length > 0 && <Avatar size={48} shape="square" src={`${CONSTANT.STATIC_URL}${images[0].filename}`} />,
                width: '50px'
            },
            { title: 'Tên', dataIndex: 'name' },
            { title: 'Thành phố', dataIndex: 'city', width: '200px', render: city => `${city.name}` },
            {
                title: 'Ngày tạo', dataIndex: 'created_at', sorter: true, width: '170px',
                render: created_at => `${moment(new Date(created_at)).format(CONSTANT.DATE_FORMAT)}`
            },
            {
                title: 'Hành động', width: '300px',
                render: (text, record) => (
                    <Space size="middle">
                        <Link to={`/hotel/action/${record._id}`}><EditOutlined /> Sửa</Link>
                        <Divider type="vertical" />
                        <Link to={`/hotel/${record._id}/room`}><WindowsOutlined /> QL phòng</Link>
                        <Divider type="vertical" />
                        <Popconfirm title="Bạn muốn xóa khách sạn này?" onConfirm={() => this.handleDelete(record._id)}>
                            <a><DeleteOutlined /> Xóa</a>
                        </Popconfirm>
                    </Space>
                )
            }
        
        ];

        this.state = {
            selectedRowKeys: [],
            loading: false,
            data: [],
            pagination: {
                current: 1,
                pageSize: 10
            }
        };
    }
    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    }
    handleDelete = async (key) => {
        if (key) {
			await axiosInstance.delete(`${CONSTANT.API.HOTEL.HOTEL}/${key}`)
				.then((res) => {
					if (res && res.data && res.data.code === 200) {
						const dataSource = [...this.state.data];
						this.setState({
                            data: dataSource.filter((item) => item._id !== key),
                        });
                        message.success('Xóa thành công!');
					}
					else {
						message.error('Xóa không thành công');
					}
				})
				.catch(err => message.error('Xóa không thành công'))
		}
        
        
    }
  
    async componentDidMount() {
        const { pagination } = this.state;
        this.fetch({ pagination });
    }

    handleTableChange = (pagination, filters, sorter) => {
        let options = { pagination };
        if (Object.keys(sorter).length > 0)
            options.sort = `{"${sorter.field}": ${sorter.order === 'ascend' ? 1 : -1}}`;
        this.fetch(options);
    }

    fetch = async (params = {}) => {
        this.setState({ loading: true });
        let query = new URLSearchParams(getRandomuserParams(params)).toString();
        let user = localStorage.getItem(`${CONSTANT.STORAGE_KEY_USER}`);
        user = JSON.parse(user);
        if (user && user._id) {
            await axiosInstance.get(`${CONSTANT.API.HOTEL.HOTEL}?${query}&created_by=${user._id}`)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        const { docs, total } = res.data.data;
                        this.setState({
                            loading: false,
                            data: docs,
                            pagination: {
                                ...params.pagination,
                                total
                            }
                        })
                    }

                })
        }

    }


    render() {
        const { loading, selectedRowKeys, data, pagination } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item >
                        <Link to="/"><HomeOutlined /></Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Khách sạn
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Layout.Content className="layout-content">
                    {/* Begin form search */}
                    {/* <Form
                        name="customized_form_controls"
                        layout="inline"
                        initialValues={{
                            price: {
                                number: 0,
                                currency: 'rmb',
                            },
                        }}
                    >
                        <Form.Item name="name" label="Tìm kiếm">
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                        </Form.Item>
                    </Form> 
                    <Divider />
                    {/* End form search */}
                    
                    
                    <Row justify="end" style={{ marginBottom: 20 }}>
                        <Col>
                            <Button type="primary" icon={<PlusOutlined />} onClick={() => this.props.history.push('/hotel/action')} >
                                Thêm mới
                            </Button>
                        </Col>
                    </Row>

                            
                    <Table                       
                        rowSelection={rowSelection}
                        columns={this.columns}
                        dataSource={data}
                        loading={loading}
                        pagination={pagination}
                        rowKey={record => record._id}
                        onChange={this.handleTableChange}
                    />
                </Layout.Content>
            </div>
        )
    }
}
