import React from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import {
  DashboardOutlined,
  EnvironmentOutlined,
  UserOutlined,
  ShopOutlined,
  MedicineBoxOutlined,
  BookOutlined,
  ForkOutlined,
  BankOutlined,
  FilePptOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;

const { SubMenu } = Menu;

export default class MenuSider extends React.PureComponent {
  state = {
    current: "location",
  };
  // Menu click handle
  handleClick = (e) => {
    this.setState({ current: e.key });
  };
  render() {
    return (
      <Sider trigger={null} collapsible collapsed={this.props.collapsed}>
        <div className="logo">
          <Link to="/">
            <img src="vivmedic-white.png" alt="logo tatinta" />
          </Link>
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
            <Link to="/">Trang chủ</Link>
          </Menu.Item>
          {/* Location */}
          <SubMenu
            key="location"
            icon={<EnvironmentOutlined />}
            title="Quản lý địa chỉ"
          >
            <Menu.Item key="area">
              <Link to="/location/area">Khu vực</Link>
            </Menu.Item>
            <Menu.Item key="country">
              <Link to="/location/country">Quốc gia</Link>
            </Menu.Item>
            <Menu.Item key="city">
              <Link to="/location/city">Thành phố</Link>
            </Menu.Item>
            <Menu.Item key="district">
              <Link to="/location/district">Quận/Huyện</Link>
            </Menu.Item>
          </SubMenu>

          {/* Mua sắm */}
          <SubMenu key="shop" icon={<ShopOutlined />} title="Mua sắm">
            <Menu.Item key="shop-category">
              <Link to="/shop/category">Danh mục</Link>
            </Menu.Item>
            <Menu.Item key="shop-product">
              <Link to="/shop/product">Sản phẩm</Link>
            </Menu.Item>
            <Menu.Item key="shop-order">
              <Link to="/shop/order">Đơn hàng</Link>
            </Menu.Item>
            <Menu.Item key="shop-discount">
              <Link to="/shop/discount">Mã giảm giá</Link>
            </Menu.Item>
            <Menu.Item key="shop-statistic">
              <Link to="/shop/statistic">Thống kê</Link>
            </Menu.Item>
          </SubMenu>

          {/*Bác sĩ Chuyên môn */}
          <SubMenu key="cmyt" icon={<ForkOutlined />} title="Bác sĩ CK">
            <Menu.Item key="bs-cate">
              <Link to="/doctor/category">DM chuyên môn</Link>
            </Menu.Item>
            <Menu.Item key="bs-list">
              <Link to="/doctors">Danh sách Bác sĩ</Link>
            </Menu.Item>
            <Menu.Item key="bs-booking">
              <Link to="/doctor/bookings">Lịch hẹn</Link>
            </Menu.Item>
            <Menu.Item key="bs-statistic">
              <Link to="/doctor/statistic">Thống kê</Link>
            </Menu.Item>
          </SubMenu>

          {/* Cơ sở y tế */}
          <SubMenu key="csyt" icon={<BankOutlined />} title="Cơ sở y tế">
            <Menu.Item key="csyt-type">
              <Link to="/hospital/type">Thể loại</Link>
            </Menu.Item>
            <Menu.Item key="csyt-specialty">
              <Link to="/hospital/specialty">Chuyên khoa</Link>
            </Menu.Item>
            <Menu.Item key="csyt-list">
              <Link to="/hospital">Danh sách</Link>
            </Menu.Item>
          </SubMenu>

          {/* Dịch vụ y tế */}
          <SubMenu
            key="service"
            icon={<MedicineBoxOutlined />}
            title="Dịch vụ y tế"
          >
            <Menu.Item key="service-category">
              <Link to="/service/category">Danh mục</Link>
            </Menu.Item>
            <Menu.Item key="service-service">
              <Link to="/service">Dịch vụ</Link>
            </Menu.Item>
            <Menu.Item key="service-order">
              <Link to="/service/booking">Lịch hẹn</Link>
            </Menu.Item>
            <Menu.Item key="service-statistic">
              <Link to="/service/statistic">Thống kê</Link>
            </Menu.Item>
          </SubMenu>

          {/* Cẩm nang khám bệnh */}
          <SubMenu key="cnkb" icon={<BookOutlined />} title="Cẩm nang">
            <Menu.Item key="cnkb-cate">
              <Link to="/handbook/category">Danh mục</Link>
            </Menu.Item>
            <Menu.Item key="cnkb-list">
              <Link to="/handbook">Danh sách bài viết</Link>
            </Menu.Item>
          </SubMenu>

          {/* Quản lý các trang nội dung */}
          <Menu.Item key="page" icon={<FilePptOutlined />}>
            <Link to="/page">Trang nội dung</Link>
          </Menu.Item>

          {/* Quản lý hỏi đáp */}
          <Menu.Item key="qa" icon={<QuestionCircleOutlined />}>
            <Link to="/qa">Câu hỏi thường gặp</Link>
          </Menu.Item>

          <SubMenu key="user" icon={<UserOutlined />} title="Quản lý tài khoản">
            <Menu.Item key="userAdmin">
              <Link to="/user/admin">Tài khoản Admin</Link>
            </Menu.Item>
            <Menu.Item key="userAgency">
              <Link to="/user/agency">TK Đại lý</Link>
            </Menu.Item>
            <Menu.Item key="userUser">
              <Link to="/user/user">TK người dùng</Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
    );
  }
}
