import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Form, Input, Select, Upload, message, Button, Spin, Collapse
} from 'antd';
import { HomeOutlined, SaveOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import JoditEditor from "jodit-react";
import { getAccessToken } from 'axios-jwt';

import CONSTANT from '../../../config/constant';
import * as COMMON from '../../../config/common';
import EditableTagGroup from './editable-tag-group';
import * as Service from '../../../config/service';

const status = [
    { value: 'new', label: 'Mới tạo' },
    { value: 'publish', label: 'Công khai' },
    { value: 'approve', label: 'Chờ duyệt' },
    { value: 'draft', label: 'Bản nháp' },
];

const currencys = [
    { value: 'VND', label: 'VND' },
];



const HandbookAction = (props) => {
    const { id } = useParams();
    const editor = useRef(null);
    const [content, setContent] = useState('')
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [tags, setTags] = useState([]);

    //define variable
    const [categoriesData, setcategoriesData] = useState([]);
    /*================= Images ============== */
    const [avatar, setAvatar] = useState();
    const [image, setImage] = useState();
    const [loadingAvatar, setLoadingAvatar] = useState(false);

    // on change upload image
    const onChangeImages = async ({ file }) => {
        if (file.status === 'uploading')
            setLoadingAvatar(true);
        if (file.status === 'done') {
            setLoadingAvatar(false);
            setAvatar(file?.response?.data.filename);
        }
    }


    // xử lý lưu image
    const saveImage = async () => {
        if (avatar) {
            return await Service.post(`${CONSTANT.API.EXTRA.SAVE_FILE}`, {
                filename: avatar,
                type: 'post'
            })
                .then(res => {
                    if (res?.data.code === 200) {
                        return res.data.data.url;
                    }
                })
        }
        return null;
    }


    // submit
    const onFinish = async (values) => {

        if (avatar) {
            let image = await saveImage();
            values.avatar = image;
        }

        let dataSave = {
            title: values.title,
            info: values.info,
            tag: tags,
            content: values.content,
            //related: [],
            status: values?.status,
            avatar: values.avatar,
            category: values.category,
            source_name: values.source_name,
            source_link: values.source_link
        }
        // Action
        if (!id) {
            await Service.post(`${CONSTANT.API.POST.POST}`, dataSave)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Thêm mới thành công', 1).then(() => props.history.push('/handbook'));
                    }
                    else {
                        message.error('Thêm mới thất bại! Vui lòng thử lại sau.');
                    }
                });
        }
        else {
            await Service.patch(`${CONSTANT.API.POST.POST}/${id}`, dataSave)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        message.success('Sửa thông tin thành công!', 1)
                            .then(() => props.history.push('/handbook'))
                    }
                    else {
                        message.error('Sửa thông tin thất bại! Vui lòng thử lại sau.');
                    }
                });
        }
    };

    // effect
    useEffect(() => {
        let mounted = true;
        setLoading(true);
        // Load thông tin chi tiết handbook
        const loadDetail = async () => {
            if (typeof id !== 'undefined') {
                return await Service.get(`${CONSTANT.API.POST.POST}/${id}`)
                    .then(async res => {
                        if (res && res.data && res.data.code === 200) {
                            return res.data.data;
                        }
                    })
                    .catch(error => message.warning('Bài viết không tồn tại!', 2.5).then(() => props.history.push('/handbook')))
            }
        }
        loadDetail().then(data => {
            if (mounted && data) {
                form.setFieldsValue({
                    _id: data?._id,
                    title: data?.title,
                    info: data?.info,
                    content: data?.content,
                    category: data?.category?._id,
                    status: data?.status,
                    source_name: data?.source_name,
                    source_link: data?.source_link
                });
                if (data?.tag)
                    setTags(data.tag);
                if (data?.avatar)
                    setImage(data.avatar);
            }
            setLoading(false);
        });

        // Load danh mục
        const loadCategory = async () => {
            return await Service.get(`${CONSTANT.API.POST.CATEGORY}?perPage=-1&is_parent=true`)
                .then(res => {
                    if (res && res.data && res.data.code === 200) {
                        return res.data.data.docs;

                    }
                });
        }
        loadCategory().then(data => {
            if (mounted && data) {
                setcategoriesData(data);
                setLoading(false);
            }
        });

        return () => {
            mounted = false;
        }

    }, []);

    // on change tag
    const onChangeTag = (value) => {
        setTags(value)
    }

    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/handbook">Cẩm nang</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {id && id !== '' ? 'Sửa thông tin' : 'Thêm mới'}
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {
                    loading &&
                    <div style={{ textAlign: 'center' }}>
                        <Spin />
                    </div>
                }

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={{
                        title: "", info: "", content: "", status: 'draft'
                    }}
                    onFinishFailed={() => message.warning('Vui lòng nhập đầy đủ thông tin')}
                >
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={18}>
                            <Form.Item
                                label="Tên"
                                name="title"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập tên bài viết!' },
                                ]}
                            >
                                <Input placeholder="Tên dịch vụ" />
                            </Form.Item>

                            <Form.Item
                                label="Mô tả ngắn gọn"
                                name="info"
                            >
                                <Input.TextArea placeholder="Nhập mô tả ngắn gọn" />
                            </Form.Item>

                            <Form.Item
                                label="Từ khóa"
                            >
                                <EditableTagGroup onChange={onChangeTag} tags={tags} />
                            </Form.Item>

                            <Form.Item
                                name="content"
                                label="Nội dung"
                            >
                                <JoditEditor
                                    ref={editor}
                                    value={content}
                                    config={{
                                        height: 500,
                                        language: 'vi',
                                        i18n: {
                                            vi: {
                                                'Type something': 'Nhập nội dung...',
                                                'Paste as HTML': 'Dán dưới dạng HTML',
                                                'Your code is similar to HTML. Keep as HTML?': 'Mã của bạn tương tự như HTML. Giữ dưới dạng HTML?',
                                                'Keep': 'Giữ',
                                                'Insert as Text': 'Chèn dưới dạng văn bản',
                                                'Insert only Text': 'Chỉ chèn văn bản',
                                                'Cancel': 'Hủy bỏ',
                                                'Drop image': 'Kéo thả hình ảnh',
                                                'or click': 'hoặc nhấp vào',
                                                'Upload': 'Tải lên',
                                                'Browse': 'Mở',
                                            }
                                        },
                                        filebrowser: {
                                            ajax: {
                                                method: "POST",
                                                url: `${CONSTANT.BASE_API}media/filemanager`,
                                                headers: {
                                                    Authorization: `Bearer ${getAccessToken()}`
                                                },
                                                prepareData: (e) => {
                                                    e.path = "post/post_content";
                                                    return e;
                                                }
                                            },
                                            showFoldersPanel: false,
                                            editImage: false,
                                        },
                                        uploader: {
                                            url: `${CONSTANT.BASE_API}media/filemanager/uploads`,
                                            headers: {
                                                Authorization: `Bearer ${getAccessToken()}`
                                            },
                                            data: { type: 'post_content' },
                                            filesVariableName: () => {
                                                return 'files'
                                            }
                                        }
                                    }}
                                />

                            </Form.Item>

                            <Row gutter={24}>
                                <Col md={8}>
                                    <Form.Item
                                        label="Tên nguồn bài viết"
                                        name="source_name"
                                    >
                                        <Input placeholder="Ví dụ: VNExpress" />
                                    </Form.Item>
                                </Col>
                                <Col md={16}>
                                    <Form.Item
                                        label="URL nguồn bài viết"
                                        name="source_link"
                                    >
                                        <Input placeholder="Ví dụ: https://vnexpress.net" />
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={24} sm={24} md={6}>
                            <Form.Item
                                label="Hình ảnh"
                            >
                                <ImgCrop
                                    rotate
                                    modalTitle="Sửa ảnh"
                                    modalOk="Đồng ý"
                                    aspect={1200 / 800}
                                    quality={0.8}
                                    modalWidth={800}
                                >
                                    <Upload
                                        action={`${CONSTANT.API.EXTRA.UPLOAD_IMAGE}`}
                                        data={{ type: 'area' }}
                                        listType="picture-card"
                                        showUploadList={false}
                                        onChange={onChangeImages}
                                    >
                                        {
                                            typeof avatar !== 'undefined'
                                                ? <img src={`${CONSTANT.STATIC_URL_TMP}${avatar}`} alt="avatar" style={{ width: '100%' }} />
                                                : <>
                                                    {
                                                        image ? <img src={`${COMMON.getImage(image, 'post')}`} alt="avatar" style={{ width: '100%' }} />
                                                            :
                                                            <div>
                                                                {loadingAvatar ? <LoadingOutlined /> : <PlusOutlined />}
                                                                <div style={{ marginTop: 8 }}>Tải ảnh</div>
                                                            </div>
                                                    }

                                                </>
                                        }

                                    </Upload>
                                </ImgCrop>
                                {/*  */}
                            </Form.Item>

                            <Form.Item
                                label="Danh mục"
                                tooltip="Chọn danh mục cẩm nang"
                                name="category"
                                placeholder="Chọn danh mục của dịch vụ"
                                required
                                rules={[
                                    { required: true, message: 'Vui lòng chọn danh mục của dịch vụ !' }
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    placeholder="Vui lòng chọn danh mục"
                                    style={{ width: '100%' }}
                                >
                                    {
                                        categoriesData.length > 0 &&
                                        categoriesData.map(lv1 => (
                                            <React.Fragment key={lv1.slug}>
                                                <Select.Option key={lv1._id}>{`♦ ${lv1.name}`}</Select.Option>
                                                {
                                                    lv1.childs.length > 0 &&
                                                    lv1.childs.map(lv2 => (
                                                        <Select.Option key={lv2._id}>{`   └ ${lv2.name}`}</Select.Option>
                                                    ))
                                                }
                                            </React.Fragment>
                                        ))
                                    }
                                </Select>
                            </Form.Item>



                            <Form.Item label="Trạng thái" name="status">
                                <Select
                                    placeholder="Chọn trạng thái"
                                    style={{ width: '100%' }}>
                                    {
                                        status.map(item => <Select.Option key={item.value}>{item.label}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className="footer-toolbar">
                        <div className="right">
                            <Button type="primary" icon={<SaveOutlined />}
                                htmlType="submit"
                            >
                                Lưu thông tin
                            </Button>
                        </div>

                    </div>
                </Form>

            </Layout.Content>

        </div>
    )

}

export default HandbookAction;
