import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Form, Input, Select, Upload, message, Button,
    InputNumber, Radio, Checkbox, Modal
} from 'antd';
import { HomeOutlined, PlusOutlined, LoadingOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

import CONSTANT from '../../../config/constant';
import { axiosInstance } from '../../../config/api';
import * as COMMON from '../../../config/common';


const playbackTypeData = [
    { value: 'random', label: 'Outdoor Tour' },
    { value: 'quest', label: 'Quest' }
];

const status = [
    { value: 'published', label: 'Công khai' },
    { value: 'limited', label: 'Giới hạn' },
    { value: 'draft', label: 'Bản nháp' },
];

const AddRoom = (props) => {
    
    const { idHotel } = useParams();

    const [form] = Form.useForm();

    //define variable
    const [facilityData, setFacilityData] = useState([]);
    const [saving, setSaving] = useState(false);


    /* ================= Images ============== */
    const [finalImages, setFinalImages] = useState('');
    const [imageList, setImageList] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewTitle, setPreviewTitle] = useState('');
    const [previewImage, setPreviewImage] = useState('');
    
    const onChangeImages = async ({fileList}) => {        
        setImageList(fileList);
    }
    const onPreviewImages = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }

        setPreviewImage(src);
        setPreviewVisible(true);
        let _previewTitle = file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
        setPreviewTitle(_previewTitle);
    };
    
    // upload image
    const saveImage = async () => {
        let arrImage = [];
        if(imageList.length > 0){
            for(const item of imageList){
                if(item.response.code === 200 && item.response.data && item.response.data.filename){
                    let filename = await axiosInstance.post(`${CONSTANT.API.EXTRA.SAVE_FILE}`,{
                        filename: item.response.data.filename,
                        type: "hotel"
                    })
                    .then(res => {
                        if(res && res.data && res.data.code === 200){
                            let filename = res.data.data.url;
                            filename = filename.indexOf('http') >= 0 ? filename.replace(`${CONSTANT.STATIC_URL}`, '') : filename;
                            return filename;
                        }
                    });
                    arrImage.push(filename);
                }
            }
        }
        if(arrImage.length > 0){
            return arrImage.join(',');
        }
    };
   
    // onFinish
    const onFinish = async (values) => {
        let image = await saveImage();
        values.image = image;
        values.hotel = idHotel;
        // Create room
        setSaving(true);
        await axiosInstance.post(`${CONSTANT.API.HOTEL.ROOM}`, values)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    message.success('Lưu thông tin thành công!')
                        .then(() => props.history.push(`/hotel/${idHotel}/room`))
                }
                else {
                    message.error('Lưu thông tin thất bại! Vui lòng thử lại sau.');
                }
                setSaving(false);
            });

    };

    //onFinish Failed
    const onFinishFailed = () => {
        message.warning('Vui lòng nhập đầy đủ các thông tin bắt buộc!');
    }

    // effect
    useEffect(() => {
        // load facility
        loadFacility();
    }, []);

    // load facility
    const loadFacility = async () => {
        await axiosInstance.get(`${CONSTANT.API.HOTEL.FACILITY}?perPage=-1&type=room`)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    setFacilityData(res.data.data.docs);
                }
            });
    }


    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/hotel">Khách sạn</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Thêm mới phòng
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={{
                        price: 0,
                        num_room: 0,
                        num_room_empty: 0, num_twinbed: 0, num_bed: 0,
                        breakfast: 'no', extrabed: 'no', acreage: 0, num_person: 0,
                        is_active: true,
                        note: ''
                    }}
                >

                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={18}>
                            <Form.Item
                                label="Tên phòng" name="name"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng nhập tên phòng!' },
                                ]}
                            >
                                <Input placeholder="Tên phòng" />
                            </Form.Item>
                            <Row gutter={16}>
                                <Col xs={8} sm={8} md={8}>
                                    <Form.Item
                                        label="Giá phòng" name="price"
                                    >
                                        <InputNumber
                                            formatter={value => `đ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\đ\s?|(,*)/g, '')}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={8} sm={8} md={8}>
                                    <Form.Item
                                        label="Số lượng phòng" name="num_room"
                                    >
                                        <InputNumber
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={8} sm={8} md={8}>
                                    <Form.Item
                                        label="Số phòng còn trống" name="num_room_empty"
                                    >
                                        <InputNumber
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item
                                name="breakfast"
                                label="Ăn sáng"
                            >
                                <Radio.Group>
                                    <Radio value="no">Không bao gồm bữa sáng</Radio>
                                    <Radio value="yes">Bao gồm bữa sáng</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Row gutter={16}>
                                <Col xs={12} sm={12} md={12}>
                                    <Form.Item
                                        label="Số giường đơn" name="num_bed"
                                    >
                                        <InputNumber
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={12} sm={12} md={12}>
                                    <Form.Item
                                        label="Số giường đôi" name="num_twinbed"
                                    >
                                        <InputNumber
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item
                                name="extrabed"
                                label="Giường phụ"
                            >
                                <Radio.Group>
                                    <Radio value="yes">Có</Radio>
                                    <Radio value="no">Không</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Row gutter={16}>
                                <Col xs={12} sm={12} md={12}>
                                    <Form.Item
                                        label="Diện tích" name="acreage"
                                    >
                                        <InputNumber
                                            formatter={value => `Mét vuông ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\Mét vuông\s?|(,*)/g, '')}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={12} sm={12} md={12}>
                                    <Form.Item
                                        label="Số người" name="num_person"
                                    >
                                        <InputNumber
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item
                                label="Tiện nghi" name="facilities"
                                required tooltip="Trường bắt buộc nhập"
                                rules={[
                                    { required: true, message: 'Vui lòng chọn ít nhất một tiện nghi!' },
                                ]}
                            >
                                <Checkbox.Group>
                                    <Row gutter={16}>
                                        {
                                            facilityData.map((item, index) => (
                                                <Col md={6} key={index}>
                                                    <Checkbox value={item._id} style={{ lineHeight: '32px' }}>
                                                        {item.name}
                                                    </Checkbox>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Checkbox.Group>

                            </Form.Item>


                            <Form.Item
                                label="Ghi chú" name="note"
                            >
                                <Input.TextArea placeholder="Nhập ghi chú" rows={3} />
                            </Form.Item>

                            <Form.Item
                                name="is_active"
                                label="Trạng thái phòng"
                            >
                                <Radio.Group>
                                    <Radio value={true}>Hoạt động</Radio>
                                    <Radio value={false}>Tạm đóng</Radio>
                                </Radio.Group>
                            </Form.Item>


                        </Col>
                        <Col xs={24} sm={24} md={6}>
                            <Form.Item
                                label="Hình ảnh"
                            >
                                <ImgCrop
                                    rotate
                                    modalTitle="Sửa ảnh"
                                    modalOk="Đồng ý"
                                    aspect={1200 / 628}
                                    quality={0.8}
                                    modalWidth={800}
                                >
                                    <Upload
                                        action={`${CONSTANT.API.EXTRA.UPLOAD_IMAGE}`}
                                        listType="picture"
                                        fileList={imageList}
                                        onPreview={onPreviewImages}
                                        onChange={onChangeImages}
                                        headers={{
                                            'X-TK': CONSTANT.API.KEY
                                        }}
                                    >
                                        {
                                            imageList.length < 11 &&
                                            <Button icon={<UploadOutlined />}>Chọn ảnh tải lên</Button>
                                        }
                                    </Upload>
                                </ImgCrop>
                                {/*  */}
                            </Form.Item>


                        </Col>
                    </Row>
                    <div className="footer-toolbar">
                        <div className="right">
                            <Button
                                type="primary" icon={<SaveOutlined />} htmlType="submit"
                                loading={saving}
                            >
                                {saving ? 'Đang lưu' : 'Lưu thông tin'}
                            </Button>
                        </div>
                    </div>
                </Form>
            </Layout.Content>

            {/* Modal preview image */}
            <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={() => setPreviewVisible(false)}
            >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>

        </div>
    )

}

export default AddRoom;
