import { applyAuthTokenInterceptor, clearAuthTokens } from 'axios-jwt';
import axios from 'axios';
import CONSTANT from './constant';

// Create an axios instance that you wish to apply the interceptor to
const axiosInstance = axios.create();
axiosInstance.defaults.headers.common['X-TK'] = CONSTANT.API.KEY;



// Define token refresh function
const requestRefresh = (refresh) => {
    // get current user
    let rawUser = localStorage.getItem(CONSTANT.STORAGE_KEY_USER);
    if (!rawUser) {
        clearAuthTokens();
        //window.location.href = `/login?back=${window.location.pathname}`;
    }
    rawUser = JSON.parse(rawUser);
    return axios.post(`${CONSTANT.BASE_API}auth/refresh-token`,
        {
            refreshToken: refresh,
            email: rawUser.email,
            role: rawUser.role
        },
        {
            validateStatus: function (status) {
                return status < 500; // Resolve only if the status code is less than 500
            }
        }
    )
        .then(res => {
            if (res && res.data && res.data.code === 200) {
                return res.data.data.token
            }
            else {
                clearAuthTokens();
               // window.location.href = `/login?back=${window.location.pathname}`;
            }

        })
}

// Apply interceptor
applyAuthTokenInterceptor(axiosInstance, {
    requestRefresh,
    header: "Authorization",
    headerPrefix: "Bearer "
});

export {
    axiosInstance
}


