import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout, Breadcrumb, DatePicker, Form, Table, Select, Input, message, Tabs, Button, Tag, Space
} from 'antd';
import { HomeOutlined, RedoOutlined, SearchOutlined, EyeOutlined } from '@ant-design/icons';
import CONSTANT from '../../../config/constant';
import { axiosInstance } from '../../../config/api';
import moment from 'moment';
import * as COMMON from '../../../config/common';
import ModalShopDetailOrder from './detail';
const { TabPane } = Tabs;
const arrStatus = {
    'wait-paid': { text: "Chờ thanh toán", color: "orange" },
    'paid': { text: 'Đã thanh toán', color: 'green' },
    'confirmed': { text: 'Đã xác nhận', color: 'blue' },
    'packing': { text: 'Đang đóng gói', color: 'orange' },
    'delivery-in-progress': { text: 'Đang giao hàng', color: 'orange' },
    'customer-received': { text: 'Khách đã nhận hàng', color: 'green' },
    'completed': { text: 'Đã hoàn thành', color: 'green' },
    'abandoned': { text: 'Khách đã hủy', color: 'red' },
    'cancelled': { text: 'Đối tác đã hủy đơn này', color: 'red' },
    'refunded': { text: 'Đã hoàn hoàn tiền', color: 'orange' },
    'trash': { text: 'Đang đặt mua', color: 'black' },
    'processing': { text: 'Đang xử lý', color: 'pink' },
};
const StatisticOrderShop = (props) => {
    const [form] = Form.useForm();
    const columns = [
        {
            title: '#',
            dataIndex: 'uid',
        },
        {
            title: 'Họ tên',
            width: '170px',
            dataIndex: 'shipping',
            render: shipping => <label> {shipping.fullname}</label>
        },
        {
            title: 'Email',
            dataIndex: 'shipping',
            render: shipping => shipping.email
        },
        {
            title: 'Ngày đặt hàng', dataIndex: 'created_at', width: '170px',
            render: created_at => `${moment(new Date(created_at)).format("DD/MM/YYYY HH:mm")}`
        },

        {
            title: 'Tổng tiền', dataIndex: 'date_paymented', width: '170px', align: 'right',
            render: (text, record) => COMMON.currencyFormat(parseFloat(record.total)) + ' ' + COMMON.getCurrency(record.currency)
        },
        {
            title: 'Trạng thái',
            render: (text, record) => <Tag color={arrStatus[record.status]?.color}>{arrStatus[record.status]?.text}</Tag>
        },

        {
            title: 'Hành động', width: '200px',
            render: (text, record) => (
                <Space size="small">
                    <Button type="link" onClick={() => openOrderService(record._id)}><EyeOutlined /> Xem</Button>
                </Space>
            )
        },
    ];
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [status, setStatus] = useState('all');
    const [total, setTotal] = useState(0);
    const [count, setCount] = useState(0);
    const [isModalServiceVisible, setIsModalServiceVisible] = useState(false);
    const [orderId, setOrderId] = useState(null);
    const [countPaging, setCountPaging] = useState(0);
    const [statistics, setStatistics] = useState([]);
    const [optionFilter, setOptionFilter] = useState({});
    const [pagination, setPagination] = useState({
        pageSize: 10,
        total: 0,
        perPage: 1,
        hideOnSinglePage: true,
        showLessItems: true
    });
    // effect
    useEffect(() => {
        loadStatistic({});
    }, []);
    const openOrderService = async (data) => {
        await setOrderId(data);
        setIsModalServiceVisible(true);
    }
    const changeStatusOrder = async (statusOrder) => {
        let options = { ...optionFilter };
        if (statusOrder !== status) {
            await setOrders([]);
            await setStatus(statusOrder);
            if (statusOrder !== 'all' && statusOrder) {
                options.status = statusOrder;
            }
            else {
                delete options.status;
            }
            loadStatistic({
                ...options
            });
            setOptionFilter(options);
        }
    }
    const loadStatistic = async (queryParam) => {
        setLoading(true);
        let query = '';
        if (queryParam) query = Object.keys(queryParam).map(key => key + '=' + queryParam[key]).join('&') + '&';
        await axiosInstance.get(`${CONSTANT.API.SHOP.STATISTIC}?${query}`)
            .then(async res => {
                if (res && res.data && res.data.code === 200 && res.data.data?.docs?.length > 0) {
                    let arrStatistic = [];
                    let _total = 0;
                    let _count = 0;
                    if (!queryParam?.status) {
                        ['wait-paid', 'paid', 'wait-confirm', 'confirmed','customer-received', 'completed', 'not-come', 'cancelled', 'abandoned'].map((item) => {
                            for (const statistic of res.data.data.docs) {
                                if (statistic._id === item) {
                                    arrStatistic.push(statistic);
                                    _total += statistic.total;
                                    _count += statistic.count;
                                    break;
                                }
                            }
                        });
                        setStatistics(arrStatistic);
                        setTotal(_total);
                        setCount(_count);
                    }
                    else {
                        arrStatistic.push(res.data.data.docs[0]);
                    }
                    loadOrder(queryParam);
                }
                else {
                    setStatistics([]);
                    setOrders([]);
                    setCount(0);
                    setTotal(0);
                }
            })
            .catch(error => message.warning('Đã xảy ra lỗi thống kê ! Vui lòng quay lại sau !' + error.message, 5))
        setLoading(false);
    }
    const loadOrder = async (queryParam) => {
        setLoading(true);
        let query = Object.keys(queryParam).map(key => key + '=' + queryParam[key]).join('&');
        debugger;
        await axiosInstance.get(`${CONSTANT.API.SHOP.ORDER}?${query}&statistic=true`)
            .then(async res => {
                if (res && res.data && res.data.code === 200) {
                    // Set giá trị cho pagination
                    let _pagination = { ...pagination, current: queryParam.page };
                    _pagination.total = res.data.data.total;
                    setPagination(_pagination);
                    setOrders(res.data.data.docs);
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                setOrders([]);
                message.warning('Lỗi dữ liệu nhập không đúng!', 3);
            });
        setLoading(false);
    }
    // Xử lý sự kiện search
    const onSearch = async (values) => {
        let options = {};
        if (values.q !== '' && values.q !== undefined) {
            if (values.field === 'fullname') {
                options.fullname = values.q;
            }
            else if (values.field === 'email') {
                options.email = values.q;
            }
            else if (values.field === 'phone') {
                options.phone = values.q;
            }
            else if (values.field === 'uid') {
                options.uid = values.q.replace('VIVMEDIC-P', '');
                if (isNaN(+options.uid)) {
                    message.warning('Mã đặt không đúng định dạng. Vui lòng kiểm tra lại !');
                    return;
                }
            }
        }
        if (values.date_range) {
            options.date_range = JSON.stringify({
                start: moment(values.date_range[0]).format("YYYY-MM-DD"),
                end: moment(values.date_range[1]).format("YYYY-MM-DD")
            });
        }
        if (status !== 'all' && status) {
            options.status = status;
        }
        loadStatistic({
            page: 1,
            perPage: pagination.perPage,
            ...options
        });
        setOptionFilter(options);
    }
    const reload = () => {
        loadOrder({ page: 1, perPage: pagination.perPage });
        form.resetFields();
    }
    const onPaginationChange = async (page) => {
        loadOrder({ page, perPage: pagination.perPage, ...optionFilter });
    }
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Bán hàng y tế
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Thống kê đơn hàng
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {/* Begin form search */}
                <Form
                    layout="inline"
                    onFinish={onSearch}
                    style={{ marginBottom: 20 }}
                    initialValues={{
                        field: 'fullname'
                    }}
                    style={{ margin: '0px -8px 20px -8px' }}
                    form={form}
                >
                    <Form.Item name="q"
                        // required rules={[
                        //     { required: true, message: 'Vui lòng nhập thông tin' }
                        // ]}
                        extra="(VD: Nguyễn Văn A hoặc VIVMEDIC-P123)"
                    >
                        <Input
                            type="text"
                            placeholder="Nhập tên người đặt hoặc mã đặt"
                            style={{ width: 300 }}
                        />
                    </Form.Item>
                    <Form.Item name="field">
                        <Select
                            style={{ width: 150 }}
                        >
                            <Select.Option value="fullname">Tên người đặt</Select.Option>
                            <Select.Option value="phone">Điện thoại</Select.Option>
                            <Select.Option value="email">Email</Select.Option>
                            <Select.Option value="uid">Mã đặt</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="date_range">
                        <DatePicker.RangePicker format="DD-MM-YYYY" />
                    </Form.Item>
                    <Form.Item>
                        <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
                            Tìm
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button icon={<RedoOutlined />} onClick={reload}>
                            Tải lại trang
                        </Button>
                    </Form.Item>
                </Form>
                {/* End form search */}

                <Tabs defaultActiveKey="all" onChange={(key) => {
                    changeStatusOrder(key);
                }}>
                    <TabPane tab={<div style={{ color: '#1890ff' }}>{'Tất cả (' + count + ')'}<br></br>{total.toLocaleString('en-US') + ' ₫'}</div>} key="all">
                    </TabPane>
                    {
                        statistics && statistics.length > 0 && statistics.map((item) => (
                            <TabPane tab={<div style={{ color: arrStatus[item?._id]?.color }}>{arrStatus[item?._id]?.text + ' (' + item.count + ')'}<br></br>{item.total.toLocaleString('en-US') + ' ₫'}</div>} key={item._id}>
                            </TabPane>
                        ))
                    }
                </Tabs>
                {
                    orders && <Table
                        dataSource={orders}
                        columns={columns}
                        loading={loading}
                        pagination={{
                            onChange: async (page, pageSize) => {
                                let para = { page, perPage: pageSize };
                                if (status !== 'all') {
                                    para.status = status;
                                }
                                loadOrder(para);
                            },
                            ...pagination
                        }}
                        rowKey={record => record._id}
                    />
                }
            </Layout.Content>
            <ModalShopDetailOrder visible={isModalServiceVisible} orderId={orderId}
                cancel={(reload) => {
                    setIsModalServiceVisible(false);
                    if (reload) {
                        loadOrder({ page: 1, perPage: pagination.perPage });
                        form.resetFields();
                    }
                }} />
        </div >
    )

}

export default StatisticOrderShop;