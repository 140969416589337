import React, { useRef, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Layout, Breadcrumb, Row, Col, Form, Input, Select, message, Button,Tabs, Spin, Upload, InputNumber
} from 'antd';
import { HomeOutlined, SaveOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

import CONSTANT from '../../../config/constant';
import * as Service from '../../../config/service';
import * as COMMON from '../../../config/common';

const status = [
    { value: 'published', label: 'Công khai' },
    { value: 'draft', label: 'Bản nháp' },
];

const DoctorCategoryEdit = (props) => {

    const [form] = Form.useForm();

    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    const [loadingAvatar, setLoadingAvatar] = useState(false);
    const [avatar, setAvatar] = useState();
    const [image, setImage] = useState();
    const [categories, setCategories] = useState([]);

    // on change upload image
    const onChangeImages = async ({ file }) => {
        if (file.status === 'uploading')
            setLoadingAvatar(true);
        if (file.status === 'done') {
            setLoadingAvatar(false);
            setAvatar(file?.response?.data.filename);
        }
    }

    // xử lý lưu image
    const saveImage = async () => {
        if (avatar) {
            return await Service.post(`${CONSTANT.API.EXTRA.SAVE_FILE}`, {
                filename: avatar,
                type: 'doctor'
            })
                .then(res => {
                    if (res?.data.code === 200) {
                        return res.data.data.url;
                    }
                })
        }
        return null;
    }

    useEffect(() => {
        let mounted = true;
        setLoading(true);
        const loadDetail = async () => {
            if (typeof id !== 'undefined') {
                return await Service.get(`${CONSTANT.API.DOCTOR.CATEGORY}/${id}`)
                    .then(res => {
                        if (res && res.data && res.data.code === 200) {
                            return res.data.data;
                        }
                    })
                    .catch(error => {
                        message.warning('Lỗi lấy thông tin chi tiết danh mục!');
                    });
            }
        }
        loadDetail().then(data => {
            if (mounted) {
                if (data) {
                    form.setFieldsValue({
                        name: data.name,
                        short_name: data.short_name,
                        info: data?.info,
                        name_en: data.translations.en.name,
                        short_name_en: data.translations.en.short_name,
                        info_en: data.translations.en.info,
                        parent: data.parent ? data.parent : 'root',
                        order: data.order,
                        status: data.is_active === true ? 'published' : 'draft'
                    });
                    setImage(data.avatar);
                }
                setLoading(false);
            }
        });

        //  load danh muc
        const loadCategories = async () => {
            return await Service.get(`${CONSTANT.API.DOCTOR.CATEGORY}`, { is_parent: true, perPage: -1, is_deleted: false, page: 1 })
                .then(res => {
                    if (res?.data.code === 200)
                        return res.data.data.docs;
                })
                .catch(error => {
                    message.warning('Không lấy được thông tin danh mục!');
                });
        }

        loadCategories().then(data => {
            if (mounted) {
                setCategories(data);
                setLoading(false);
            }
        });

        return () => {
            mounted = false;
        }

    }, []);


    // submit
    const onFinish = async (values) => {
        setSaving(true);
        if (values.parent === 'root')
            delete values.parent;

        if (avatar) {
            let image = await saveImage();
            values.avatar = image;
        }
        values.is_active = values.status === 'published' ? true : false;
        values.translations = {
            en: {
                name: values.name_en,
                short_name:values.short_name_en,
                info: values.info_en
            }
        }        
        // Create
        await Service.patch(`${CONSTANT.API.DOCTOR.CATEGORY}/${id}`, values)
            .then(res => {
                if (res && res.data && res.data.code === 200) {
                    message.success('Lưu thông tin thành công!', 1).then(() => props.history.push('/doctor/category'))
                }
                else {
                    message.error('Lưu thông tin thất bại! Vui lòng thử lại sau.');
                }
                setSaving(false);
            }).catch(err => {
                message.error('Lưu thông tin thất bại! Vui lòng thử lại sau.');
                setSaving(false);
            });

    };


    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item >
                    <Link to="/"><HomeOutlined /></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/page">Danh mục</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Sửa
                </Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className="layout-content">
                {loading && <div style={{ textAlign: 'center' }}><Spin /></div>}
                {
                    !loading &&
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        initialValues={{
                            status: "draft"
                        }}
                    >
                        <Row gutter={16}>
                            <Col xs={24} sm={24} md={18}>
                            <Tabs defaultActiveKey="vi" type="card" size="large">
                                <Tabs.TabPane tab="Tiếng Việt" key="vi" forceRender={true}>
                                    <Form.Item
                                        label="Tên" name="name"
                                        required tooltip="Trường bắt buộc nhập"
                                        rules={[
                                            { required: true, message: 'Vui lòng nhập tên!' },
                                        ]}
                                    >
                                        <Input placeholder="Tên" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Tên viết tắt" name="short_name"
                                        tooltip="Ví dụ: BS, BS CKI..."
                                    >
                                        <Input placeholder="Tên viết tắt" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Mô tả ngắn gọn" name="info"
                                    >
                                        <Input.TextArea placeholder="Nhập mô tả ngắn gọn" />
                                    </Form.Item>
                                </Tabs.TabPane>
                                <Tabs.TabPane forceRender={true} tab="Tiếng Anh" key="en">
                                    <Form.Item
                                        label="Tên" name="name_en"
                                        required tooltip="Trường bắt buộc nhập"
                                        rules={[
                                            { required: true, message: 'Vui lòng nhập tên!' },
                                        ]}
                                    >
                                        <Input placeholder="Tên" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Tên viết tắt" name="short_name_en"
                                        tooltip="Ví dụ: BS, BS CKI..."
                                    >
                                        <Input placeholder="Tên viết tắt" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Mô tả ngắn gọn" name="info_en"
                                    >
                                        <Input.TextArea placeholder="Nhập mô tả ngắn gọn" />
                                    </Form.Item>
                                </Tabs.TabPane>
                            </Tabs>
                                <Form.Item
                                    label="Thứ tự" name="order"
                                >
                                    <InputNumber placeholder="Nhập số thứ tự" min={1} style={{width:'100%'}} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={6}>

                                <Form.Item
                                    label="Hình ảnh"
                                >
                                    <ImgCrop
                                        rotate
                                        modalTitle="Sửa ảnh"
                                        modalOk="Đồng ý"
                                        aspect={500 / 500}
                                        quality={0.8}
                                        modalWidth={800}
                                    >
                                        <Upload
                                            action={`${CONSTANT.API.EXTRA.UPLOAD_IMAGE}`}
                                            data={{ type: 'area' }}
                                            listType="picture-card"
                                            showUploadList={false}
                                            onChange={onChangeImages}
                                        >
                                            {
                                                typeof avatar !== 'undefined'
                                                    ? <img src={`${CONSTANT.STATIC_URL_TMP}${avatar}`} alt="avatar" style={{ width: '100%' }} />
                                                    : <>
                                                        {
                                                            image ? <img src={`${COMMON.getImage(image, 'location')}`} alt="avatar" style={{ width: '100%' }} />
                                                                :
                                                                <div>
                                                                    {loadingAvatar ? <LoadingOutlined /> : <PlusOutlined />}
                                                                    <div style={{ marginTop: 8 }}>Tải ảnh</div>
                                                                </div>
                                                        }

                                                    </>
                                            }

                                        </Upload>
                                    </ImgCrop>
                                    {/*  */}
                                </Form.Item>

                                <Form.Item
                                    label="Danh mục cha"
                                    name="parent"
                                    required tooltip="Trường bắt buộc nhập"
                                    rules={[
                                        { required: true, message: 'Vui lòng chọn danh mục cha!' },
                                    ]}
                                >
                                    <Select
                                        placeholder="Chọn danh mục cha"
                                        style={{ width: '100%' }}

                                    >
                                        <Select.Option key='root'>Danh mục cha</Select.Option>
                                        {
                                            categories.map(lv1 =>
                                                <React.Fragment key={lv1.uid}>
                                                    <Select.Option key={lv1._id}>{`${lv1.name}`}</Select.Option>
                                                </React.Fragment>
                                            )

                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Trạng thái"
                                    name="status"
                                >
                                    <Select
                                        placeholder="Chọn trạng thái"
                                        style={{ width: '100%' }}
                                    >
                                        {
                                            status.map(item => <Select.Option key={item.value}>{item.label}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="footer-toolbar">
                            <div className="right">
                                <Button
                                    type="primary" icon={<SaveOutlined />} htmlType="submit"
                                    loading={saving}
                                >
                                    {saving ? 'Đang lưu' : 'Lưu thông tin'}
                                </Button>
                            </div>

                        </div>
                    </Form>
                }


            </Layout.Content>

        </div>
    )

}

export default DoctorCategoryEdit;
