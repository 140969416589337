import { axiosInstance } from './api';
export const get = async (url, queryParam) => {
    if (queryParam) {
        let query = Object.keys(queryParam).map(key => key + '=' + queryParam[key]).join('&');
        if (query)
            url = `${url}?${query}`;
    }
    return await axiosInstance.get(url)
        .then(res => {
            if (res.status !== 200)
                switch (res.status) {
                    case 404: console.log("URL api không đúng. 404"); break;
                    case 401: console.log("Không có quyền truy cập api. 401"); break;
                    default: console.log(res.status); break;
                }
            return res;
        });
}
/**
 * Hàm lấy danh sách area từ api
 * @param {*} queryParam 
 * @returns array areas
 */
export const post = async (url, data, header = null) => {
    return await axiosInstance.post(url, data, header)
        .then(res => {
            return res;
        });
}
export const patch = async (url, data, header = null) => {
    return await axiosInstance.patch(url, data, header)
        .then(res => {
            return res;
        });
}
export const remove = async (url, header = null) => {
    return await axiosInstance.delete(url, header)
        .then(res => {
            return res;
        });
}