import React from 'react';
import { Result } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

import './dashboard.css';

export default class Dashboard extends React.PureComponent {
    render() {

        return (
            <div className="dashboard">
                
                <div className="banner">
                    <Result
                        icon={<SmileOutlined style={{ color: 'white' }} />}
                        title="Tuyệt vời, Bạn đang ở trang quản trị nội dung"
                        subTitle="Bạn có thể bắt đầu quản lý nội dung trang web của bạn"
                        style={{ margin: '0 auto', padding: '20px 0px 15px 0px' }}
                    />
                </div>
                
            </div>
        )
    }
}